import { BsPrefixPropsWithChildren } from "react-bootstrap/esm/helpers";
import { createAction } from "redux-ts";
import {
    CASUploadPayload,
    CreateExperienceCompletedPayload,
    CreateExperienceInProgressPayload,
    CreateExperiencePayload,
    Experience,
    ExperienceDraft,
    ExperiencesPage,
    GetExperienceInProgressPayload,
    GetExperiencePayload,
    InitiateExperiencePayload,
    ListExperiencesForEntitiesPayload,
    ListExperiencePayload,
    UpdateExperienceCompletedPayload,
    UpdateExperienceInProgressPayload,
    UploadAssetToCASCompletedPayload,
    UploadAssetToCASInProgressPayload,
    GetAssetStatusesPayload,
    UpdateExperienceAssetsPayload,
    UpdateExperienceMetadataPayload,
    UpdateExperienceStatePayload,
    ListExperiencesCompletedPayload,
    CompositeUpdateExperiencePayload,
    UploadAssetToCASSuccessPayload,
} from "../../models";

export const listExperiences = createAction<ListExperiencePayload>(
    "STUDIO::LIST_EXPERIENCES"
);

export const listExperiencesInProgress = createAction<boolean>(
    "STUDIO::LIST_EXPERIENCES_IN_PROGRESS"
);

export const listExperiencesCompleted =
    createAction<ListExperiencesCompletedPayload>(
        "STUDIO::LIST_EXPERIENCES_COMPLETED"
    );

export const listExperiencesForEntities =
    createAction<ListExperiencesForEntitiesPayload>(
        "STUDIO::LIST_EXPERIENCES_FOR_ENTITIES"
    );

export const listExperiencesForEntitiesInProgress = createAction<boolean>(
    "STUDIO::LIST_EXPERIENCES_FOR_ENTITIES_IN_PROGRESS"
);

export const listExperiencesForEntitiesCompleted = createAction<
    Map<string, ExperiencesPage>
>("STUDIO::LIST_EXPERIENCES_FOR_ENTITIES_COMPLETED");

export const initiateExperience = createAction<InitiateExperiencePayload>(
    "STUDIO::INITIATE_EXPERIENCE"
);

export const createExperience = createAction<CreateExperiencePayload>(
    "STUDIO::CREATE_EXPERIENCE"
);

export const createExperienceInProgress =
    createAction<CreateExperienceInProgressPayload>(
        "STUDIO::CREATE_EXPERIENCE_IN_PROGRESS"
    );

export const createExperienceCompleted =
    createAction<CreateExperienceCompletedPayload>(
        "STUDIO::CREATE_EXPERIENCE_COMPLETED"
    );

export const getExperience = createAction<GetExperiencePayload>(
    "STUDIO::GET_EXPERIENCE"
);

export const getExperienceInProgress =
    createAction<GetExperienceInProgressPayload>(
        "STUDIO::GET_EXPERIENCE_IN_PROGRESS"
    );

export const getExperienceCompleted = createAction<ExperienceDraft>(
    "STUDIO::GET_EXPERIENCE_COMPLETED"
);

export const updateExperienceAssets =
    createAction<UpdateExperienceAssetsPayload>(
        "STUDIO::UPDATE_EXPERIENCE_ASSETS"
    );

export const updateExperienceMetadata =
    createAction<UpdateExperienceMetadataPayload>(
        "STUDIO::UPDATE_EXPERIENCE_METADATA"
    );

export const updateExperienceState = createAction<UpdateExperienceStatePayload>(
    "STUDIO::UPDATE_EXPERIENCE_STATE"
);

export const compositeUpdateExperience =
    createAction<CompositeUpdateExperiencePayload>(
        "STUDIO::COMPOSITE_UPDATE_EXPERIENCE"
    );

export const compositeUpdateExperienceInProgress =
    createAction<UpdateExperienceInProgressPayload>(
        "STUDIO::COMPOSITE_UPDATE_EXPERIENCE_IN_PROGRESS"
    );

export const compositeUpdateExperienceCompleted =
    createAction<UpdateExperienceCompletedPayload>(
        "STUDIO::COMPOSITE_UPDATE_EXPERIENCE_COMPLETED"
    );

export const updateExperienceAssetsInProgress =
    createAction<UpdateExperienceInProgressPayload>(
        "STUDIO::UPDATE_EXPERIENCE_ASSETS_IN_PROGRESS"
    );

export const updateExperienceMetadataInProgress =
    createAction<UpdateExperienceInProgressPayload>(
        "STUDIO::UPDATE_EXPERIENCE_METADATA_IN_PROGRESS"
    );

export const updateExperienceStateInProgress =
    createAction<UpdateExperienceInProgressPayload>(
        "STUDIO::UPDATE_EXPERIENCE_STATE_IN_PROGRESS"
    );

export const updateExperienceAssetsCompleted =
    createAction<UpdateExperienceCompletedPayload>(
        "STUDIO::UPDATE_EXPERIENCE_ASSETS_COMPLETED"
    );

export const updateExperienceMetadataCompleted =
    createAction<UpdateExperienceCompletedPayload>(
        "STUDIO::UPDATE_EXPERIENCE_METADATA_COMPLETED"
    );

export const updateExperienceStateCompleted =
    createAction<UpdateExperienceCompletedPayload>(
        "STUDIO::UPDATE_EXPERIENCE_STATE_COMPLETED"
    );

export const quickUpdateExperienceState =
    createAction<UpdateExperienceStatePayload>(
        "STUDIO::QUICK_UPDATE_EXPERIENCE_STATE"
    );

export const quickUpdateExperienceStateInProgress = createAction<Boolean>(
    "STUDIO::QUICK_UPDATE_EXPERIENCE_STATE_IN_PROGRESS"
);

export const updateLocalDraft = createAction<ExperienceDraft>(
    "STUDIO::UPDATE_LOCAL_DRAFT"
);

export const clearDraftFromDraftsMap = createAction<string>(
    "STUDIO::CLEAR_DRAFT_FROM_DRAFTS"
);

export const clearArtistStudioState = createAction("STUDIO::CLEAR_STATE");

export const uploadAssetToCAS = createAction<CASUploadPayload>(
    "STUDIO::UPLOAD_ASSET_TO_CAS"
);

export const uploadToCASInProgress =
    createAction<UploadAssetToCASInProgressPayload>(
        "STUDIO::UPLOAD_TO_CAS_IN_PROGRESS"
    );

export const uploadToCASCompleted =
    createAction<UploadAssetToCASCompletedPayload>(
        "STUDIO::UPLOAD_TO_CAS_COMPLETED"
    );

export const uploadToCASSuccess =
    createAction<UploadAssetToCASSuccessPayload>(
        "STUDIO::UPLOAD_TO_CAS_SUCCESS"
    );

export const getAssetStatuses = createAction<GetAssetStatusesPayload>(
    "STUDIO::GET_ASSET_STATUSES"
);
