import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    ImageTile,
    ImageWithFallback,
    SmallGlassButton,
    styledTitle,
    Switch,
    TextField,
    IconTile,
    FullScreenConfirmationModal,
    Icon,
} from "../../common";
import * as rootStyles from "../../../styles";
import { IconsList, ImageList, stringIds, bundleIds } from "../../../../assets";
import {
    AnnouncementUtil,
    concatenateArtistNames,
    formatDate,
    getLocalizedString,
    MSEC_PER_DAY,
} from "../../../../utils";
import {
    track,
    album,
    catalogItem,
    ExperienceEntityType,
    ExperienceDraft,
    artist,
    CommonTestData,
    ExperienceAssociatedEntity,
    BundleMap,
} from "../../../../models";
import CustomDatePicker from "../../datepicker/CustomDatePicker";

type StateProps = {
    catalog: Map<string, catalogItem>;
    onEdit: () => void;
    setExperienceDraft: (experienceDraft: ExperienceDraft) => void;
    experienceDraft: ExperienceDraft;
    showRequiredFieldError?: boolean;
    handleClickedOffInputBox?: () => void;
    bundleMap: BundleMap;
};

type State = {
    showCustomPicker: boolean;
    audioIsPaused: boolean;
    audioPlayer?: HTMLAudioElement;
    associatedEntity?: ExperienceAssociatedEntity;
    catalogItem?: track | album;
    showMatureContentHelpModal: boolean;
};

const testIDPrefix = "AnnouncementDetailsScreen-AnnouncementReview";
const metricPrefix = "announcementDetailsScreen-AnnouncementReview";

export class AnnouncementReview extends React.Component<StateProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showCustomPicker: false,
            audioIsPaused: true,
            audioPlayer: undefined,
            catalogItem: undefined,
            showMatureContentHelpModal: false,
        };
    }

    componentDidMount() {
        if (
            !this.props.experienceDraft.eligibility.schedule?.startDate ||
            !this.props.experienceDraft.eligibility.schedule?.expirationDate
        ) {
            this.setDefaultStartAndEndDates();
        }

        if (this.props.experienceDraft.audioURL) {
            this.createNewAudioPlayer(this.props.experienceDraft.audioURL);
        }

        const associatedEntities =
            this.props.experienceDraft.experience.associatedEntities;

        if (associatedEntities.length > 0) {
            const associatedEntity = associatedEntities[0];

            let associatedCatalogItem: track | album;

            if (associatedEntity.entityType == ExperienceEntityType.ALBUM) {
                associatedCatalogItem = this.props.catalog.get(
                    associatedEntity.identifier
                ) as album;
            } else {
                associatedCatalogItem = this.props.catalog.get(
                    associatedEntity.identifier
                ) as track;
            }
            this.setState({
                catalogItem: associatedCatalogItem,
                associatedEntity: associatedEntity,
            });
        }
    }

    render() {
        const ReviewHeader = () => {
            return (
                <Col>
                    <Row>
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementReviewHeader,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementReviewSubHeader,
                            })}
                        </span>
                    </Row>
                </Col>
            );
        };

        return (
            <>
                {ReviewHeader()}
                <Row>
                    {this.AnnouncementReviewWidget()}
                    {this.AnnouncementFinalDetails()}
                </Row>
                {this.state.showCustomPicker ? (
                    <CustomDatePicker
                        initialStartDate={this.dateToUTC(
                            this.props.experienceDraft.eligibility.schedule
                                ?.startDate || this.getDefaultStartDate()
                        )}
                        initialEndDate={this.dateToUTC(
                            this.props.experienceDraft.eligibility.schedule
                                ?.expirationDate || this.getDefaultEndDate()
                        )}
                        hideModal={this.onHideDatePicker}
                        saveDates={this.acceptCustomPicker}
                        minDate={this.dateToUTC(this.getDefaultStartDate())}
                        maxDate={this.dateToUTC(this.getDefaultEndDate())}
                        id={`${testIDPrefix}_DatePicker`}
                    />
                ) : null}
                {this.matureContentHelpModal()}
            </>
        );
    }

    private AnnouncementReviewWidget = () => {
        return (
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    background: rootStyles.glassColors.primary2,
                    gap: rootStyles.spacers.medium,
                    padding: 0,
                    paddingTop: rootStyles.spacers.small,
                    paddingBottom: rootStyles.spacers.small,
                    borderRadius: 4,
                }}
            >
                {this.entityDetails()}
                {this.announcementDetails()}
                {/* Learn more widget is disabled until business provides a working link */}
                {/* {this.learnMoreWidget()} */}
            </Col>
        );
    };

    private AnnouncementFinalDetails = () => {
        return (
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: rootStyles.spacers.epic,
                }}
            >
                {this.forMatureAudiencesWidget()}
                {this.startEndDateWidget()}
                {this.announcementNameWidget()}
            </Col>
        );
    };

    private entityDetails = () => {
        return (
            <Row
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: rootStyles.spacers.small,
                    flexFlow: "row nowrap",
                    paddingLeft: rootStyles.spacers.small,
                    paddingRight: rootStyles.spacers.small,
                }}
            >
                {this.entityWidget()}
                <Col
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <SmallGlassButton
                        title={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formEdit,
                        })}
                        onClick={this.props.onEdit}
                        id={`${testIDPrefix}-EditButton`}
                        containerStyle={{
                            backgroundColor: "transparent",
                        }}
                    />
                </Col>
            </Row>
        );
    };

    private announcementDetails = () => {
        return (
            <Row
                style={{
                    alignItems: "flex-end",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        background: `linear-gradient(to bottom, ${rootStyles.glassColors.secondary3}, rgb(0, 0, 0, 1)), url(${this.props.experienceDraft.backgroundImageURL})`,
                        backgroundSize: "cover",
                    }}
                >
                    <ImageWithFallback
                        source={this.props.experienceDraft.backgroundImageURL}
                        fallback={ImageList.placeholder_album}
                        style={{
                            flex: 1,
                            width: 0, // sets width to 0, it will then be overwritten when image attempts to maintain aspect ratio and will fill div
                            zIndex: -1,
                        }}
                        id={`${testIDPrefix}_BackgroundImage`}
                    />
                </div>
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        padding: rootStyles.spacers.base,
                        gap: rootStyles.spacers.mini,
                    }}
                >
                    <IconTile
                        icon={
                            this.state.audioIsPaused
                                ? IconsList.playback_play
                                : IconsList.playback_pause
                        }
                        iconSize={rootStyles.icons.tiny}
                        isCircle={true}
                        noGradient={true}
                        noGradientBackgroundColor={
                            rootStyles.glassColors.primary3
                        }
                        onClick={this.onPlayPause}
                        id={`${testIDPrefix}_PlayPause`}
                    />
                    <styledTitle.h4>
                        {this.props.experienceDraft.headline}
                    </styledTitle.h4>
                    {this.announcementDetailsWidget()}
                </Col>
            </Row>
        );
    };

    private entityWidget = () => {
        if (!this.state.associatedEntity || !this.state.catalogItem) {
            return null;
        }

        const subtitleStyle: React.CSSProperties = {
            ...rootStyles.textStyles.index,
            textAlign: "left",
            color: rootStyles.colors.accent,
        };

        const isTrack =
            this.state.associatedEntity.entityType ===
            ExperienceEntityType.TRACK;

        const contentType = isTrack
            ? getLocalizedString(this.props.bundleMap, {
                  bundleId: bundleIds.REPORTS_STRINGS,
                  stringId: stringIds.Reports.songsLabel,
              })
            : getLocalizedString(this.props.bundleMap, {
                  bundleId: bundleIds.REPORTS_STRINGS,
                  stringId: stringIds.Reports.albumsLabel,
              });

        const artistNames = concatenateArtistNames(
            this.state.catalogItem.artistAsins,
            this.props.catalog,
            true
        );

        return (
            <Row
                style={{
                    flexFlow: "row nowrap",
                    alignItems: "center",
                }}
            >
                <ImageTile
                    image={
                        this.state.catalogItem.images?.artSmall ||
                        ImageList.placeholder_album
                    }
                    size={rootStyles.icons.small * 3}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: rootStyles.spacers.small,
                    }}
                >
                    <p
                        style={{
                            ...subtitleStyle,
                            marginBottom: 0,
                        }}
                    >
                        {contentType}
                    </p>
                    <p
                        style={{
                            ...rootStyles.textStyles.primary,
                            marginBottom: 0,
                        }}
                    >
                        {this.state.catalogItem.title}
                    </p>
                    <p
                        style={{
                            ...rootStyles.textStyles.secondary,
                            marginBottom: 0,
                        }}
                    >
                        {artistNames}
                    </p>
                </div>
            </Row>
        );
    };

    private announcementDetailsWidget = () => {
        if (!this.state.associatedEntity || !this.state.catalogItem) {
            return null;
        }

        const artistAsin =
            this.props.experienceDraft.experience.publishingArtistAsin;

        const artist = this.props.catalog.get(artistAsin) as artist;

        const subtitleStyle: React.CSSProperties = {
            ...rootStyles.textStyles.index,
            textAlign: "left",
            color: rootStyles.colors.accent,
        };

        const contentType = getLocalizedString(this.props.bundleMap, {
            bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
            stringId:
                stringIds.ArtistStudio.Announcement
                    .announcementDetailsPageTitle,
        });

        const artistName = artist.title;

        return (
            <Row
                style={{
                    flexFlow: "row nowrap",
                    alignItems: "center",
                }}
            >
                <ImageTile
                    image={
                        artist.backgroundImages?.artSmall ||
                        ImageList.placeholder_album
                    }
                    isCircle={true}
                    size={rootStyles.icons.small * 3}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: rootStyles.spacers.small,
                    }}
                >
                    <p
                        style={{
                            ...subtitleStyle,
                            marginBottom: 0,
                        }}
                    >
                        {contentType}
                    </p>
                    <p
                        style={{
                            ...rootStyles.textStyles.primary,
                            marginBottom: 0,
                        }}
                    >
                        {artistName}
                    </p>
                    <p
                        style={{
                            ...rootStyles.textStyles.secondary,
                            marginBottom: 0,
                        }}
                    >
                        {this.state.catalogItem.title}
                    </p>
                </div>
            </Row>
        );
    };

    private forMatureAudiencesWidget = () => {
        const forMatureAudiencesHeader = () => {
            const helpIcon = () => {
                return (
                    <Icon
                        icon={IconsList.ic_help}
                        size={rootStyles.icons.tiny}
                        style={{
                            opacity: rootStyles.glass._4,
                            marginLeft: rootStyles.spacers.mini,
                        }}
                        onClick={() => {
                            this.setState({ showMatureContentHelpModal: true });
                        }}
                        id={`${testIDPrefix}_HelpIcon`}
                    />
                );
            };
            return (
                <Col>
                    <Row
                        style={{
                            alignItems: "center",
                            gap: rootStyles.spacers.micro,
                        }}
                    >
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementReviewForMatureAudiencesHeader,
                            })}
                        </styledTitle.h4>
                        {helpIcon()}
                    </Row>
                    <Row>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementReviewForMatureAudiencesSubHeader,
                            })}
                        </span>
                    </Row>
                </Col>
            );
        };

        return (
            <Row
                style={{
                    alignItems: "center",
                    flexFlow: "row nowrap",
                }}
            >
                {forMatureAudiencesHeader()}
                <Switch
                    isOn={this.props.experienceDraft.eligibility.explicit}
                    handleToggle={() => {
                        this.setForMatureAudiences(
                            !this.props.experienceDraft.eligibility.explicit
                        );
                    }}
                    size={rootStyles.icons.small}
                />
            </Row>
        );
    };

    private startEndDateWidget = () => {
        const startEndDateHeader = () => {
            return (
                <Col>
                    <Row>
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementReviewAvailabilityHeader,
                            })}
                        </styledTitle.h4>
                    </Row>
                </Col>
            );
        };

        const formatedStartDate = formatDate(
            this.props.experienceDraft.eligibility.schedule?.startDate ||
                this.getDefaultStartDate()
        );
        const formatedEndDate = formatDate(
            this.props.experienceDraft.eligibility.schedule?.expirationDate ||
                this.getDefaultEndDate()
        );

        return (
            <Row
                style={{
                    alignItems: "center",
                    flexFlow: "row nowrap",
                }}
            >
                {startEndDateHeader()}
                <SmallGlassButton
                    title={`${formatedStartDate} - ${formatedEndDate}`}
                    onClick={this.onShowDatePicker}
                    id={`${testIDPrefix}-StartEndDateButton`}
                    containerStyle={{
                        paddingLeft: rootStyles.spacers.base,
                        paddingRight: rootStyles.spacers.base,
                    }}
                />
            </Row>
        );
    };

    private announcementNameWidget = () => {
        const announcementNameHeader = () => {
            return (
                <Col>
                    <Row>
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementReviewNameHeader,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementReviewNameSubHeader,
                            })}
                        </span>
                    </Row>
                </Col>
            );
        };
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: rootStyles.spacers.small,
                }}
            >
                {announcementNameHeader()}
                <Col>
                    <TextField
                        multiLine={true}
                        maxLength={75}
                        id={`${testIDPrefix}-Description`}
                        onChange={this.setTitle}
                        defaultValue={this.props.experienceDraft.title}
                        showCharacterCount={true}
                        visibleRows={2}
                        requiredFieldMsgText={`*${getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formIsRequired,
                            }
                        )}`}
                        handleClickedOffInputBox={
                            this.props.handleClickedOffInputBox
                        }
                    />
                </Col>
            </div>
        );
    };

    private learnMoreWidget = () => {
        return (
            <Row
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: rootStyles.spacers.medium,
                    flexFlow: "row nowrap",
                    paddingLeft: rootStyles.spacers.medium,
                    paddingRight: rootStyles.spacers.medium,
                }}
            >
                <span
                    style={{
                        ...rootStyles.textStyles.secondary,
                    }}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .anouncementReviewLearnMoreText,
                    })}
                </span>
                <SmallGlassButton
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.NEWRELEASES_STRINGS,
                        stringId:
                            stringIds.NewReleases.helpModalLearnMoreButton,
                    })}
                    onClick={this.onLearnMore}
                    id={`${testIDPrefix}-EditButton`}
                    containerStyle={{
                        paddingLeft: rootStyles.spacers.large,
                        paddingRight: rootStyles.spacers.large,
                    }}
                />
            </Row>
        );
    };

    private matureContentHelpModal = () => {
        const matureContentBody = () => {
            const bodyText = (message: string) => {
                return (
                    <span
                        style={{
                            ...rootStyles.textStyles.secondary,
                            color: rootStyles.colors.primary,
                            flex: 1,
                            textAlign: "center",
                        }}
                    >
                        {message}
                    </span>
                );
            };
            return (
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 0,
                        gap: rootStyles.spacers.medium,
                    }}
                >
                    {bodyText(
                        getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementMatureHelpWidgetBody1,
                        })
                    )}
                    {bodyText(
                        getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementMatureHelpWidgetBody2,
                        })
                    )}
                    {bodyText(
                        getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementMatureHelpWidgetBody3,
                        })
                    )}
                </Col>
            );
        };
        return (
            <FullScreenConfirmationModal
                id={`${testIDPrefix}-MatureHelpModal`}
                text={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                    stringId:
                        stringIds.ArtistStudio.Announcement
                            .announcementMatureHelpWidgetHeadline,
                })}
                body={matureContentBody()}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.ok,
                })}
                confirmButtonAction={this.hideMatureContentHelpModal}
                onDismiss={this.hideMatureContentHelpModal}
                isVisible={this.state.showMatureContentHelpModal}
                buttonOrientation={"stacked"}
            />
        );
    };

    private onLearnMore = (): void => {
        // Pending Task: https://jira.music.amazon.dev/browse/AEM-538
    };

    private setForMatureAudiences = (isExplicit: boolean): void => {
        const newExperienceDraft: ExperienceDraft = {
            ...this.props.experienceDraft,
            eligibility: {
                ...this.props.experienceDraft.eligibility,
                explicit: isExplicit,
            },
            isMetadataEdited: true,
        };
        this.props.setExperienceDraft(newExperienceDraft);
    };

    private setNewDates = (startDate: Date, endDate: Date): void => {
        const newExperienceDraft: ExperienceDraft = {
            ...this.props.experienceDraft,
            eligibility: {
                ...this.props.experienceDraft.eligibility,
                schedule: {
                    startDate: startDate,
                    expirationDate: endDate,
                },
            },
            isMetadataEdited: true,
        };

        this.props.setExperienceDraft(newExperienceDraft);
    };

    private setTitle = (title: string): void => {
        const newExperienceDraft: ExperienceDraft = {
            ...this.props.experienceDraft,
            title: title.trim(),
            isMetadataEdited: true,
        };
        this.props.setExperienceDraft(newExperienceDraft);
    };

    private setDefaultStartAndEndDates = (): void => {
        this.setNewDates(this.getDefaultStartDate(), this.getDefaultEndDate());
    };

    /**
     * If necessary, snaps the datetime and the UTC datetime to the same day/month, so that both the UI
     * and the backend will be able to interpret the date correctly. The UI needs the correct local date, and the backend
     * needs the correct UTC date.
     */
    private snapDate = (date: Date): Date => {
        const newDate = new Date(date);

        // East of UK
        if (newDate.getTimezoneOffset() < 0) {
            newDate.setHours(0, -newDate.getTimezoneOffset(), 0, 0);
            // West of UK
        } else {
            newDate.setHours(0, 0, 0, 0);
        }

        return newDate;
    };

    /**
     * This changes a date to its UTC Equivilant.  This should only be used by the CustomDatePicker
     * When we want the customer to select a Date in UTC Time rather than their local timezone
     * Important that when this is used, the date that comes out of the time picker is converted back
     * to the correct local timezone to prevent UTC day to not match local day (see function snapDate)
     */
    private dateToUTC = (date: Date): Date => {
        const newDate = new Date(date);
        newDate.setHours(
            0,
            newDate.getHours() * 60 + newDate.getTimezoneOffset(),
            0,
            0
        );
        return newDate;
    };

    private getDefaultStartDate = (): Date => {
        const associatedMediaItem = AnnouncementUtil.getAssociatedBaseMediaItem(
            this.props.experienceDraft.experience,
            this.props.catalog
        );

        // If test media item, assume release date
        if (
            associatedMediaItem?.asin &&
            CommonTestData.testBaseMediaItemTitlesetAsins.has(
                associatedMediaItem?.asin
            )
        ) {
            return this.snapDate(new Date(Date.now()));
        } else if (associatedMediaItem?.localOriginalReleaseDate) {
            return new Date(
                new Date(
                    associatedMediaItem?.localOriginalReleaseDate
                ).getTime()
            );
        }

        return this.snapDate(new Date(Date.now()));
    };

    private getDefaultEndDate = (): Date => {
        const associatedMediaItem = AnnouncementUtil.getAssociatedBaseMediaItem(
            this.props.experienceDraft.experience,
            this.props.catalog
        );

        // If test media item, assume release date
        if (
            associatedMediaItem?.asin &&
            CommonTestData.testBaseMediaItemTitlesetAsins.has(
                associatedMediaItem?.asin
            )
        ) {
            return this.snapDate(new Date(Date.now() + MSEC_PER_DAY * 28));
        } else if (associatedMediaItem?.localOriginalReleaseDate) {
            return new Date(
                new Date(
                    associatedMediaItem?.localOriginalReleaseDate
                ).getTime() +
                    MSEC_PER_DAY * 28
            );
        }

        return this.snapDate(new Date(Date.now() + MSEC_PER_DAY * 28));
    };

    private onHideDatePicker = (): void => {
        this.setState({
            showCustomPicker: false,
        });
    };

    private onShowDatePicker = (): void => {
        this.setState({
            showCustomPicker: true,
        });
    };

    private hideMatureContentHelpModal = () => {
        this.setState({ showMatureContentHelpModal: false });
    };

    private acceptCustomPicker = (startDate: Date, endDate: Date) => {
        const newStartDate = this.snapDate(new Date(startDate));
        const newEndDate = this.snapDate(new Date(endDate));

        this.setState({
            showCustomPicker: false,
        });

        this.setNewDates(newStartDate, newEndDate);
    };

    private onPlayPause = (): void => {
        if (!this.state.audioPlayer) {
            return;
        }

        if (this.state.audioPlayer.paused) {
            this.state.audioPlayer.play();
            this.setState({
                audioIsPaused: false,
            });
        } else {
            this.state.audioPlayer.pause();
            this.setState({
                audioIsPaused: true,
            });
        }
    };

    private createNewAudioPlayer = (audioFile: string): void => {
        const newAudioPlayer: HTMLAudioElement = new Audio(audioFile);

        newAudioPlayer.onended = () => {
            this.setState({
                audioIsPaused: true,
            });
        };

        this.setState({
            audioPlayer: newAudioPlayer,
        });
    };
}
