import _ from "lodash";
import { AnnouncementConstants } from "..";
import {
    AnnouncementDraft,
    baseCatalogItem,
    baseMediaItem,
    catalogItem,
    Experience,
    ExperienceAssetKey,
    ExperienceAssociatedEntity,
    ExperienceState,
} from "../../../models";
import { ExperienceUtil } from "../ArtistStudioHelper";

const ARCHIVABLE_STATES = [ExperienceState.APPROVED, ExperienceState.LIVE];

const announcementIsANewRelease = (entities: ExperienceAssociatedEntity[]) => {
    return _.reduce(
        _.map(
            entities,
            (entity: ExperienceAssociatedEntity) => !!entity.newRelease
        ),
        (prev: boolean, curr: boolean) => prev && curr,
        true
    );
};

type AnnouncementActions = {
    update: boolean;
    archive: boolean;
    delete: boolean;
};
const getAnnouncementActions = (
    experience: Experience
): AnnouncementActions => {
    const result = {
        update: announcementIsANewRelease(experience.associatedEntities),
        archive: _.includes(ARCHIVABLE_STATES, experience.experienceState),
        delete: true,
    };

    return result;
};

const getAssociatedBaseMediaItem = (
    experience: Experience,
    catalog: Map<string, catalogItem>
): baseMediaItem | undefined => {
    if (experience.associatedEntities.length >= 1) {
        return catalog.get(
            experience.associatedEntities[0].identifier
        ) as baseMediaItem;
    }
    return undefined;
};

const loadNewAnnouncementDraft = (
    catalogItem: baseCatalogItem,
    id: string,
    artistAsin: string,
    associatedEntities: ExperienceAssociatedEntity[]
): AnnouncementDraft => {
    return {
        ...AnnouncementConstants.blankAnnouncementDraft,
        experience: {
            ...AnnouncementConstants.blankAnnouncementDraft.experience,
            experienceId: id,
            experienceState: ExperienceState.DRAFT,
            publishingArtistAsin: artistAsin,
            associatedEntities: associatedEntities,
        },
        backgroundImageURL: catalogItem?.images?.artExtraLarge || "",
    };
};

const loadAnnouncementDraftFromExperience = (
    experience: Experience
): AnnouncementDraft => {
    const backgroundImage: string =
        ExperienceUtil.getExperienceAssetFromExperience(
            experience,
            ExperienceAssetKey.BACKGROUND
        )?.asset || "";
    const audioMessage: string =
        ExperienceUtil.getExperienceAssetFromExperience(
            experience,
            ExperienceAssetKey.MESSAGE
        )?.asset || "";
    const headline: string =
        ExperienceUtil.getExperienceAssetFromExperience(
            experience,
            ExperienceAssetKey.TITLE
        )?.asset || "";

    return {
        ...AnnouncementConstants.blankAnnouncementDraft,
        experience: experience,
        title: experience.experienceTitle,
        eligibility: {
            ...experience.eligibility,
            schedule: {
                startDate: experience.eligibility.schedule?.startDate
                    ? new Date(experience.eligibility.schedule?.startDate)
                    : undefined,
                expirationDate: experience.eligibility.schedule?.expirationDate
                    ? new Date(experience.eligibility.schedule?.expirationDate)
                    : undefined,
            },
        },
        isEdit: true,
        backgroundImageURL: backgroundImage,
        headline: headline,
        audioURL: audioMessage,
    };
};

export const AnnouncementUtil = {
    getAnnouncementActions,
    loadAnnouncementDraftFromExperience,
    loadNewAnnouncementDraft,
    getAssociatedBaseMediaItem,
};
