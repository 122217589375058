import { AnnouncementDraft, ExperienceState, ExperienceType } from "../../../models"

const blankAnnouncementDraft: AnnouncementDraft = {
    getInProgress: false,
    compositeUpdateInProgress: false,
    updateAssetsInProgress: false,
    updateMetadataInProgress: false,
    updateStateInProgress: false,
    createInProgress: false,
    uploadToCASInProgress: false,
    assets: [],
    title: "",
    eligibility: {
        explicit: false,
    },
    isMetadataEdited: false,
    isEdit: false,
    experience: {
        experienceId: "",
        experienceType: ExperienceType.ANNOUNCEMENT,
        experienceTitle: "",
        experienceState: ExperienceState.DRAFT,
        publishingArtistAsin: "",
        eligibility: {
            explicit: false,
            schedule: undefined,
        },
        associatedEntities: [],
        assets: [],
    },
    backgroundImageNeedsUpload: false,
    backgroundImageWasEdited: false,
    backgroundImageURL: "",
    backgroundImageName: "",
    headline: "",
    headlineWasEdited: false,
    audioWasEdited: false,
    audioName: "",
    audioURL: "",
}

export const AnnouncementConstants = {
    blankAnnouncementDraft,
}