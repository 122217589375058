import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    HelpModal,
    MediumOutlineButton,
    MediumSolidButton,
} from "../../common";
import * as rootStyles from "../../../styles";
import { stringIds, bundleIds } from "../../../../assets";
import { getLocalizedString } from "../../../../utils";
import { BundleMap } from "../../../../models";

const testIDPrefix = "AnnouncementLearnMoreModal";

type StateProps = {
    isVisible: boolean;
    onDone: () => void;
    onLearnMore?: () => void;
    bundleMap: BundleMap;
};

export class AnnouncementLearnMoreModal extends React.Component<StateProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <HelpModal
                isVisible={this.props.isVisible}
                onDismiss={this.props.onDone}
                title={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                    stringId:
                        stringIds.ArtistStudio.Announcement
                            .announcementHelpModalHeadline,
                })}
                titleStyle={{ textAlign: "center" }}
                body={this.helpBody}
                footer={this.helpFooter}
                id={testIDPrefix}
            />
        );
    }

    private helpBody = () => {
        return (
            <span
                style={{
                    ...rootStyles.textStyles.primary,
                    display: "flex",
                    textAlign: "center",
                }}
            >
                {getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                    stringId:
                        stringIds.ArtistStudio.Announcement
                            .announcementHelpModalBody,
                })}
            </span>
        );
    };

    private helpFooter = () => {
        return (
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    gap: rootStyles.spacers.small,
                }}
            >
                <Row
                    style={{
                        width: "100%",
                        marginTop: rootStyles.spacers.small,
                    }}
                >
                    {this.props.onLearnMore && (
                        <MediumOutlineButton
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementHelpModalLearnMore,
                            })}
                            containerStyle={{ flex: 1 }}
                            onClick={this.props.onLearnMore}
                            id={`${testIDPrefix}_LearnMore`}
                        />
                    )}
                </Row>
                <Row style={{ width: "100%" }}>
                    <MediumSolidButton
                        title={getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementHelpModalOk,
                        })}
                        containerStyle={{ flex: 1 }}
                        onClick={this.props.onDone}
                        id={`${testIDPrefix}_Done`}
                    />
                </Row>
            </Col>
        );
    };
}
