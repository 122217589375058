import axios from "axios";
import { manageBluePrintsRequest, manageBluePrintsResponse } from "../models";
import { getEndpoints } from "./common";

const artistControlUrl = getEndpoints().artistControl;
const manageSkillUrl = `${artistControlUrl}/artist/blueprints/manage`;

export async function manageSkills(
    query: manageBluePrintsRequest
): Promise<manageBluePrintsResponse> {
    const result = await sendRequest(
        manageSkillUrl,
        query.teamId,
        query.artistAsin
    );

    return result?.data;
}

function sendRequest(url: string, teamId: string, artistAsin: string) {
    return axios.post(
        url,
        { artistAsin },
        {
            headers: {
                "x-team-id": teamId,
            },
        }
    );
}
