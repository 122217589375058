import { pageIds } from "@amzn/ziggy-asset";
import { paths } from "../../utils";

export enum ClientMetricsType {
    UiClick = "uiClick",
    UiPageView = "uiPageView",
}

export const PathToPageIdMap = new Map<string, string>([
    [paths.accountDetails, pageIds.account],
    [paths.claim, pageIds.claimThisArtist],
    [paths.claimDone, pageIds.claimSubmissionOut],
    [paths.requesterType, pageIds.claimRequesterAccessType],
    [paths.vendorFlow, pageIds.claimRequestInvite],
    [paths.vendorFlowLearnMore, pageIds.claimVendorLabelLearnMore],
    [paths.artistSearch, pageIds.searchForArtist],
    [paths.provideInformation, pageIds.provideYourInformation],
    [paths.profile, pageIds.profile],
    [paths.newReleases, pageIds.newRelease],
    [paths.pitch, pageIds.pitch],
    [paths.reports, pageIds.artistSelected],
    [paths.albums, pageIds.albums],
    [paths.songs, pageIds.songs],
    [paths.songPerformance, pageIds.songPerformance],
    [paths.playlistPerformance, pageIds.playlistPerformance],
    [paths.stationPerformance, pageIds.stationPerformance],
    [paths.albumPerformance, pageIds.albumPerformance],
    [paths.artistSelect, pageIds.artistSelect],
    [paths.currentTeamMembers, pageIds.currentTeamMembers],
    [paths.invitedTeamMembers, pageIds.inviteTeamMember],
    [paths.inviteTeamMember, pageIds.inviteTeamMember],
    [paths.manageTeamMember, pageIds.currentTeamMembers],
    [paths.playlists, pageIds.playlists],
    [paths.newAddsToPlaylists, pageIds.newAddsToPlaylists],
    [paths.stations, pageIds.stations],
    [paths.voice, pageIds.voice],
    [paths.programming, pageIds.programming],
    [paths.fans, pageIds.fans],
    [paths.countries, pageIds.countries],
    [paths.albumTracks, pageIds.albumTracks],
    [paths.songCountries, pageIds.songCountries],
    [paths.albumCountries, pageIds.albumCountries],
    [paths.stationCountries, pageIds.stationCountries],
    [paths.playlistCountries, pageIds.playlistCountries],
    [paths.settings, pageIds.settings],
    [paths.termsAndConditions, pageIds.termsAndConditions],
    [paths.oobe, pageIds.firstLogInScreen],
    [paths.acceptInvite, pageIds.acceptInvite],
    [paths.openSourceAttribution, pageIds.openSourceAttribution],
    [paths.optOut, pageIds.optOut],
    [paths.reportArtistProfile, pageIds.reportArtistIssue],
    [paths.blueprints, pageIds.blueprints],
    [paths.announcements, pageIds.artistStudioAnnouncements],
    [paths.announcementDetails, pageIds.artistStudioAnnouncementDetails],
    [paths.merch, pageIds.artistMerchHomePage],
    [paths.modLanding, pageIds.artistMerchMoDLandingScreen],
    [paths.autoSearchMerch, pageIds.artistMerchAutoSearchScreen],
    [paths.manualSearchMerch, pageIds.artistMerchManualSearchScreen],
    [paths.seeAllMerch, pageIds.artistMerchSeeAllScreen],
    [paths.promoCard, pageIds.promoCard],
    // Set the below pageIds to empty as these paths don't represent a user visible page in ZiggyWeb
    [paths.checkTeamExists, ""],
    [paths.signOut, ""],
    [paths.home, ""],
    [paths.initialFlow, ""],
    [paths.deeplinkResolver, ""],
]);
