import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    CropImageModal,
    InlineWarning,
    styledTitle,
    TextField,
} from "../../common";
import * as rootStyles from "../../../styles";
import { stringIds, bundleIds } from "../../../../assets";
import {
    BACKGROUND_IMAGE_MAX_BYTES,
    getLocalizedString,
    IMAGE_COMPRESSION,
} from "../../../../utils";
import {
    album,
    artist,
    BundleMap,
    catalogItem,
    ExperienceDraft,
} from "../../../../models";
import { ExperienceUploadImage } from "..";
import { Area, Point } from "react-easy-crop/types";

type StateProps = {
    album?: album;
    artist?: artist;
    catalog: Map<string, catalogItem>;
    setExperienceDraft: (experienceDraft: ExperienceDraft) => void;
    experienceDraft: ExperienceDraft;
    bundleMap: BundleMap;
};

type State = {
    showError: boolean;
    errorMessage: string;
    selectedImage: string;
    previousSelectedImage: string;
    showCropBackground: boolean;
    crop: Point;
    zoom: number;
    showBackgroundAlert: boolean;
    backgroundAlertText: string;
    croppedAreaPixels?: Area;
    croppedImageUrl?: string;
};

const testIDPrefix = "AnnouncementAddBackground";
const metricPrefix = "announcementAddBackground";

const BACKGROUND_IMAGE_MIN_SIZE = 1100;

const HEADLINE_FIELD_ROWS = 5;
const HEADLINE_MAX_LENGTH = 75;

export class AnnouncementAddBackground extends React.Component<
    StateProps,
    State
> {
    constructor(props: any) {
        super(props);
        this.state = {
            showError: false,
            errorMessage: "",
            selectedImage: "",
            previousSelectedImage: "",
            showCropBackground: false,
            crop: { x: 0, y: 0 },
            zoom: 1,
            showBackgroundAlert: false,
            backgroundAlertText: "",
        };
    }

    componentDidMount() {
        if (this.props.experienceDraft.backgroundImageURL) {
            this.setState({
                selectedImage: this.props.experienceDraft.backgroundImageURL,
            });
        }
    }

    render() {
        const AddHeadlineHeader = () => {
            return (
                <Col>
                    <Row>
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementAddBackgroundHeadlineHeader,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementAddBackgroundHeadlineSubHeader,
                            })}
                        </span>
                    </Row>
                </Col>
            );
        };

        const AddHeadlineTextArea = () => {
            return (
                <Col
                    style={{
                        maxWidth: 616, // From UX Spec
                    }}
                >
                    <TextField
                        multiLine={true}
                        maxLength={HEADLINE_MAX_LENGTH}
                        id={`${testIDPrefix}-Description`}
                        onChange={this.setHeadline}
                        defaultValue={this.props.experienceDraft.headline}
                        showCharacterCount={true}
                        visibleRows={HEADLINE_FIELD_ROWS}
                        requiredFieldMsgText={`*${getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formIsRequired,
                            }
                        )}`}
                    />
                </Col>
            );
        };

        const AddBackgroundHeader = () => {
            return (
                <Col>
                    <Row>
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementAddBackgroundImageHeader,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementAddBackgroundImageSubHeader,
                            })}
                        </span>
                    </Row>
                </Col>
            );
        };

        const cropImageModal = () => {
            return (
                <Row
                    style={{
                        justifyContent: "center",
                    }}
                >
                    <CropImageModal
                        labelText={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.PROFILE_STRINGS,
                            stringId: stringIds.Profile.instruction,
                        })}
                        cropShape="rect"
                        image={this.state.selectedImage}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={1} // 1:1 aspect ratio
                        onZoomIn={this.onZoomIn}
                        onZoomOut={this.onZoomOut}
                        onCropChange={this.onCropChange}
                        onZoomChange={this.onZoomChange}
                        onCropComplete={this.onBackgroundCropComplete}
                        showAlert={this.state.showBackgroundAlert}
                        alertText={this.state.backgroundAlertText}
                        text={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.PROFILE_STRINGS,
                            stringId: stringIds.Profile.uploadBackground,
                        })}
                        description={getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementAddBackgroundCropperHelpText,
                        })}
                        uploadButtonText={getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.cancel,
                            }
                        )}
                        uploadButtonAction={this.onDismissCropBackground} // Cancel button for this use case
                        saveButtonText={getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.PROFILE_STRINGS,
                                stringId: stringIds.Profile.upload,
                            }
                        )}
                        saveButtonAction={this.saveCroppedImage}
                        onDismiss={this.onDismissCropBackground}
                        isVisible={this.state.showCropBackground}
                        id={testIDPrefix + "_CropBackgroundImageModal"}
                    />
                </Row>
            );
        };

        return (
            <>
                {cropImageModal()}
                {AddHeadlineHeader()}
                {AddHeadlineTextArea()}
                {AddBackgroundHeader()}
                {this.state.showError && (
                    <InlineWarning
                        text={this.state.errorMessage}
                        id={`${testIDPrefix}_Error`}
                    />
                )}
                <ExperienceUploadImage
                    experienceDraft={this.props.experienceDraft}
                    album={this.props.album}
                    onClickAlbumDefault={this.onClickAlbumDefault}
                    readImageFileIn={this.readImageFileIn}
                    onClickEdit={() => {
                        this.setState({
                            showCropBackground: true,
                        });
                    }}
                    showEditButton={
                        !!this.props.experienceDraft.backgroundImageBlob
                    }
                    bundleMap={this.props.bundleMap}
                />
            </>
        );
    }

    private onClickAlbumDefault = (): void => {
        if (!this.props.album?.images?.artExtraLarge) {
            this.setState({
                errorMessage: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ERRORS_STRINGS,
                    stringId: stringIds.Errors.genericError,
                }),
                showError: true,
            });
            return;
        }
        this.setState({
            selectedImage: this.props.album?.images?.artExtraLarge,
            showError: false,
        });
        this.setBackgroundImage(
            this.props.album?.images?.artExtraLarge,
            true,
            false
        );
    };

    private setHeadline = (headline: string): void => {
        const newExperienceDraft: ExperienceDraft = {
            ...this.props.experienceDraft,
            headline: headline.trim(),
            headlineWasEdited: true,
        };
        this.props.setExperienceDraft(newExperienceDraft);
    };

    private setBackgroundImage = (
        backgroundImage: string,
        wasEdited: boolean,
        needsUpload: boolean,
        backgroundImageBlob?: Blob,
        backgroundImageName?: string
    ): void => {
        const newExperienceDraft: ExperienceDraft = {
            ...this.props.experienceDraft,
            backgroundImageURL: backgroundImage,
            backgroundImageNeedsUpload: needsUpload,
            backgroundImageBlob: backgroundImageBlob,
            backgroundImageWasEdited: wasEdited,
            backgroundImageName: !needsUpload
                ? ""
                : backgroundImageName ||
                  this.props.experienceDraft.backgroundImageName,
        };
        this.props.setExperienceDraft(newExperienceDraft);
    };

    private saveCroppedImage = (): void => {
        const image = this.state.selectedImage;
        const crop = this.state.croppedAreaPixels;

        if (!crop || !image) {
            return;
        }

        this.saveCrop(image, crop);
    };

    private saveImage = (canvas: HTMLCanvasElement) => {
        const dataUrl = canvas?.toDataURL();

        if (!dataUrl) {
            return;
        }

        canvas &&
            canvas.toBlob(
                (blob) => {
                    if (!blob || !blob.type) {
                        return;
                    }

                    if (blob.size > BACKGROUND_IMAGE_MAX_BYTES) {
                        this.setState({
                            showBackgroundAlert: true,
                            backgroundAlertText: getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.PROFILE_STRINGS,
                                    stringId:
                                        stringIds.Profile.backgroundSizeAlert,
                                }
                            ),
                        });
                    } else {
                        this.setBackgroundImage(dataUrl, true, true, blob);

                        this.setState({
                            showCropBackground: false,
                            showError: false,
                            previousSelectedImage: this.state.selectedImage,
                        });
                    }
                },
                "image/jpeg",
                IMAGE_COMPRESSION
            );
    };

    private readImageFileIn = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        const file = event.target.files && event.target.files[0];

        fileReader.onloadend = () => {
            const result = fileReader.result;

            if (typeof result === "string") {
                const image = document.createElement("img");

                image.onload = () => {
                    if (
                        image.height < BACKGROUND_IMAGE_MIN_SIZE ||
                        image.width < BACKGROUND_IMAGE_MIN_SIZE
                    ) {
                        this.setState({
                            showError: true,
                            errorMessage: getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId:
                                        bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                    stringId:
                                        stringIds.ArtistStudio.Announcement
                                            .announcementAddBackgroundCropperImageTooSmallText,
                                }
                            ),
                        });
                        return;
                    }

                    this.setState({
                        showError: false,
                        previousSelectedImage: this.state.selectedImage,
                        selectedImage: result,
                    });

                    if (
                        image.height === image.width &&
                        result.length <= BACKGROUND_IMAGE_MAX_BYTES
                    ) {
                        // if aspect ratio is 1:1 and file size isnt above the max, just save it, otherwise open up cropper
                        this.setBackgroundImage(
                            result,
                            true,
                            true,
                            file || undefined,
                            file?.name || ""
                        );

                        this.setState({
                            previousSelectedImage: result,
                        });
                    } else {
                        this.setState({
                            showCropBackground: true,
                        });
                    }
                };

                image.src = result;
            }
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    // Steps the zoom in by 0.2
    // Slider is used to step the the Zoom in and out
    // This zoom value is used together with the aspect ratio
    // and the max zoom of the image (largest image while maintaining the expected crop aspect ratio)
    // to calculate the dimensions of the cropped image
    private onZoomIn = () => {
        this.setState({ zoom: this.state.zoom + 0.2 });
    };

    private onZoomOut = () => {
        const newZoom = this.state.zoom - 0.2;

        if (newZoom >= 1) {
            this.setState({ zoom: newZoom });
        }
    };

    private onCropChange = (crop: Point) => {
        this.setState({ crop: crop });
    };

    private onZoomChange = (zoom: number) => {
        this.setState({ zoom: zoom });
    };

    private onBackgroundCropComplete = (
        _croppedArea: Area,
        croppedAreaPixels: Area
    ) => {
        this.setState({
            croppedAreaPixels: croppedAreaPixels,
            showBackgroundAlert:
                croppedAreaPixels.width < BACKGROUND_IMAGE_MIN_SIZE ||
                croppedAreaPixels.height < BACKGROUND_IMAGE_MIN_SIZE,
            backgroundAlertText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                stringId:
                    stringIds.ArtistStudio.Announcement
                        .announcementAddBackgroundCropperImageTooSmallText,
            }),
        });
    };

    private onDismissCropBackground = () => {
        this.setState({
            showCropBackground: false,
            selectedImage: this.state.previousSelectedImage,
        });
    };

    private saveCrop(imageSrc: string, pixelCrop: Area) {
        const image = document.createElement("img");

        image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = image.width;
            canvas.height = image.height;

            if (!ctx) {
                return;
            }

            ctx.drawImage(image, 0, 0);
            const data = ctx.getImageData(0, 0, image.width, image.height);

            // set canvas width to final desired crop size - this will clear existing context
            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;

            // paste generated rotate image with correct offsets for x,y crop values.
            ctx.putImageData(data, 0 - pixelCrop.x, 0 - pixelCrop.y);

            this.saveImage(canvas);
        };
        image.crossOrigin = "Anonymous";
        image.src = imageSrc;
    }
}
