import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    FullScreenConfirmationModal,
    Icon,
    IconTile,
    InlineWarning,
    LargeGlassButton,
    styledTitle,
} from "../../common";
import * as rootStyles from "../../../styles";
import { IconsList, ImageList, stringIds, bundleIds } from "../../../../assets";
import {
    BYTES_IN_MEGABYTE,
    getLocalizedString,
    ExperienceUtil,
    TelemetryUtil,
    DeviceUtil,
    getLocalizedUrl,
} from "../../../../utils";
import {
    BundleMap,
    ExperienceDraft,
    METRIC_KEYS,
    telemetryPayload,
} from "../../../../models";
import { LocalizedUrls } from "@amzn/ziggy-asset";

type Props = {
    setExperienceDraft: (experienceDraft: ExperienceDraft) => void;
    experienceDraft: ExperienceDraft;
    userAction: (payload: telemetryPayload) => void;
    pagePath: string;
    bundleMap: BundleMap;
};

type State = {
    showHelpModal: boolean;
    showError: boolean;
    audioFileRef: React.RefObject<HTMLInputElement>;
    audioPlayer?: HTMLAudioElement;
    audioMetaDataLoaded: boolean;
    audioIsPaused: boolean;
};

const testIDPrefix = "AnnouncementAddAudio";
const metricPrefix = "announcementAddAudio";
const MIN_AUDIO_DURATION_IN_SECONDS = 2;
const MAX_AUDIO_DURATION_IN_SECONDS = 16;
const MAX_AUDIO_REDRIVE_ATTEMPTS = 3;
const DEFAULT_DURATION_RENDER = "00:00:00";
const ISOSliceStartIndex = "YYYY-MM-DDTHH:".length;
const ISOSliceEndIndex = ISOSliceStartIndex + "MM:SS".length;


const MAX_AUDIO_FILE_SIZE = BYTES_IN_MEGABYTE * 5;

export class AnnouncementAddAudio extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showHelpModal: false,
            showError: false,
            audioFileRef: React.createRef(),
            audioPlayer: undefined,
            audioMetaDataLoaded: false,
            audioIsPaused: true,
        };
    }

    componentDidMount() {
        if (this.props.experienceDraft.audioURL) {
            this.createNewAudioPlayer(this.props.experienceDraft.audioURL, 0);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.experienceDraft.audioURL !==
            prevProps.experienceDraft.audioURL
        ) {
            this.setState({
                audioPlayer: undefined,
                audioMetaDataLoaded: false,
            });

            if (this.props.experienceDraft.audioURL) {
                this.createNewAudioPlayer(
                    this.props.experienceDraft.audioURL,
                    0
                );
            }
        }
    }

    render() {
        const AddAudioHeader = () => {
            return (
                <Col>
                    <Row>
                        <styledTitle.h4>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementAddAudioWidgetHeader,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementAddAudioWidgetSubHeader,
                            })}
                        </span>
                    </Row>
                </Col>
            );
        };

        return (
            <>
                {AddAudioHeader()}
                <Row
                    style={{
                        marginLeft: rootStyles.spacers.base,
                        marginRight: rootStyles.spacers.base,
                    }}
                >
                    {this.AddAudioHelpModal()}
                    <Col
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: rootStyles.spacers.medium,
                            backgroundColor: rootStyles.glassColors.primary2,
                            paddingTop: rootStyles.spacers.small,
                            paddingBottom: rootStyles.spacers.giant,
                            paddingRight: 0,
                            paddingLeft: 0,
                            borderRadius: 8,
                            maxWidth: 616, // From UX Spec
                        }}
                    >
                        {this.state.showError && this.audioUploadErrorMessage()}
                        {this.audioUploadMessage()}
                        {this.state.audioMetaDataLoaded &&
                            this.audioWaveImage()}
                        {this.audioActions()}
                        <input
                            accept={ExperienceUtil.getSupportedAudioFileTypesCommaDelimited()}
                            ref={this.state.audioFileRef}
                            type="file"
                            style={{ display: "none" }}
                            value={""}
                            onChange={this.readAudioFileIn}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    private audioUploadErrorMessage = (): JSX.Element => {
        return (
            <Row
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <InlineWarning
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .announcementAddAudioWidgetErrorMessage,
                    })}
                    id={`${testIDPrefix}_Error`}
                />
            </Row>
        );
    };

    private audioUploadMessage = (): JSX.Element => {
        const helpIcon = () => {
            return (
                <Icon
                    icon={IconsList.ic_help}
                    size={rootStyles.icons.tiny}
                    style={{
                        opacity: rootStyles.glass._4,
                        marginLeft: rootStyles.spacers.mini,
                    }}
                    onClick={this.showHelpModal}
                    id={`${testIDPrefix}_HelpButton`}
                />
            );
        };

        return (
            <Row
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: this.state.showError
                        ? 0
                        : rootStyles.spacers.large,
                }}
            >
                <span style={rootStyles.textStyles.primary}>
                    {!this.state.audioMetaDataLoaded
                        ? getLocalizedString(this.props.bundleMap, {
                              bundleId:
                                  bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                              stringId:
                                  stringIds.ArtistStudio.Announcement
                                      .announcementAddAudioWidgetHelperText,
                          })
                        : getLocalizedString(this.props.bundleMap, {
                              bundleId:
                                  bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                              stringId:
                                  stringIds.ArtistStudio.Announcement
                                      .announcementAddAudioWidgetAudioAdded,
                          })}
                </span>
                {!this.state.audioMetaDataLoaded && helpIcon()}
            </Row>
        );
    };

    private audioActions = (): JSX.Element => {
        const uploadAudio = (): JSX.Element => {
            return (
                <LargeGlassButton
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .announcementAddAudioWidgetCTA,
                    })}
                    onClick={this.onUploadAudio}
                    leftIcon={IconsList.action_upload}
                    containerStyle={{
                        background: "transparent",
                    }}
                    buttonStyle={{
                        gap: rootStyles.spacers.epic,
                    }}
                    renderRightSideSpacer={true}
                    id={`${testIDPrefix}_UploadAudio`}
                />
            );
        };

        const audioPlayer = (): JSX.Element => {
            const playPauseIcon = this.state.audioIsPaused
                ? IconsList.playback_play
                : IconsList.playback_pause;
            return (
                <Row
                    style={{
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        gap: rootStyles.spacers.small,
                        flex: 1,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: rootStyles.spacers.small,
                        }}
                    >
                        <IconTile
                            icon={IconsList.ic_audio_input}
                            iconSize={rootStyles.icons.small}
                            id={`${testIDPrefix}_AudioAddedIcon`}
                        />

                        <Col
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <span
                                style={{
                                    ...rootStyles.textStyles.primary,
                                }}
                            >
                                {this.getAudioFileName()}
                            </span>
                            <span
                                style={{
                                    ...rootStyles.textStyles.secondary,
                                }}
                            >
                                {this.getFormatedAudioDuration()}
                            </span>
                        </Col>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: rootStyles.spacers.base,
                        }}
                    >
                        <Icon
                            icon={playPauseIcon}
                            size={rootStyles.icons.small}
                            onClick={this.onPlayPause}
                            id={`${testIDPrefix}_PlayPause`}
                        />

                        <Icon
                            icon={IconsList.action_removeInline}
                            size={rootStyles.icons.small}
                            onClick={this.onRemoveAudio}
                            id={`${testIDPrefix}_RemoveAudio`}
                        />
                    </div>
                </Row>
            );
        };

        return (
            <Row
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {this.state.audioMetaDataLoaded ? audioPlayer() : uploadAudio()}
            </Row>
        );
    };

    private audioWaveImage = (): JSX.Element => {
        return <img src={ImageList.audio_wave} style={{}} />;
    };

    private footerHelpLink = (): JSX.Element => {
        return (
            <Row
                style={{
                    justifyContent: "center",
                }}
            >
                <span style={rootStyles.textStyles.secondary}>
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .announcementAddAudioHelpFooterQuestion,
                    })}{" "}
                    <span
                        style={{
                            color: rootStyles.colors.accent,
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            window.open(
                                getLocalizedUrl(
                                    LocalizedUrls.PitchReleaseIsMissingInstructionsURL
                                ),
                                "_blank"
                            )
                        }
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementAddAudioHelpFooterAction,
                        })}
                    </span>
                </span>
            </Row>
        );
    };

    private showHelpModal = (): void => {
        this.setState({
            showHelpModal: true,
        });
    };

    private hideHelpModal = (): void => {
        this.setState({
            showHelpModal: false,
        });
    };

    private onRemoveAudio = (): void => {
        this.setAudioInDraft("");
        this.setState({
            audioPlayer: undefined,
            showError: false,
        });
    };

    private onPlayPause = (): void => {
        if (!this.state.audioPlayer) {
            return;
        }

        if (this.state.audioPlayer.paused) {
            this.state.audioPlayer.play();
            this.setState({
                audioIsPaused: false,
            });
        } else {
            this.state.audioPlayer.pause();
            this.setState({
                audioIsPaused: true,
            });
        }
    };

    private onUploadAudio = (): void => {
        this.state.audioFileRef.current &&
            this.state.audioFileRef.current.click();
    };

    private createNewAudioPlayer = (
        audioFile: string,
        redriveAttempt: number
    ): void => {
        const newAudioPlayer: HTMLAudioElement = new Audio(audioFile);

        // Occurs when the duration and dimensions of the media have been determined.
        newAudioPlayer.onloadedmetadata = () => {
            // determine is duration is within expected parameters
            if (
                !newAudioPlayer.duration ||
                newAudioPlayer.duration < MIN_AUDIO_DURATION_IN_SECONDS ||
                newAudioPlayer.duration > MAX_AUDIO_DURATION_IN_SECONDS
            ) {
                // Error with wav/mp3 files on firefox
                // that cause duration to be wrong and very large.
                // A redrive should allow firefox to get the correct duration value
                if (
                    DeviceUtil.isBrowserFirefox() &&
                    redriveAttempt < MAX_AUDIO_REDRIVE_ATTEMPTS
                ) {
                    this.createNewAudioPlayer(audioFile, redriveAttempt + 1);
                    return;
                }
                // ERROR
                this.setState({
                    audioMetaDataLoaded: false,
                    showError: true,
                    audioPlayer: undefined,
                });
                this.setAudioInDraft("");
                return;
            }

            this.setState({
                audioMetaDataLoaded: true,
                showError: false,
            });
        };

        // Occurs everytime there is an error with the Audio player.
        newAudioPlayer.onerror = () => {
            if (!newAudioPlayer.error) {
                return;
            }

            this.props.userAction({
                name: `${metricPrefix}-AUDIO_ERROR`,
                dataPoints: new Map<string, string | undefined>([
                    ...TelemetryUtil.getDeviceInfo(),
                    [METRIC_KEYS.page, this.props.pagePath],
                    [METRIC_KEYS.errorMessage, newAudioPlayer.error.message],
                    [METRIC_KEYS.file, this.props.experienceDraft.audioURL],
                ]),
            });

            switch (newAudioPlayer.error.code) {
                case newAudioPlayer.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    this.setAudioInDraft("");
                    this.setState({
                        showError: true,
                        audioPlayer: undefined,
                    });
                    break;
                case newAudioPlayer.error.MEDIA_ERR_DECODE:
                    this.setAudioInDraft("");
                    this.setState({
                        showError: true,
                        audioPlayer: undefined,
                    });
                    break;
                default:
                    this.setState({
                        showError: true,
                    });
                    break;
            }
        };

        newAudioPlayer.onended = () => {
            this.setState({
                audioIsPaused: true,
            });
        };

        this.setState({
            audioPlayer: newAudioPlayer,
            audioMetaDataLoaded: false,
        });
    };

    private getAudioFileName = () => {
        return this.props.experienceDraft.audioName ?? "";
    };

    private getFormatedAudioDuration = () => {
        if (!this.state.audioPlayer || !this.state.audioPlayer.duration) {
            return DEFAULT_DURATION_RENDER;
        }
        // RAW ISO EXAMPLE: 1970-01-01T00:00:03.776Z
        // Formats to: MM:SS
        return new Date(Math.round(this.state.audioPlayer.duration) * 1000)
            .toISOString()
            .slice(ISOSliceStartIndex, ISOSliceEndIndex)
            .replace(".", ":");
    };

    private readAudioFileIn = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileReader = new FileReader();
        const file = event.target.files && event.target.files[0];

        fileReader.onloadend = () => {
            const result = fileReader.result;
            if (typeof result === "string") {
                if (result.length > MAX_AUDIO_FILE_SIZE) {
                    this.setAudioInDraft("");
                    this.setState({
                        showError: true,
                    });
                    return;
                }
                this.setAudioInDraft(result, file || undefined);
            }
        };

        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    private setAudioInDraft = (audio: string, file?: File): void => {
        const newExperienceDraft: ExperienceDraft = {
            ...this.props.experienceDraft,
            audioURL: audio,
            audioName: file?.name || "",
            audioBlob: file,
            audioWasEdited: true,
        };
        this.props.setExperienceDraft(newExperienceDraft);
    };

    private AddAudioHelpModal = () => {
        const stepInstruction = (
            icon: string,
            message: string,
            index: number
        ) => {
            return (
                <Row>
                    <Icon
                        size={rootStyles.icons.small}
                        icon={icon}
                        style={{
                            marginRight: rootStyles.spacers.mini,
                            flex: 0,
                        }}
                        id={`${testIDPrefix}_${index}`}
                    />
                    <span
                        style={{
                            ...rootStyles.textStyles.secondary,
                            color: rootStyles.colors.primary,
                            flex: 1,
                        }}
                    >
                        {message}
                    </span>
                </Row>
            );
        };

        const AddAudioHelpModalBody = () => {
            return (
                <Row
                    style={{
                        gap: rootStyles.spacers.small,
                    }}
                >
                    {stepInstruction(
                        IconsList.ic_step_1,
                        getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementAddAudioHelpModalBody1,
                        }),
                        1
                    )}
                    {stepInstruction(
                        IconsList.ic_step_2,
                        getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementAddAudioHelpModalBody2,
                        }),
                        2
                    )}
                    {stepInstruction(
                        IconsList.ic_step_3,
                        getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementAddAudioHelpModalBody3,
                        }),
                        3
                    )}
                </Row>
            );
        };

        return (
            <FullScreenConfirmationModal
                id={`${testIDPrefix}-HelpModal`}
                icon={IconsList.ic_audio_input}
                text={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                    stringId:
                        stringIds.ArtistStudio.Announcement
                            .announcementAddAudioHelpModalHeadline,
                })}
                body={AddAudioHelpModalBody()}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.ok,
                })}
                confirmButtonAction={this.hideHelpModal}
                onDismiss={this.hideHelpModal}
                isVisible={this.state.showHelpModal}
                buttonOrientation={"stacked"}
            />
        );
    };
}
