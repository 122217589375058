import React from "react";
import styled from "styled-components";
import "./AnnouncementsTableMoreOptionsDropdown.css";
import { Dropdown, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { dropDownStyles } from "../../../styles/buttonStyles";
import { IconsList, stringIds, bundleIds } from "../../../../assets";
import * as rootStyles from "../../../styles";
import { getLocalizedString } from "../../../../utils";
import { generateDropdownOption } from "../../common/helpers";
import { Icon } from "../..";
import { commonSelectors } from "../../../../store/selectors";
import { BundleMap } from "../../../../models";

type StateProps = {
    onSelectUpdate?: () => void;
    onSelectArchive?: () => void;
    onSelectDelete?: () => void;
    id: string;
};

export type AnnouncemenMoreOptionsProps = StateProps;

const AnnouncementMoreOptions: React.FC<AnnouncemenMoreOptionsProps> = ({
    onSelectUpdate,
    onSelectArchive,
    onSelectDelete,
    id,
}) => {
    const dropdownStyle: React.CSSProperties = dropDownStyles.dropdown;
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    return (
        <StyledDropdown style={dropdownStyle} drop={"left"}>
            <Dropdown.Toggle
                className={"announcementTableDropDownToggle"}
                id={`${id}_announcementTableDropDownToggle`}
                style={{
                    backgroundColor: "transparent",
                    borderColor: "transparent",
                    cursor: "pointer",
                    padding: `${rootStyles.spacers.micro}px`,
                }}
                as={Row}
            >
                <Icon
                    icon={IconsList.action_more}
                    size={rootStyles.icons.small}
                    id={`${id}_MoreIcon`}
                />
            </Dropdown.Toggle>

            <Dropdown.Menu
                style={{
                    width: 300,
                }}
            >
                {onSelectUpdate && (
                    <Dropdown.Item
                        key={"UpdateAnnouncement"}
                        eventKey={"UpdateAnnouncement"}
                        onSelect={onSelectUpdate}
                        id={`${id}_Update`}
                    >
                        {generateDropdownOption(
                            IconsList.action_edit,
                            getLocalizedString(bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementActionsMenuUpdate,
                            }),
                            `${id}_Update`
                        )}
                    </Dropdown.Item>
                )}
                {onSelectArchive && (
                    <Dropdown.Item
                        key={"ArchiveAnnouncement"}
                        eventKey={"ArchiveAnnouncement"}
                        onSelect={onSelectArchive}
                        style={dropDownStyles.dropdownItem}
                        id={`${id}_Archive`}
                    >
                        {generateDropdownOption(
                            IconsList.archive,
                            getLocalizedString(bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementActionsMenuArchive,
                            }),
                            `${id}_Archive`
                        )}
                    </Dropdown.Item>
                )}
                {onSelectDelete && (
                    <Dropdown.Item
                        key={"DeleteAnnouncement"}
                        eventKey={"DeleteAnnouncement"}
                        onSelect={onSelectDelete}
                        style={dropDownStyles.dropdownItem}
                        id={`${id}_Delete`}
                    >
                        {generateDropdownOption(
                            IconsList.action_delete,
                            getLocalizedString(bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementActionsMenuDelete,
                            }),
                            `${id}_Delete`
                        )}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </StyledDropdown>
    );
};

const StyledDropdown = styled(Dropdown)`
    display: flex;
    float: left;
    z-index: 1000;
    margin: auto 0px auto 16px;
`;

export default AnnouncementMoreOptions;
