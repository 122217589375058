import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { AnyAction } from "redux";
import {
    artistIssueActions,
    artistStudioActions,
    clientMetricsActions,
    experienceAnnouncementSelector,
    globalNotificationsActions,
    pitchActions,
    pitchFormActions,
    RootState,
    telemetryActions,
    userActions,
} from "../../../store";
import {
    buildUIClickPayload,
    concatenateArtistNames,
    ExperienceUtil,
    generateNewReleaseRow,
    getLocalizedString,
    paths,
} from "../../../utils";
import {
    CommonHeader,
    Footer,
    FormNavigation,
    FormNavigationStep,
    FullScreenConfirmationModal,
    InlineError,
    Loading,
    TableRow,
    AnnouncementAddAudio,
    AnnouncementAddBackground,
    AnnouncementReview,
} from "../../components";
import * as rootStyles from "../../styles";
import { IconsList, stringIds, bundleIds } from "../../../assets";
import {
    album,
    albumRelease,
    baseMediaItem,
    CatalogProps,
    ErrorPayload,
    listTracksToPitchPayload,
    METRIC_KEYS,
    telemetryPayload,
    track,
    artist,
    ExperienceDraft,
    ExperienceType,
    ExperienceEntityType,
    CASUploadPayload,
    ExperienceAssetContext,
    ExperienceAssetKey,
    ExperienceAssetType,
    ExperienceAssetSource,
    CreateExperiencePayload,
    ExperienceAsset,
    ExperienceCreationMode,
    ExperienceState,
    UpdateExperienceAssetsPayload,
    UpdateExperienceMetadataPayload,
    UpdateExperienceStatePayload,
    ExperienceStateChange,
    CompositeUpdateExperiencePayload,
    AnnouncementDraft,
    clientMetricsPayload,
    EntityType,
    BundleMap,
    ToastViewData,
} from "../../../models";
import _ from "lodash";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";

const testIDPrefix = "AnnouncementDetailsScreen";
const metricPrefix = "announcementDetailsScreen";
const pagePath = paths.announcementDetails;
const pitchFormSteps: FormNavigationStep[] = [
    {
        stringId:
            stringIds.ArtistStudio.Announcement.announcementDetailsAddAudioStep,
        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
        enabled: true,
    },
    {
        stringId:
            stringIds.ArtistStudio.Announcement
                .announcementDetailsAddBackgroundStep,
        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
        enabled: false,
    },
    {
        stringId:
            stringIds.ArtistStudio.Announcement.announcementDetailsReviewStep,
        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
        enabled: false,
    },
];

type ViewProps = {};

type StateProps = CatalogProps & {
    selectedArtistAsin: string;
    error?: ErrorPayload;
    teamId: string;
    selectedStep?: number;
    isPrivileged?: boolean;
    selectedAlbumRelease: albumRelease;
    experienceDrafts: Map<string, ExperienceDraft>;
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    userAction: (payload: telemetryPayload) => void;
    selectReportIssueItem: (payload: baseMediaItem) => void;
    selectStep: (payload: number) => void;
    selectTrack: (payload: string | undefined) => void;
    getTracksToAnnounce: (payload: listTracksToPitchPayload) => void;
    updateDraft: (payload: ExperienceDraft) => void;
    uploadAssetToCAS: (payload: CASUploadPayload) => void;
    createExperience: (payload: CreateExperiencePayload) => void;
    updateExperienceAssets: (payload: UpdateExperienceAssetsPayload) => void;
    updateExperienceMetadata: (
        payload: UpdateExperienceMetadataPayload
    ) => void;
    updateExperienceState: (payload: UpdateExperienceStatePayload) => void;
    compositeUpdateExperience: (
        payload: CompositeUpdateExperiencePayload
    ) => void;
    clearDraftFromDraftsMap: (id: string) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    showSaveDraftToast: (ToastViewData: ToastViewData) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    stepIndex: number | undefined;
    jumpToStep?: number;
    selectedAlbum?: album;
    selectedArtist?: artist;
    showError: boolean;
    showSuccessModal: boolean;
    showFailureModal: boolean;
    showRequiredFieldError: boolean;
};

type RedirectState = {
    stepIndex: number | undefined;
};

enum AnnouncementFlowStep {
    ADD_AUDIO = 0,
    ADD_BACKGROUND = 1,
    REVIEW = 2,
}

class AnnouncementDetailPage extends React.Component<Props, State> {
    private savePress: "SUBMIT" | "SAVE_DRAFT" | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            stepIndex:
                (this.props.location.state as RedirectState)?.stepIndex || 0,
            showError: false,
            showSuccessModal: false,
            showFailureModal: false,
            showRequiredFieldError: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        if (!this.props.selectedArtistAsin) {
            this.props.history.push(paths.artistSelect);
            return;
        } else {
            const artist = this.props.catalog.get(
                this.props.selectedArtistAsin
            ) as artist;
            if (artist) {
                this.setState({ selectedArtist: artist });
            }
        }

        const experienceId = this.getExperienceId();

        if (!experienceId) {
            this.props.history.push(paths.newReleases);
            return;
        }

        const experienceDraft = this.props.experienceDrafts.get(experienceId);

        if (
            !experienceDraft ||
            experienceDraft.experience.experienceType !==
                ExperienceType.ANNOUNCEMENT
        ) {
            this.props.history.push(paths.newReleases);
            return;
        }

        // if not a selected Album --> get required entities from the experienceDraft
        let albumCatalogItem;
        if (!this.props.selectedAlbumRelease) {
            if (!experienceDraft) {
                this.props.history.push(paths.newReleases);
                return;
            }

            const associatedEntities =
                experienceDraft.experience.associatedEntities;

            if (associatedEntities.length !== 1) {
                this.props.history.push(paths.newReleases);
                return;
            }

            const associatedEntity = associatedEntities[0];

            if (associatedEntity.entityType === ExperienceEntityType.ALBUM) {
                albumCatalogItem = this.props.catalog.get(
                    associatedEntity.identifier
                ) as album;
                if (albumCatalogItem) {
                    this.setState({ selectedAlbum: albumCatalogItem });
                }
            } else if (
                associatedEntity.entityType === ExperienceEntityType.TRACK
            ) {
                const trackCatalogItem = this.props.catalog.get(
                    associatedEntity.identifier
                ) as track;
                if (trackCatalogItem) {
                    albumCatalogItem = this.props.catalog.get(
                        trackCatalogItem.albumAsin
                    ) as album;
                    if (albumCatalogItem) {
                        this.setState({ selectedAlbum: albumCatalogItem });
                    }
                }
            } else {
                this.props.history.push(paths.newReleases);
                return;
            }
        } else {
            albumCatalogItem = this.props.catalog.get(
                this.props.selectedAlbumRelease.titlesetAsin
            ) as album;

            if (albumCatalogItem) {
                this.setState({ selectedAlbum: albumCatalogItem });
            }
        }

        if (!albumCatalogItem) {
            this.props.history.push(paths.newReleases);
            return;
        }

        this.props.selectStep(0);

        this.props.userAction({
            name: `${metricPrefix}View`,
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtistAsin],
                [METRIC_KEYS.page, pagePath],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const experienceId = this.getExperienceId();

        if (!experienceId) {
            this.props.history.push(paths.newReleases);
            return;
        }

        const experienceDraft = this.props.experienceDrafts.get(experienceId);
        const prevExperienceDraft =
            prevProps.experienceDrafts.get(experienceId);

        if (this.props.error !== prevProps.error) {
            this.setState({
                showError: this.props.error !== undefined,
            });
        }
        if (
            this.state.jumpToStep !== undefined &&
            this.state.jumpToStep !== prevState.jumpToStep
        ) {
            this.props.selectStep(this.state.jumpToStep);
        }

        if (this.state.stepIndex !== prevState.stepIndex) {
            window.scrollTo(0, 0);
        }

        // Upload to CAS has completed
        if (
            experienceDraft &&
            prevExperienceDraft &&
            !experienceDraft.uploadToCASInProgress &&
            prevExperienceDraft.uploadToCASInProgress
        ) {
            if (experienceDraft.uploadToCASSuccess) {
                this.setState({
                    jumpToStep:
                        (this.state.stepIndex ? this.state.stepIndex : 0) + 1,
                });
                this.props.updateDraft({
                    ...experienceDraft,
                    audioWasEdited: false,
                    backgroundImageWasEdited: false,
                    backgroundImageNeedsUpload: false,
                });
            } else {
                this.setState({
                    showError: true,
                });
            }
        }

        // create has completed
        if (
            experienceDraft &&
            prevExperienceDraft &&
            !experienceDraft.createInProgress &&
            prevExperienceDraft.createInProgress
        ) {
            const success = !!experienceDraft.createSuccess;
            if (this.savePress === "SUBMIT") {
                this.submitCompleted(success);
            } else {
                this.saveDraftCompleted(success);
            }
        }
        // update has completed
        if (
            experienceDraft &&
            prevExperienceDraft &&
            !experienceDraft.updateAssetsInProgress &&
            !experienceDraft.updateMetadataInProgress &&
            !experienceDraft.updateStateInProgress &&
            !experienceDraft.compositeUpdateInProgress &&
            (prevExperienceDraft.updateAssetsInProgress ||
                prevExperienceDraft.updateMetadataInProgress ||
                prevExperienceDraft.updateStateInProgress ||
                prevExperienceDraft.compositeUpdateInProgress)
        ) {
            const success =
                (experienceDraft.updateAssetsSuccess === undefined ||
                    experienceDraft.updateAssetsSuccess) &&
                (experienceDraft.updateMetadataSuccess === undefined ||
                    experienceDraft.updateMetadataSuccess) &&
                (experienceDraft.updateStateSuccess === undefined ||
                    experienceDraft.updateStateSuccess) &&
                (experienceDraft.compositeUpdateSuccess === undefined ||
                    experienceDraft.compositeUpdateSuccess);
            if (this.savePress === "SUBMIT") {
                this.submitCompleted(success);
            } else {
                this.saveDraftCompleted(success);
            }
        }
    }

    render() {
        const experienceId = this.getExperienceId();

        if (!experienceId) {
            this.props.history.push(paths.newReleases);
            return null;
        }

        const experienceDraft = this.props.experienceDrafts.get(experienceId);

        if (!experienceDraft) {
            this.props.history.push(paths.newReleases);
            return null;
        }

        this.updateStepFormEnable();
        if (experienceDraft.getInProgress) return <Loading />;
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    paddingTop: 0,
                    display: "flex",
                    gap: rootStyles.spacers.medium,
                }}
            >
                {this.state.showError && (
                    <Row>
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.genericError,
                            })}
                            id={`${testIDPrefix}_Error`}
                        />
                    </Row>
                )}

                {this.announcementForm()}

                <FullScreenConfirmationModal
                    id={`${testIDPrefix}-SuccessModal`}
                    icon={IconsList.action_doneInline}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .announcementSuccessModalHeadine,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .announcementSuccessModalPrimaryBody,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.GENERIC_STRINGS,
                            stringId: stringIds.Generic.done,
                        }
                    )}
                    confirmButtonAction={this.onSuccess}
                    onDismiss={this.onSuccess}
                    isVisible={this.state.showSuccessModal}
                    buttonOrientation={"stacked"}
                    footer={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .announcementSuccessModalHelperText,
                    })}
                />

                <FullScreenConfirmationModal
                    id={`${testIDPrefix}-FailureModal`}
                    icon={IconsList.ic_important}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .announcementFailureModalHeadline,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                        stringId:
                            stringIds.ArtistStudio.Announcement
                                .announcementFailureModalBody,
                    })}
                    cancelButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.GENERIC_STRINGS,
                        stringId: stringIds.Generic.goBack,
                    })}
                    cancelButtonAction={() =>
                        this.setState({
                            showFailureModal: false,
                            showError: false,
                        })
                    }
                    onDismiss={() =>
                        this.setState({
                            showFailureModal: false,
                            showError: false,
                        })
                    }
                    isVisible={this.state.showFailureModal}
                    buttonOrientation={"inline"}
                />
            </Container>
        );
    }

    private announcementForm() {
        const id = this.getExperienceId();
        let experienceDraft;

        if (id) {
            experienceDraft = this.props.experienceDrafts.get(id);
        }
        let nextDisabled: boolean;
        switch (this.state.stepIndex) {
            case AnnouncementFlowStep.ADD_AUDIO: // Announcement details page
                if (!experienceDraft) {
                    return undefined;
                }
                nextDisabled = !experienceDraft.audioURL;

                return (
                    <>
                        {this.AnnounceAnEntityHeader()}
                        {this.SelectedEntityHeader()}
                        {this.FormNavigation()}
                        <AnnouncementAddAudio
                            setExperienceDraft={this.props.updateDraft}
                            experienceDraft={experienceDraft}
                            userAction={this.props.userAction}
                            pagePath={pagePath}
                            bundleMap={this.props.bundleMap}
                        />
                        {this.footer(
                            this.footerBackButton,
                            this.footerAudioNextButton,
                            getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formNext,
                            }),
                            nextDisabled,
                            experienceDraft.uploadToCASInProgress
                        )}
                    </>
                );
            case AnnouncementFlowStep.ADD_BACKGROUND:
                if (!experienceDraft) {
                    return undefined;
                }

                nextDisabled = !(
                    experienceDraft.backgroundImageURL &&
                    experienceDraft.headline &&
                    experienceDraft.audioURL
                );

                return (
                    <>
                        {this.AnnounceAnEntityHeader()}
                        {this.SelectedEntityHeader()}
                        {this.FormNavigation()}
                        <AnnouncementAddBackground
                            album={this.state.selectedAlbum}
                            artist={this.state.selectedArtist}
                            setExperienceDraft={this.props.updateDraft}
                            experienceDraft={experienceDraft}
                            catalog={this.props.catalog}
                            bundleMap={this.props.bundleMap}
                        />
                        {this.footer(
                            this.footerBackButton,
                            this.footerBackgroundNextButton,
                            getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formNext,
                            }),
                            nextDisabled,
                            experienceDraft.uploadToCASInProgress
                        )}
                    </>
                );
            case AnnouncementFlowStep.REVIEW:
                if (!experienceDraft) {
                    return undefined;
                }
                nextDisabled =
                    this.props.isPrivileged ||
                    !(
                        experienceDraft.backgroundImageURL &&
                        experienceDraft.headline &&
                        experienceDraft.audioURL &&
                        experienceDraft.title &&
                        experienceDraft.eligibility.schedule &&
                        experienceDraft.eligibility.schedule.startDate &&
                        experienceDraft.eligibility.schedule.expirationDate
                    );
                const loading =
                    experienceDraft.createInProgress ||
                    experienceDraft.updateAssetsInProgress ||
                    experienceDraft.updateMetadataInProgress ||
                    experienceDraft.updateStateInProgress ||
                    experienceDraft.compositeUpdateInProgress;
                return (
                    <>
                        {this.AnnounceAnEntityHeader()}
                        {this.FormNavigation()}
                        <AnnouncementReview
                            catalog={this.props.catalog}
                            onEdit={this.onEdit}
                            setExperienceDraft={this.props.updateDraft}
                            experienceDraft={experienceDraft}
                            showRequiredFieldError={
                                this.state.showRequiredFieldError
                            }
                            handleClickedOffInputBox={() => {
                                this.setState({ showRequiredFieldError: true });
                            }}
                            bundleMap={this.props.bundleMap}
                        />
                        {this.footer(
                            this.saveDraftAnnouncement,
                            this.submitAnnouncement,
                            getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formSubmit,
                            }),
                            nextDisabled,
                            loading && this.savePress === "SUBMIT",
                            getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                                stringId:
                                    stringIds.ArtistStudio.Announcement
                                        .announcementReviewSaveDraftCTA,
                            }),
                            nextDisabled,
                            loading && this.savePress === "SAVE_DRAFT",
                            () => {
                                this.setState({ showRequiredFieldError: true });
                            }
                        )}
                    </>
                );
            default:
                return undefined;
        }
    }

    private AnnounceAnEntityHeader = () => {
        return (
            <CommonHeader
                title={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                    stringId:
                        stringIds.ArtistStudio.Announcement
                            .announcementDetailsPageTitle,
                })}
                subtitle={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.NEWRELEASES_STRINGS,
                    stringId: stringIds.NewReleases.title,
                })}
                id={`${testIDPrefix}_Header`}
                hideDatePicker={true}
            />
        );
    };

    private SelectedEntityHeader = () => {
        const entity = this.state.selectedAlbum;

        if (entity) {
            const artistNames = concatenateArtistNames(
                entity.artistAsins,
                this.props.catalog,
                true
            );

            const TableRowProps = generateNewReleaseRow(
                entity,
                undefined,
                undefined,
                artistNames,
                undefined,
                `${testIDPrefix}-SelectedSongHeader`,
                false
            );
            return <TableRow {...TableRowProps} />;
        }

        return undefined;
    };

    private FormNavigation = () => {
        return (
            <Row>
                <Col>
                    <FormNavigation
                        stepList={pitchFormSteps}
                        onChange={(index) => {
                            const experienceId = this.getExperienceId();
                            if (
                                !experienceId ||
                                !this.props.experienceDrafts.get(experienceId)
                            ) {
                                this.props.history.push(paths.newReleases);
                                return;
                            } else {
                                this.setState({
                                    stepIndex: index,
                                    jumpToStep: index,
                                });
                            }
                        }}
                        defaultValue={this.state.stepIndex}
                        id={`${testIDPrefix}_Navigation`}
                        jumpToStep={this.state.jumpToStep}
                    />
                </Col>
            </Row>
        );
    };

    private footer = (
        leftButtonClick: () => void,
        rightButtonClick: () => void,
        rightButtonText: string,
        rightButtonDisabled: boolean,
        rightButtonLoading?: boolean,
        leftButtonText?: string,
        leftButtonDisabled?: boolean,
        leftButtonLoading?: boolean,
        onDisabledButtonClick?: () => void
    ) => {
        return (
            <Footer
                show={true}
                rightButtonDisabled={rightButtonDisabled}
                leftButtonDisabled={leftButtonDisabled}
                leftButtonText={
                    leftButtonText ||
                    getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formBack,
                    })
                }
                rightButtonText={rightButtonText}
                onLeftButtonClick={leftButtonClick}
                onRightButtonClick={rightButtonClick}
                rightButtonLoading={rightButtonLoading}
                leftButtonLoading={leftButtonLoading}
                onDisabledButtonClick={onDisabledButtonClick}
                id={`${testIDPrefix}-NavigationFooter`}
            />
        );
    };

    private footerBackButton = () => {
        const experienceId = this.getExperienceId();

        if (!experienceId || !this.props.experienceDrafts.get(experienceId)) {
            this.props.history.push(paths.newReleases);
            return;
        }

        this.setState({
            showError: false,
        });

        if (this.state.stepIndex && this.state.stepIndex > 0) {
            this.setState({ jumpToStep: this.state.stepIndex - 1 });
        } else {
            this.props.history.push(paths.newReleases);
        }
    };

    private footerBackgroundNextButton = () => {
        const experienceId = this.getExperienceId();

        if (!experienceId) {
            this.props.history.push(paths.newReleases);
            return;
        }

        this.setState({
            showError: false,
        });

        let experienceDraft = this.props.experienceDrafts.get(experienceId);

        if (!experienceDraft) {
            this.props.history.push(paths.newReleases);
            return;
        }

        let assets: ExperienceAsset[] = experienceDraft.assets;

        if (
            !experienceDraft.backgroundImageNeedsUpload &&
            (!experienceDraft.isEdit ||
                (experienceDraft.isEdit &&
                    experienceDraft.backgroundImageWasEdited))
        ) {
            const newBackgroundAsset: ExperienceAsset = {
                key: ExperienceAssetKey.BACKGROUND,
                type: ExperienceAssetType.IMAGE,
                sourceType: ExperienceAssetSource.ALBUM,
                asset: this.state.selectedAlbum?.globalAsin || "",
            };

            assets = ExperienceUtil.putExperienceAssetIntoExperienceAssetList(
                assets,
                newBackgroundAsset
            );
        }

        if (experienceDraft.headlineWasEdited) {
            const newHeadlineAsset: ExperienceAsset = {
                key: ExperienceAssetKey.TITLE,
                type: ExperienceAssetType.TEXT,
                sourceType: ExperienceAssetSource.RAW,
                asset: experienceDraft.headline,
            };

            assets = ExperienceUtil.putExperienceAssetIntoExperienceAssetList(
                assets,
                newHeadlineAsset
            );
        }

        if (
            this.state.stepIndex !== undefined &&
            this.state.stepIndex < pitchFormSteps.length - 1 &&
            !experienceDraft.backgroundImageNeedsUpload
        ) {
            this.setState({ jumpToStep: this.state.stepIndex + 1 });
        }

        this.props.updateDraft({
            ...experienceDraft,
            assets: assets,
        });

        // execute this last to prevent any race conditions with draft being updated
        if (experienceDraft.backgroundImageNeedsUpload) {
            this.uploadNewBackgroundImageToCAS(experienceDraft);
        }
    };

    private footerAudioNextButton = () => {
        const experienceId = this.getExperienceId();

        if (!experienceId) {
            this.props.history.push(paths.newReleases);
            return;
        }

        this.setState({
            showError: false,
        });

        const experience = this.props.experienceDrafts.get(experienceId);

        if (!experience) {
            this.props.history.push(paths.newReleases);
            return;
        }

        if (experience.audioWasEdited) {
            this.uploadNewAudioMessageToCAS(experience);
        } else if (
            this.state.stepIndex !== undefined &&
            this.state.stepIndex < pitchFormSteps.length - 1
        ) {
            this.setState({ jumpToStep: this.state.stepIndex + 1 });
        }
    };

    private uploadNewAudioMessageToCAS = (experienceDraft: ExperienceDraft) => {
        if (!experienceDraft.audioBlob) {
            return;
        }

        const fileExtension = ExperienceUtil.getExtensionFromFileName(
            experienceDraft.audioName
        );

        const payload: CASUploadPayload = {
            assetContext:
                ExperienceAssetContext.AM4A_EXPERIENCES_ANNOUNCEMENTS_AUDIO,
            mimeType: ExperienceUtil.transformToAcceptedMimeType(
                experienceDraft.audioBlob.type
            ),
            fileExtension: fileExtension,
            teamId: this.props.teamId,
            file: experienceDraft.audioBlob,
            artistAsin: this.props.selectedArtistAsin,
            experienceId: experienceDraft.experience.experienceId,
            requestPath: pagePath,
            assetKey: ExperienceAssetKey.MESSAGE,
            assetType: ExperienceAssetType.AUDIO,
        };

        this.props.uploadAssetToCAS(payload);
    };

    private uploadNewBackgroundImageToCAS = (
        experienceDraft: ExperienceDraft
    ) => {
        if (!experienceDraft.backgroundImageBlob) {
            return;
        }

        const payload: CASUploadPayload = {
            assetContext: ExperienceAssetContext.AM4A_EXPERIENCES_IMAGE,
            mimeType: ExperienceUtil.transformToAcceptedMimeType(
                experienceDraft.backgroundImageBlob.type
            ),
            fileExtension: ExperienceUtil.getExtensionFromMimeType(
                experienceDraft.backgroundImageBlob.type
            ),
            teamId: this.props.teamId,
            file: experienceDraft.backgroundImageBlob,
            artistAsin: this.props.selectedArtistAsin,
            experienceId: experienceDraft.experience.experienceId,
            requestPath: pagePath,
            assetKey: ExperienceAssetKey.BACKGROUND,
            assetType: ExperienceAssetType.IMAGE,
        };

        this.props.uploadAssetToCAS(payload);
    };

    private updateStepFormEnable = () => {
        const experienceId = this.getExperienceId();
        const experienceDraft = this.props.experienceDrafts.get(
            experienceId || ""
        );

        switch (this.state.stepIndex) {
            case AnnouncementFlowStep.ADD_AUDIO:
                if (!experienceId || !experienceDraft) {
                    this.props.history.push(paths.newReleases);
                    return;
                }
                if (
                    !experienceDraft.audioWasEdited &&
                    experienceDraft.backgroundImageURL &&
                    experienceDraft.headline &&
                    experienceDraft.audioURL
                ) {
                    pitchFormSteps[
                        AnnouncementFlowStep.ADD_BACKGROUND
                    ].enabled = true;
                    pitchFormSteps[AnnouncementFlowStep.REVIEW].enabled = true;
                } else if (
                    !experienceDraft.audioWasEdited &&
                    experienceDraft.audioURL
                ) {
                    pitchFormSteps[
                        AnnouncementFlowStep.ADD_BACKGROUND
                    ].enabled = true;
                    pitchFormSteps[AnnouncementFlowStep.REVIEW].enabled = false;
                } else {
                    pitchFormSteps[
                        AnnouncementFlowStep.ADD_BACKGROUND
                    ].enabled = false;
                    pitchFormSteps[AnnouncementFlowStep.REVIEW].enabled = false;
                }
                break;
            case AnnouncementFlowStep.ADD_BACKGROUND:
                if (!experienceId || !experienceDraft) {
                    this.props.history.push(paths.newReleases);
                    return;
                }
                if (
                    !experienceDraft.backgroundImageWasEdited &&
                    !experienceDraft.headlineWasEdited &&
                    experienceDraft.backgroundImageURL &&
                    experienceDraft.headline &&
                    experienceDraft.audioURL
                ) {
                    pitchFormSteps[AnnouncementFlowStep.REVIEW].enabled = true;
                } else {
                    pitchFormSteps[AnnouncementFlowStep.REVIEW].enabled = false;
                }
                break;
            case AnnouncementFlowStep.REVIEW:
                if (!experienceId || !experienceDraft) {
                    this.props.history.push(paths.newReleases);
                    return;
                }
                break;
            default:
                break;
        }
    };

    private submitAnnouncement = () => {
        const experienceId = this.getExperienceId();

        if (!experienceId) {
            this.props.history.push(paths.newReleases);
            return;
        }

        this.setState({
            showError: false,
        });

        const experienceDraft: AnnouncementDraft | undefined =
            this.props.experienceDrafts.get(experienceId) as AnnouncementDraft;

        if (!experienceDraft) {
            this.props.history.push(paths.newReleases);
            return;
        }

        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Announcement.submitAnnouncement,
                pageIds.artistStudioAnnouncementDetails,
                experienceId,
                EntityType.Interlude
            )
        );

        this.savePress = "SUBMIT";

        if (
            experienceDraft.isEdit &&
            experienceDraft.experience.experienceState === ExperienceState.DRAFT
        ) {
            let updateExperienceAssetsPayload:
                | UpdateExperienceAssetsPayload
                | undefined = undefined;
            let updateExperienceMetadataPayload:
                | UpdateExperienceMetadataPayload
                | undefined = undefined;

            if (experienceDraft.assets.length > 0) {
                updateExperienceAssetsPayload = {
                    teamId: this.props.teamId,
                    experienceId: experienceId,
                    set: experienceDraft.assets,
                    remove: [],
                    requestPath: pagePath,
                };
            }

            if (experienceDraft.isMetadataEdited) {
                updateExperienceMetadataPayload = {
                    teamId: this.props.teamId,
                    experienceId: experienceId,
                    title: experienceDraft.title,
                    eligibility: experienceDraft.eligibility,
                    requestPath: pagePath,
                };
            }

            const updateExperienceStatePayload: UpdateExperienceStatePayload = {
                teamId: this.props.teamId,
                experienceId: experienceId,
                state: ExperienceStateChange.REVIEW,
                requestPath: pagePath,
            };

            const compositeUpdateExperiencePayload: CompositeUpdateExperiencePayload =
                {
                    experienceId: experienceId,
                    requestPath: pagePath,
                    updateExperienceAssetsPayload:
                        updateExperienceAssetsPayload,
                    updateExperienceMetadataPayload:
                        updateExperienceMetadataPayload,
                    updateExperienceStatePayload: updateExperienceStatePayload,
                };

            this.props.compositeUpdateExperience(
                compositeUpdateExperiencePayload
            );
        } else {
            // if no new asset was added, check if an original asset exists and add it instead
            // no originals will exist if this is a new experience
            const assets: ExperienceAsset[] =
                ExperienceUtil.putExistingExperienceAssetIfNewAssetDoesNotExist(
                    experienceDraft.assets,
                    experienceDraft.experience.assets,
                    experienceDraft.experience.experienceId
                );

            const experienceDraftWithAssets: ExperienceDraft = {
                ...experienceDraft,
                assets: assets,
            };

            const createExperiencePayload: CreateExperiencePayload = {
                experienceDraft: experienceDraftWithAssets,
                creationMode: ExperienceCreationMode.REVIEW,
                teamId: this.props.teamId,
                requestPath: pagePath,
            };

            this.props.createExperience(createExperiencePayload);
        }
    };

    private saveDraftAnnouncement = () => {
        const experienceId = this.getExperienceId();

        if (!experienceId) {
            this.props.history.push(paths.newReleases);
            return;
        }

        this.setState({
            showError: false,
        });

        const experienceDraft = this.props.experienceDrafts.get(experienceId);

        if (!experienceDraft) {
            this.props.history.push(paths.newReleases);
            return;
        }

        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Announcement.saveDraftAnnouncement,
                pageIds.artistStudioAnnouncementDetails,
                experienceId,
                EntityType.Interlude
            )
        );

        this.savePress = "SAVE_DRAFT";

        // If editting a draft, update it
        // Else create a new draft
        if (
            experienceDraft.isEdit &&
            experienceDraft.experience.experienceState === ExperienceState.DRAFT
        ) {
            let updateExperienceAssetsPayload:
                | UpdateExperienceAssetsPayload
                | undefined = undefined;
            let updateExperienceMetadataPayload:
                | UpdateExperienceMetadataPayload
                | undefined = undefined;

            if (experienceDraft.assets.length > 0) {
                updateExperienceAssetsPayload = {
                    teamId: this.props.teamId,
                    experienceId: experienceId,
                    set: experienceDraft.assets,
                    remove: [],
                    requestPath: pagePath,
                };
            }

            if (experienceDraft.isMetadataEdited) {
                updateExperienceMetadataPayload = {
                    teamId: this.props.teamId,
                    experienceId: experienceId,
                    title: experienceDraft.title,
                    eligibility: experienceDraft.eligibility,
                    requestPath: pagePath,
                };
            }

            // If nothing to update, show "Draft Saved"
            if (
                !updateExperienceAssetsPayload &&
                !updateExperienceMetadataPayload
            ) {
                this.showDraftSavedToast();
                this.props.history.push(paths.newReleases);
            }

            const compositeUpdateExperiencePayload: CompositeUpdateExperiencePayload =
                {
                    experienceId: experienceId,
                    requestPath: pagePath,
                    updateExperienceAssetsPayload:
                        updateExperienceAssetsPayload,
                    updateExperienceMetadataPayload:
                        updateExperienceMetadataPayload,
                };

            this.props.compositeUpdateExperience(
                compositeUpdateExperiencePayload
            );
        } else {
            // if no new asset was added, check if an original asset exists and add it instead
            // no originals will exist if this is a new experience
            const assets: ExperienceAsset[] =
                ExperienceUtil.putExistingExperienceAssetIfNewAssetDoesNotExist(
                    experienceDraft.assets,
                    experienceDraft.experience.assets,
                    experienceDraft.experience.experienceId
                );

            const experienceDraftWithAssets: ExperienceDraft = {
                ...experienceDraft,
                assets: assets,
            };

            const createExperiencePayload: CreateExperiencePayload = {
                experienceDraft: experienceDraftWithAssets,
                creationMode: ExperienceCreationMode.DRAFT,
                teamId: this.props.teamId,
                requestPath: pagePath,
            };

            this.props.createExperience(createExperiencePayload);
        }
    };

    private onSuccess = () => {
        const experienceId = this.getExperienceId();

        if (!experienceId) {
            this.props.history.push(paths.newReleases);
            return;
        }
        // clear draft AFTER navigation as this page relies that the draft exists
        this.props.history.push(paths.newReleases);
        this.props.clearDraftFromDraftsMap(experienceId);
    };

    private onEdit = (): void => {
        this.setState({
            jumpToStep: AnnouncementFlowStep.ADD_AUDIO,
        });
    };

    private getExperienceId = (): string | undefined => {
        const splitPath = window.location.pathname.split("/");
        return splitPath[3];
    };

    private submitCompleted = (success: boolean): void => {
        this.setState({
            showSuccessModal: success,
            showFailureModal: !success,
        });
    };

    private saveDraftCompleted = (success: boolean): void => {
        this.setState({
            showFailureModal: !success,
        });
        if (success) {
            this.showDraftSavedToast();
            this.props.history.push(paths.newReleases);
        }
    };

    private showDraftSavedToast = (): void => {
        this.props.showSaveDraftToast({
            toastText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTSTUDIO_STRINGS,
                stringId: stringIds.ArtistStudio.experienceToastDraftSaved,
            }),
            icon: IconsList.action_done,
            placement: "bottom",
            id: `${testIDPrefix}_DraftSaved`,
        });
    };
}

function mapStateToProps(state: RootState): StateProps {
    return experienceAnnouncementSelector(state, pagePath);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        selectReportIssueItem: (payload: baseMediaItem) =>
            dispatch(artistIssueActions.selectReportIssueItem(payload)),
        selectTrack: (payload: string) =>
            dispatch(pitchFormActions.selectTrack(payload)),
        selectStep: (payload: number) =>
            dispatch(pitchFormActions.selectStep(payload)),
        getTracksToAnnounce: (payload: listTracksToPitchPayload) =>
            dispatch(pitchActions.listTracksToPitch(payload)),
        clearDraftFromDraftsMap: (id: string) =>
            dispatch(artistStudioActions.clearDraftFromDraftsMap(id)),
        updateDraft: (payload: ExperienceDraft) =>
            dispatch(artistStudioActions.updateLocalDraft(payload)),
        uploadAssetToCAS: (payload: CASUploadPayload) =>
            dispatch(artistStudioActions.uploadAssetToCAS(payload)),
        createExperience: (payload: CreateExperiencePayload) =>
            dispatch(artistStudioActions.createExperience(payload)),
        updateExperienceAssets: (payload: UpdateExperienceAssetsPayload) =>
            dispatch(artistStudioActions.updateExperienceAssets(payload)),
        updateExperienceMetadata: (payload: UpdateExperienceMetadataPayload) =>
            dispatch(artistStudioActions.updateExperienceMetadata(payload)),
        updateExperienceState: (payload: UpdateExperienceStatePayload) =>
            dispatch(artistStudioActions.updateExperienceState(payload)),
        compositeUpdateExperience: (
            payload: CompositeUpdateExperiencePayload
        ) => dispatch(artistStudioActions.compositeUpdateExperience(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        showSaveDraftToast: (toastViewData: ToastViewData) =>
            dispatch(
                globalNotificationsActions.requestToastView(toastViewData)
            ),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AnnouncementDetailPage)
);
