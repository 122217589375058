import React, { Component } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { LocalizedUrls } from "@amzn/ziggy-asset";
import * as rootStyles from "../../styles";
import {
    MediumOutlineButton,
    MediumSolidButton,
    Icon,
    styledTitle,
    BlurredModal,
} from "../common";
import { getLocalizedString, getLocalizedUrl } from "../../../utils";
import { stringIds, IconsList, bundleIds } from "../../../assets";
import { BundleMap, channel } from "../../../models";

export const TwitchSuccessModalTestIDPrefix = "TwitchSuccessModalTestIdPrefix";
export type TwitchSuccessModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
    bundleMap: BundleMap;
    channel?: channel;
};

export class TwitchSuccessModal extends Component<TwitchSuccessModalProps> {
    render() {
        const spacer = <div style={spacerStyle} />;
        return (
            <BlurredModal
                id="blurred-modal"
                show={this.props.isVisible}
                centered
                top="40%"
                minwidth="256px"
            >
                <Modal.Body>
                    <Row
                        style={{
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}
                    >
                        <div
                            onClick={this.props.onDismiss}
                            style={dismissIconContainerStyle}
                        >
                            <Icon
                                icon={IconsList.action_cancel}
                                size={rootStyles.icons.medium}
                                id={`${TwitchSuccessModalTestIDPrefix}_DismissIcon`}
                            />
                        </div>
                        {spacer}
                    </Row>

                    <Row style={profileImageContainer}>
                        <Col>
                            <img
                                id={
                                    TwitchSuccessModalTestIDPrefix +
                                    "_ArtistImage"
                                }
                                alt="Profile"
                                style={profileImage}
                                src={this.props.channel?.profileImageUrl}
                            />
                        </Col>
                    </Row>
                    <Row style={centeredRowStyle}>
                        <Col>
                            <span style={infoText}>
                                {this.props.channel?.displayName
                                    ? getLocalizedString(this.props.bundleMap, {
                                          bundleId: bundleIds.TWITCH_STRINGS,
                                          stringId:
                                              stringIds.Twitch.channelLabel,
                                      })
                                    : null}
                            </span>
                        </Col>
                    </Row>
                    <Row style={centeredRowStyle}>
                        <Col>
                            <styledTitle.h3
                                id={
                                    TwitchSuccessModalTestIDPrefix +
                                    "_ArtistName"
                                }
                            >
                                {this.props.channel?.displayName}
                            </styledTitle.h3>
                        </Col>
                    </Row>
                    {spacer}
                    <Row style={centeredRowStyle}>
                        <Col>
                            <styledTitle.h4>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.TWITCH_STRINGS,
                                    stringId:
                                        stringIds.Twitch.modalSuccessTitle,
                                })}
                            </styledTitle.h4>
                        </Col>
                    </Row>
                    {spacer}
                    <Row style={centeredRowStyle}>
                        <Col>
                            <span style={rootStyles.textStyles.primary}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.TWITCH_STRINGS,
                                    stringId: stringIds.Twitch.modalSuccessText,
                                })}
                            </span>
                        </Col>
                    </Row>
                    {spacer}
                    <Row style={centeredRowStyle}>
                        <Col>
                            <MediumSolidButton
                                containerStyle={buttons}
                                id={
                                    TwitchSuccessModalTestIDPrefix +
                                    "_ContinueButton"
                                }
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.TWITCH_STRINGS,
                                        stringId:
                                            stringIds.Twitch
                                                .modalSuccessContinueButton,
                                    }
                                )}
                                onClick={this.onContinueButtonClick}
                            />
                        </Col>
                    </Row>
                    <Row>{spacer}</Row>
                    <Row style={centeredRowStyle}>
                        <Col>
                            <MediumOutlineButton
                                containerStyle={buttons}
                                id={
                                    TwitchSuccessModalTestIDPrefix +
                                    "_ResourcesButton"
                                }
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.TWITCH_STRINGS,
                                        stringId:
                                            stringIds.Twitch
                                                .modalSeeLivestreamResource,
                                    }
                                )}
                                onClick={() =>
                                    window.open(
                                        getLocalizedUrl(
                                            LocalizedUrls.TwitchLearnMoreUrl
                                        ),
                                        "_blank"
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </BlurredModal>
        );
    }

    private onContinueButtonClick = () => {
        this.props.onDismiss();
    };
}
const profileImageContainer: React.CSSProperties = {
    alignItems: "center",
    textAlign: "center",
};
const profileImage: React.CSSProperties = {
    ...rootStyles.imageStyles.roundImage,
    marginTop: 0,
    alignSelf: "center",
    justifyContent: "center",
};
const spacerStyle: React.CSSProperties = {
    flex: 1,
    minHeight: rootStyles.spacers.large,
};
const buttons: React.CSSProperties = {
    width: "100%",
    maxWidth: 300,
};
const dismissIconContainerStyle: React.CSSProperties = {
    height: 36,
    width: 36,
    paddingTop: 4,
    cursor: "pointer",
    textAlign: "center",
    borderRadius: 18,
    backgroundColor: rootStyles.glassColors.primary2,
};
const infoText: React.CSSProperties = {
    ...rootStyles.textStyles.label,
    marginTop: rootStyles.spacers.huge,
    alignSelf: "center",
};
const centeredRowStyle: React.CSSProperties = {
    textAlign: "center",
    alignItems: "center",
};
