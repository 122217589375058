import { getBundleMap } from "./commonSelectors";
import { createSelector } from "reselect";
import { selectedTeamIdSelector } from "./userSelectors";
import { RootState } from "../reducers";
import {
    albumRelease,
    BundleMap,
    ErrorPayload,
    Experience,
    ExperienceDraft,
    ExperiencesPage,
    Feature,
    TeamPermissions,
} from "../../models";
import { getUserPermissionsSelector } from "./teamManagementSelectors";
import _ from "lodash";

const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;

const getCatalog = (state: RootState) => state.catalog.catalog;
const getCatalogBuildCompleted = (state: RootState) =>
    state.catalog.catalogBuildCompleted;
const getHydrationInProgress = (state: RootState) =>
    state.catalog.hydrationInProgress;
const getHydratingAsins = (state: RootState) => state.catalog.hydratingAsins;
const getFailedAsins = (state: RootState) => state.catalog.failedAsins;

const getAlbumsToPitch = (state: RootState) => state.pitch.albumsToPitch;

const getListAlbumsToPitchInProgress = (state: RootState) =>
    state.pitch.listAlbumsToPitchInProgress;

const getUserLocale = (state: RootState) => state.user.locale;

const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);
const getIsPrivileged = (state: RootState) => state.user.isPrivileged;

const getExperienceDrafts = (state: RootState) =>
    state.artistStudio.experienceDrafts;

const getExperiences = (state: RootState) => state.artistStudio.experiences;
const listExperienceInProgress = (state: RootState) =>
    state.artistStudio.listExperiencesInProgress;
const getExperiencesForEntities = (state: RootState) =>
    state.artistStudio.experiencesToEntities;
const listExperiencesForEntitiesInProgress = (state: RootState) =>
    state.artistStudio.listExperiencesForEntitiesInProgress;
const quickUpdateExperienceStateInProgress = (state: RootState) =>
    state.artistStudio.quickUpdateExperienceStateInProgress;
const getHasArtistStudioAccessPermissions = (state: RootState) => {
    return (
        state.featureAccess.featurePermissionsList.length !== 0 &&
        !!_.find(state.featureAccess.featurePermissionsList, (permission) => {
            return permission.feature === Feature.ArtistStudio;
        })
    );
};
const getHasManageExperiencesPermissions = createSelector(
    [getUserPermissionsSelector],
    (userPermissions) => {
        return !!_.find(userPermissions, (permission) => {
            return permission === TeamPermissions.ManageExperiences;
        });
    }
);

const getHasAnnouncementPermissions = createSelector(
    [getHasArtistStudioAccessPermissions, getHasManageExperiencesPermissions],
    (hasStudioAccessPermissions, hasManageExperiencesPermissions) => {
        return hasStudioAccessPermissions && hasManageExperiencesPermissions;
    }
);

const catalogSelector = createSelector(
    [
        getCatalog,
        getCatalogBuildCompleted,
        getHydrationInProgress,
        getHydratingAsins,
        getFailedAsins,
    ],
    (
        catalog,
        catalogBuildCompleted,
        hydrationInProgress,
        hydratingAsins,
        failedAsins
    ) => {
        return {
            catalog,
            catalogBuildCompleted,
            hydrationInProgress,
            hydratingAsins,
            failedAsins,
        };
    }
);

const announcementSelector = createSelector(
    [
        getExperienceDrafts,
        getExperiences,
        listExperienceInProgress,
        getExperiencesForEntities,
        listExperiencesForEntitiesInProgress,
        quickUpdateExperienceStateInProgress,
        getHasAnnouncementPermissions,
    ],
    (
        experienceDrafts: Map<string, ExperienceDraft>,
        experiences: Experience[] | undefined,
        listExperienceInProgress: boolean | undefined,
        experiencesForEntities: Map<string, ExperiencesPage> | undefined,
        listExperiencesForEntitiesInProgress: boolean,
        quickUpdateExperienceStateInProgress: boolean,
        hasAnnouncementPermissions: boolean
    ) => {
        return {
            experienceDrafts,
            experiences,
            listExperienceInProgress,
            experiencesForEntities,
            listExperiencesForEntitiesInProgress,
            quickUpdateExperienceStateInProgress,
            hasAnnouncementPermissions,
        };
    }
);

export const newReleasesSelector = createSelector(
    [
        selectedTeamIdSelector,
        getSelectedArtistAsin,
        getAlbumsToPitch,
        getListAlbumsToPitchInProgress,
        getUserLocale,
        getErrorFromStore,
        getUserPermissionsSelector,
        catalogSelector,
        getIsPrivileged,
        announcementSelector,
        getBundleMap,
    ],
    (
        teamId: string | undefined,
        selectedArtistAsin: string | undefined,
        albumsToPitch: albumRelease[] | undefined,
        inProgress: boolean,
        userLocale: string,
        error: ErrorPayload | undefined,
        userPermissions: any,
        catalogSelector: any,
        isPrivileged: boolean | undefined,
        announcementSelector: any,
        bundleMap: BundleMap
    ) => {
        return {
            teamId,
            selectedArtistAsin,
            albumsToPitch,
            inProgress,
            userLocale,
            error,
            userPermissions,
            ...catalogSelector,
            isPrivileged,
            ...announcementSelector,
            bundleMap: bundleMap,
        };
    }
);
