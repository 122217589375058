import { Action } from "redux-ts";
import { takeEvery, put } from "redux-saga/effects";
import {
    errorActions,
    artistControlActions,
    telemetryActions,
    opsMetricsActions,
} from "../actions";
import * as service from "../../service";
import { manageBluePrintsResponse, METRIC_KEYS } from "../../models";
import { manageBluePrintsPayload } from "../../models/payloads";
import { createSuccessOpsMetricsPayload } from "../../utils";

export const artistControlSagas = [watchManageSkills()];

function* manageSkills(action: Action<manageBluePrintsPayload>) {
    const start = Date.now();

    const functionName = "manageSkills";
    try {
        yield put(
            telemetryActions.appEvent({
                name: "manageSkillsStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        const response: manageBluePrintsResponse = yield service.manageSkills({
            teamId: action.payload.teamId,
            artistAsin: action.payload.artistAsin,
        });

        yield put(artistControlActions.manageSkillsCompleted(response.url));

        yield put(
            telemetryActions.appEvent({
                name: "manageSkillsEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: new Map<string, string | undefined>([]),
            })
        );
    }
}

function* watchManageSkills() {
    yield takeEvery(artistControlActions.manageSkills.type, manageSkills);
}
