import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    IconTile,
    ImageTile,
    ImageWithGradient,
    LargeGlassButton,
} from "../common";
import * as rootStyles from "../../styles";
import { IconsList, ImageList, stringIds, bundleIds } from "../../../assets";
import { ExperienceUtil, getLocalizedString } from "../../../utils";
import { album, BundleMap, ExperienceDraft } from "../../../models";

type StateProps = {
    album?: album;
    onClickAlbumDefault?: () => void;
    readImageFileIn: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickEdit: () => void;
    experienceDraft: ExperienceDraft;
    showEditButton: boolean;
    bundleMap: BundleMap;
};

type State = {
    imageFileRef: React.RefObject<HTMLInputElement>;
};

const testIDPrefix = "ExperienceUploadImage";
const metricPrefix = "experienceUploadImage";

export class ExperienceUploadImage extends React.Component<StateProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            imageFileRef: React.createRef(),
        };
    }

    render() {
        return (
            <Row
                style={{
                    backgroundColor: rootStyles.glassColors.primary2,
                    borderRadius: 4,
                    alignItems: "flex-start",
                }}
            >
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Row
                        style={{
                            flex: 1,
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {this.props.showEditButton && (
                                <LargeGlassButton
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId: bundleIds.PITCH_STRINGS,
                                            stringId: stringIds.Pitch.formEdit,
                                        }
                                    )}
                                    leftIcon={IconsList.action_edit}
                                    containerStyle={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        position: "absolute",
                                        margin: rootStyles.spacers.giant,
                                        backgroundColor:
                                            rootStyles.glassColors.primary2,
                                        zIndex: 1000,
                                    }}
                                    buttonStyle={{
                                        gap: rootStyles.spacers.micro,
                                    }}
                                    id={`${testIDPrefix}-StartEndDateButton`}
                                    onClick={this.props.onClickEdit}
                                />
                            )}
                            <ImageWithGradient
                                source={
                                    this.props.experienceDraft
                                        .backgroundImageURL
                                }
                                fallback={ImageList.placeholder_album}
                                style={{
                                    maxWidth: Math.min(600, window.innerWidth), // Image will be 1:1 aspect ratio, so height will match.  need image to be size of browser window if it shrinks to under 600px
                                }}
                                id={`${testIDPrefix}_BackgroundImage`}
                            />
                        </div>
                    </Row>
                </Col>
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: rootStyles.spacers.large,
                        flexGrow: 0,
                        position: "absolute",
                        marginTop: rootStyles.spacers.epic,
                        marginLeft: rootStyles.spacers.medium,
                    }}
                >
                    {this.props.onClickAlbumDefault &&
                        this.props.album?.images?.artSmall && (
                            <ImageTile
                                image={this.props.album.images.artSmall}
                                size={rootStyles.icons.tiny * 3}
                                isCircle={false}
                                onClick={this.props.onClickAlbumDefault}
                            />
                        )}
                    <IconTile
                        icon={IconsList.ic_image}
                        iconSize={rootStyles.icons.tiny}
                        isCircle={true}
                        noGradient={true}
                        iconColor={rootStyles.colors.primary}
                        onClick={this.onUploadImage}
                        id={`${testIDPrefix}_UploadImageButton`}
                    />
                </Col>
                <input
                    accept={ExperienceUtil.getSupportedImageFileTypesCommaDelimited()}
                    ref={this.state.imageFileRef}
                    type="file"
                    style={{ display: "none" }}
                    value={""}
                    onChange={this.props.readImageFileIn}
                />
            </Row>
        );
    }

    private onUploadImage = (): void => {
        this.state.imageFileRef.current &&
            this.state.imageFileRef.current.click();
    };
}
