import React, { Component } from "react";
import { Col, Spinner, Row, Modal } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { getLocalizedString } from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import { BlurredModal } from "../common";
import styled from "styled-components";
import { BundleMap } from "../../../models";

const testIDPrefix = "TwitchInProgressModal";
type Props = {
    isVisible: boolean;
    bundleMap: BundleMap;
};

export class TwitchInProgressModal extends Component<Props> {
    render() {
        return (
            <BlurredModal
                id="blurred-modal"
                show={this.props.isVisible}
                centered
                minwidth="210px"
                top="50%"
            >
                <Row style={{ textAlign: "center" }}>
                    <Col>
                        <Spinner animation="border" variant="light" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span style={rootStyles.textStyles.primary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.TWITCH_STRINGS,
                                stringId:
                                    stringIds.Twitch.modalInProgressLinking,
                            })}
                        </span>
                    </Col>
                </Row>
            </BlurredModal>
        );
    }
}
