import { Action } from "redux-ts";
import {
    artistStudioActions,
    catalogActions,
    errorActions,
    opsMetricsActions,
    telemetryActions,
} from "../actions";
import {
    ArtistStudioCreateAssetResponse,
    ArtistStudioCreateExperienceResponse,
    ArtistStudioGetAssetStatusesResponse,
    ArtistStudioGetExperienceResponse,
    ArtistStudioListExperiencesForEntitiesResponse,
    ArtistStudioListExperiencesResponse,
    CASUploadPayload,
    CreateExperienceCompletedPayload,
    CreateExperienceInProgressPayload,
    CreateExperiencePayload,
    Experience,
    ExperienceAsset,
    ExperienceAssetContext,
    ExperienceAssetKey,
    ExperienceAssetSource,
    ExperienceAssetStatus,
    ExperienceAssetType,
    ExperienceDraft,
    ExperiencesPage,
    ExperienceType,
    GetExperienceInProgressPayload,
    GetExperiencePayload,
    ListExperiencePayload,
    ListExperiencesCompletedPayload,
    ListExperiencesForEntitiesPayload,
    METRIC_KEYS,
    UpdateExperienceAssetsPayload,
    UpdateExperienceCompletedPayload,
    UpdateExperienceInProgressPayload,
    UpdateExperienceMetadataPayload,
    CompositeUpdateExperiencePayload,
    UpdateExperienceStatePayload,
    hydrateCatalogPayload,
    QueryType,
    ExperienceEntityType,
} from "../../models";
import { put, takeEvery } from "redux-saga/effects";
import {
    createAsset,
    publishTimer,
    startTimer,
    uploadCASAsset,
} from "../../service";
import * as services from "../../service";
import { AxiosResponse } from "axios";
import _, { update } from "lodash";
import {
    AnnouncementUtil,
    createSuccessOpsMetricsPayload,
    ExperienceUtil,
} from "../../utils";

export const artistStudioSagas = [
    watchListExperiences(),
    watchCreateExperience(),
    watchGetExperience(),
    watchUpdateExperienceAssets(),
    watchUpdateExperienceMetadata(),
    watchUpdateExperienceState(),
    watchCASUpload(),
    watchListExperiencesForEntities(),
    watchQuickUpdateExperienceState(),
    watchCompositeUpdateExperience(),
];

function* listExperiences(action: Action<ListExperiencePayload>) {
    const start = Date.now();
    const functionName = "listExperiences";
    const timeMetric = startTimer("listExperiencesRequestDuration");
    try {
        yield put(artistStudioActions.listExperiencesInProgress(true));

        if (!action.payload.paginatonToken) {
            yield put(
                artistStudioActions.listExperiencesCompleted({
                    experiences: undefined,
                    paginationToken: "",
                })
            );
        }

        yield put(
            telemetryActions.appEvent({
                name: "listExperiencesStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        const result: ArtistStudioListExperiencesResponse =
            yield services.listExperiences({
                publishingArtistAsin: action.payload.artistAsin,
                experienceTypeFilter: action.payload.experienceTypeFilter,
                paginationToken: action.payload.paginatonToken || "",
                teamId: action.payload.teamId,
            });

        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "listExperiencesEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        const hydrationPayloads =
            ExperienceUtil.getHydrationPayloadsForAssociatedEntities(
                result.experiences
            );

        for (let hydrationPayload of hydrationPayloads) {
            yield put(catalogActions.hydrateAsins(hydrationPayload));
        }

        const ListExperiencesCompletedPayload: ListExperiencesCompletedPayload =
            {
                experiences: result.experiences,
                paginationToken: result.paginationToken,
            };

        yield put(
            artistStudioActions.listExperiencesCompleted(
                ListExperiencesCompletedPayload
            )
        );

        yield put(artistStudioActions.listExperiencesInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(artistStudioActions.listExperiencesInProgress(false));
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* listExperiencesForEntities(
    action: Action<ListExperiencesForEntitiesPayload>
) {
    const start = Date.now();
    const functionName = "listExperiencesForEntities";
    const timeMetric = startTimer("listExperiencesForEntitiesRequestDuration");
    try {
        yield put(
            artistStudioActions.listExperiencesForEntitiesInProgress(true)
        );

        yield put(
            telemetryActions.appEvent({
                name: "listExperiencesForEntitiesStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        const result: ArtistStudioListExperiencesForEntitiesResponse =
            yield services.listExperiencesForEntities({
                publishingArtistAsin: action.payload.publishingArtistAsin,
                experienceTypeFilter: action.payload.experienceTypeFilter,
                entities: action.payload.entities,
                teamId: action.payload.teamId,
            });

        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "listExperiencesForEntitiesEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        const experiencesForEntities: Map<string, ExperiencesPage> = new Map<
            string,
            ExperiencesPage
        >();

        _.forEach(action.payload.entities, (entity) => {
            const value = result.experiences[entity.identifier];
            if (value) {
                experiencesForEntities.set(entity.identifier, value);
            }
        });

        yield put(
            artistStudioActions.listExperiencesForEntitiesCompleted(
                experiencesForEntities
            )
        );
        yield put(
            artistStudioActions.listExperiencesForEntitiesInProgress(false)
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(
            artistStudioActions.listExperiencesForEntitiesInProgress(false)
        );
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* createExperience(action: Action<CreateExperiencePayload>) {
    const start = Date.now();
    const functionName = "createExperience";
    const timeMetric = startTimer("createExperienceRequestDuration");
    let createExperienceInProgressPayload: CreateExperienceInProgressPayload = {
        id: action.payload.experienceDraft.experience.experienceId,
        inProgress: true,
    };
    try {
        yield put(
            artistStudioActions.createExperienceInProgress(
                createExperienceInProgressPayload
            )
        );

        yield put(
            telemetryActions.appEvent({
                name: "createExperienceStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        const assetsThatNeedStatusChecked: ExperienceAsset[] = _.filter(
            action.payload.experienceDraft.assets,
            (asset) => {
                return asset.sourceType === ExperienceAssetSource.ASSET;
            }
        );

        const assetIds = _.map(assetsThatNeedStatusChecked, (asset) => {
            return asset.asset;
        });

        const assetStatusesResult: ArtistStudioGetAssetStatusesResponse =
            yield services.getAssetStatuses({
                assetIds: assetIds,
                teamId: action.payload.teamId,
            });

        let notAvailable: boolean = false;
        _.forEach(assetStatusesResult.statuses.values, (status) => {
            if (status !== ExperienceAssetStatus.AVAILABLE) {
                notAvailable = true;
                return;
            }
        });

        if (notAvailable) {
            throw new Error("Create Experience Failed: Assets not available");
        }

        const result: ArtistStudioCreateExperienceResponse =
            yield services.createExperience({
                publishingArtistAsin:
                    action.payload.experienceDraft.experience
                        .publishingArtistAsin,
                experienceType:
                    action.payload.experienceDraft.experience.experienceType,
                experienceTitle: action.payload.experienceDraft.title,
                creationState: action.payload.creationMode,
                eligibility: {
                    explicit:
                        action.payload.experienceDraft.eligibility.explicit,
                    schedule:
                        action.payload.experienceDraft.eligibility.schedule,
                },
                associatedEntities:
                    action.payload.experienceDraft.experience
                        .associatedEntities,
                assets: action.payload.experienceDraft.assets,
                teamId: action.payload.teamId,
            });

        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "createExperienceEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        const createExperienceCompletedPayload: CreateExperienceCompletedPayload =
            {
                id: action.payload.experienceDraft.experience.experienceId,
                success: !!result.experienceId,
            };

        createExperienceInProgressPayload = {
            ...createExperienceInProgressPayload,
            inProgress: false,
        };

        yield put(
            artistStudioActions.createExperienceCompleted(
                createExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.createExperienceInProgress(
                createExperienceInProgressPayload
            )
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
            })
        );
        const createExperienceCompletedPayload: CreateExperienceCompletedPayload =
            {
                id: action.payload.experienceDraft.experience.experienceId,
                success: false,
            };
        createExperienceInProgressPayload = {
            ...createExperienceInProgressPayload,
            inProgress: false,
        };
        yield put(
            artistStudioActions.createExperienceCompleted(
                createExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.createExperienceInProgress(
                createExperienceInProgressPayload
            )
        );
    }
}

function* getExperience(action: Action<GetExperiencePayload>) {
    const start = Date.now();
    const functionName = "getExperience";
    const timeMetric = startTimer("getExperienceRequestDuration");
    let inProgressPayload: GetExperienceInProgressPayload = {
        inProgress: true,
        id: action.payload.experienceId,
    };
    try {
        yield put(
            artistStudioActions.getExperienceInProgress(inProgressPayload)
        );

        yield put(
            telemetryActions.appEvent({
                name: "getExperienceStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        const result: ArtistStudioGetExperienceResponse =
            yield services.getExperience({
                experienceId: action.payload.experienceId,
                teamId: action.payload.teamId,
            });

        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "getExperienceEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        const experience: Experience = result.experience;

        let updatedDraft: ExperienceDraft;
        switch (experience.experienceType) {
            case ExperienceType.ANNOUNCEMENT:
                updatedDraft =
                    AnnouncementUtil.loadAnnouncementDraftFromExperience(
                        experience
                    );
                break;
            default:
                throw new Error("Experience Type Not Recognized");
        }

        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };
        yield put(artistStudioActions.getExperienceCompleted(updatedDraft));
        yield put(
            artistStudioActions.getExperienceInProgress(inProgressPayload)
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        const inProgressPayload: GetExperienceInProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };
        yield put(
            artistStudioActions.getExperienceInProgress(inProgressPayload)
        );
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* updateExperienceAssets(
    action: Action<UpdateExperienceAssetsPayload>
) {
    const start = Date.now();
    const functionName = "updateExperienceAssets";
    const timeMetric = startTimer("updateExperienceAssetsRequestDuration");
    let inProgressPayload: UpdateExperienceInProgressPayload = {
        inProgress: true,
        id: action.payload.experienceId,
    };
    try {
        yield put(
            artistStudioActions.updateExperienceAssetsInProgress(
                inProgressPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceAssetsCompleted({
                id: action.payload.experienceId,
                success: undefined,
            })
        );

        yield put(
            telemetryActions.appEvent({
                name: "updateExperienceStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        const updateExperiencePayload = action.payload;

        const assetsThatNeedStatusChecked: ExperienceAsset[] = _.filter(
            updateExperiencePayload.set,
            (asset) => {
                return asset.sourceType === ExperienceAssetSource.ASSET;
            }
        );

        const assetIds = _.map(assetsThatNeedStatusChecked, (asset) => {
            return asset.asset;
        });

        const assetStatusesResult: ArtistStudioGetAssetStatusesResponse =
            yield services.getAssetStatuses({
                assetIds: assetIds,
                teamId: action.payload.teamId,
            });

        let notAvailable: boolean = false;
        _.forEach(assetStatusesResult.statuses.values, (status) => {
            if (status !== ExperienceAssetStatus.AVAILABLE) {
                notAvailable = true;
                return;
            }
        });

        if (notAvailable) {
            throw new Error("Update Experience Failed: Assets not available");
        }

        const result: AxiosResponse<any> | undefined =
            yield services.updateExperienceAssets({
                teamId: updateExperiencePayload.teamId,
                experienceId: updateExperiencePayload.experienceId,
                set: updateExperiencePayload.set,
                remove: updateExperiencePayload.remove,
            });

        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "updateExperienceAssetsEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );
        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };

        const updateExperienceCompletedPayload: UpdateExperienceCompletedPayload =
            {
                id: action.payload.experienceId,
                success: result?.status === 200,
            };

        yield put(
            artistStudioActions.updateExperienceAssetsCompleted(
                updateExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceAssetsInProgress(
                inProgressPayload
            )
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };

        const updateExperienceCompletedPayload: UpdateExperienceCompletedPayload =
            {
                id: action.payload.experienceId,
                success: false,
            };

        yield put(
            artistStudioActions.updateExperienceAssetsCompleted(
                updateExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceAssetsInProgress(
                inProgressPayload
            )
        );
        yield put(
            telemetryActions.appEvent({
                name: "updateExperienceAssetsError",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );
    }
}

function* updateExperienceMetadata(
    action: Action<UpdateExperienceMetadataPayload>
) {
    const start = Date.now();
    const functionName = "updateExperienceMetadata";
    const timeMetric = startTimer("updateExperienceRequestDuration");
    let inProgressPayload: UpdateExperienceInProgressPayload = {
        inProgress: true,
        id: action.payload.experienceId,
    };
    try {
        yield put(
            artistStudioActions.updateExperienceMetadataInProgress(
                inProgressPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceMetadataCompleted({
                id: action.payload.experienceId,
                success: undefined,
            })
        );

        yield put(
            telemetryActions.appEvent({
                name: "updateExperienceMetadataStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        const updateExperiencePayload: UpdateExperienceMetadataPayload =
            action.payload;

        const result: AxiosResponse<any> | undefined =
            yield services.updateExperienceMetadata({
                teamId: updateExperiencePayload.teamId,
                experienceId: updateExperiencePayload.experienceId,
                title: updateExperiencePayload.title,
                eligibility: updateExperiencePayload.eligibility,
            });

        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "updateExperienceMetadataEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );
        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };

        const updateExperienceCompletedPayload: UpdateExperienceCompletedPayload =
            {
                id: action.payload.experienceId,
                success: result?.status === 200,
            };

        yield put(
            artistStudioActions.updateExperienceMetadataCompleted(
                updateExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceMetadataInProgress(
                inProgressPayload
            )
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };

        const updateExperienceCompletedPayload: UpdateExperienceCompletedPayload =
            {
                id: action.payload.experienceId,
                success: false,
            };

        yield put(
            artistStudioActions.updateExperienceMetadataCompleted(
                updateExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceMetadataInProgress(
                inProgressPayload
            )
        );
        yield put(
            telemetryActions.appEvent({
                name: functionName,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );
    }
}

function* updateExperienceState(action: Action<UpdateExperienceStatePayload>) {
    const start = Date.now();
    const functionName = "updateExperienceState";
    const timeMetric = startTimer("updateExperienceRequestDuration");
    let inProgressPayload: UpdateExperienceInProgressPayload = {
        inProgress: true,
        id: action.payload.experienceId,
    };
    try {
        yield put(
            artistStudioActions.updateExperienceStateInProgress(
                inProgressPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceStateCompleted({
                id: action.payload.experienceId,
                success: undefined,
            })
        );

        yield put(
            telemetryActions.appEvent({
                name: "updateExperienceStateStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        const updateExperiencePayload: UpdateExperienceStatePayload =
            action.payload;

        const result: AxiosResponse<any> | undefined =
            yield services.updateExperienceState({
                teamId: updateExperiencePayload.teamId,
                experienceId: updateExperiencePayload.experienceId,
                state: updateExperiencePayload.state,
            });
        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "updateExperienceStateEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );
        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };

        const updateExperienceCompletedPayload: UpdateExperienceCompletedPayload =
            {
                id: action.payload.experienceId,
                success: result?.status === 200,
            };

        yield put(
            artistStudioActions.updateExperienceStateCompleted(
                updateExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceStateInProgress(
                inProgressPayload
            )
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };

        const updateExperienceCompletedPayload: UpdateExperienceCompletedPayload =
            {
                id: action.payload.experienceId,
                success: false,
            };

        yield put(
            artistStudioActions.updateExperienceStateCompleted(
                updateExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.updateExperienceStateInProgress(
                inProgressPayload
            )
        );
        yield put(
            telemetryActions.appEvent({
                name: functionName,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );
    }
}

function* compositeUpdateExperience(
    action: Action<CompositeUpdateExperiencePayload>
) {
    const start = Date.now();
    const functionName = "compositeUpdateExperience";
    const timeMetric = startTimer("updateExperienceRequestDuration");
    let inProgressPayload: UpdateExperienceInProgressPayload = {
        inProgress: true,
        id: action.payload.experienceId,
    };
    try {
        yield put(
            artistStudioActions.compositeUpdateExperienceInProgress(
                inProgressPayload
            )
        );
        yield put(
            artistStudioActions.compositeUpdateExperienceCompleted({
                id: action.payload.experienceId,
                success: undefined,
            })
        );

        yield put(
            telemetryActions.appEvent({
                name: "compositeUpdateExperienceStateStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        let updateAssetsResult: AxiosResponse<any> | undefined = undefined;
        let updateMetadataResult: AxiosResponse<any> | undefined = undefined;
        let updateStateResult: AxiosResponse<any> | undefined = undefined;

        if (action.payload.updateExperienceMetadataPayload) {
            const updateExperienceMetadataPayload: UpdateExperienceMetadataPayload =
                action.payload.updateExperienceMetadataPayload;

            updateMetadataResult = yield services.updateExperienceMetadata({
                teamId: updateExperienceMetadataPayload.teamId,
                experienceId: updateExperienceMetadataPayload.experienceId,
                title: updateExperienceMetadataPayload.title,
                eligibility: {
                    explicit:
                        updateExperienceMetadataPayload.eligibility.explicit,
                    schedule:
                        updateExperienceMetadataPayload.eligibility.schedule,
                },
            });

            if (updateMetadataResult?.status !== 200) {
                throw new Error(
                    "Composite Update Experience Error: Failed to update metadata"
                );
            }
        }

        if (action.payload.updateExperienceAssetsPayload) {
            const updateExperienceAssetsPayload: UpdateExperienceAssetsPayload =
                action.payload.updateExperienceAssetsPayload;

            const assetsThatNeedStatusChecked: ExperienceAsset[] = _.filter(
                updateExperienceAssetsPayload.set,
                (asset) => {
                    return asset.sourceType === ExperienceAssetSource.ASSET;
                }
            );

            const assetIds = _.map(assetsThatNeedStatusChecked, (asset) => {
                return asset.asset;
            });

            const assetStatusesResult: ArtistStudioGetAssetStatusesResponse =
                yield services.getAssetStatuses({
                    assetIds: assetIds,
                    teamId: updateExperienceAssetsPayload.teamId,
                });

            let notAvailable: boolean = false;
            _.forEach(assetStatusesResult.statuses.values, (status) => {
                if (status !== ExperienceAssetStatus.AVAILABLE) {
                    notAvailable = true;
                    return;
                }
            });

            if (notAvailable) {
                throw new Error(
                    "Composite Update Experience Failed: Assets not available"
                );
            }

            updateAssetsResult = yield services.updateExperienceAssets({
                teamId: updateExperienceAssetsPayload.teamId,
                experienceId: updateExperienceAssetsPayload.experienceId,
                set: updateExperienceAssetsPayload.set,
                remove: updateExperienceAssetsPayload.remove,
            });

            if (updateAssetsResult?.status !== 200) {
                throw new Error(
                    "Composite Update Experience Error: Failed to update assets"
                );
            }
        }

        // THIS MUST HAPPEN LAST
        // If state gets changed to something immutable (such as IN_REVIEW)
        // All other update calls will fail as the Experience is now immutable
        if (action.payload.updateExperienceStatePayload) {
            const updateExperienceStatePayload: UpdateExperienceStatePayload =
                action.payload.updateExperienceStatePayload;

            updateStateResult = yield services.updateExperienceState({
                teamId: updateExperienceStatePayload.teamId,
                experienceId: updateExperienceStatePayload.experienceId,
                state: updateExperienceStatePayload.state,
            });

            if (updateStateResult?.status !== 200) {
                throw new Error(
                    "Composite Update Experience Error: Failed to update state"
                );
            }
        }

        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "CompositeUpdateExperienceEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );
        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };

        const updateExperienceCompletedPayload: UpdateExperienceCompletedPayload =
            {
                id: action.payload.experienceId,
                success: true,
            };

        yield put(
            artistStudioActions.compositeUpdateExperienceCompleted(
                updateExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.compositeUpdateExperienceInProgress(
                inProgressPayload
            )
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        inProgressPayload = {
            inProgress: false,
            id: action.payload.experienceId,
        };

        const updateExperienceCompletedPayload: UpdateExperienceCompletedPayload =
            {
                id: action.payload.experienceId,
                success: false,
            };

        yield put(
            artistStudioActions.compositeUpdateExperienceCompleted(
                updateExperienceCompletedPayload
            )
        );
        yield put(
            artistStudioActions.compositeUpdateExperienceInProgress(
                inProgressPayload
            )
        );
        yield put(
            telemetryActions.appEvent({
                name: functionName,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );
    }
}

function* quickUpdateExperienceState(
    action: Action<UpdateExperienceStatePayload>
) {
    const start = Date.now();
    const functionName = "quickUpdateExperienceState";
    const timeMetric = startTimer("quickUpdateExperienceRequestDuration");
    try {
        yield put(
            artistStudioActions.quickUpdateExperienceStateInProgress(true)
        );

        yield put(
            telemetryActions.appEvent({
                name: "quickUpdateExperienceStateStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );

        const updateExperiencePayload: UpdateExperienceStatePayload =
            action.payload;

        const result: AxiosResponse<any> | undefined =
            yield services.updateExperienceState({
                teamId: updateExperiencePayload.teamId,
                experienceId: updateExperiencePayload.experienceId,
                state: updateExperiencePayload.state,
            });
        publishTimer(timeMetric);

        yield put(
            telemetryActions.appEvent({
                name: "quickUpdateExperienceStateEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        if (result?.status !== 200) {
            throw new Error("Quick Update State Failure");
        }

        yield put(
            artistStudioActions.quickUpdateExperienceStateInProgress(false)
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(
            artistStudioActions.quickUpdateExperienceStateInProgress(false)
        );
        yield put(
            telemetryActions.appEvent({
                name: "updateExperienceStateFailure",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath.toString()],
                ]),
            })
        );
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* CASAssetUpload(action: Action<CASUploadPayload>) {
    const start = Date.now();
    const functionName = "CASAssetUpload";
    try {
        console.log("Uploading asset: " + action.payload.assetContext);
        yield put(
            artistStudioActions.uploadToCASInProgress({
                inProgress: true,
                id: action.payload.experienceId,
            })
        );
        yield put(
            artistStudioActions.uploadToCASSuccess({
                id: action.payload.experienceId,
                success: false,
            })
        );

        yield put(
            telemetryActions.appEvent({
                name: `artistStudioAssetUploadStart`,
                dataPoints: new Map<string, string | undefined>([]),
            })
        );

        // make the service call to get the uploadUrl
        const response: ArtistStudioCreateAssetResponse = yield createAsset({
            assetContext: action.payload.assetContext,
            mimeType: action.payload.mimeType,
            extension: action.payload.fileExtension,
            teamId: action.payload.teamId,
        });
        // when we have a result, dispatch the completed task
        const uploadResponse: AxiosResponse<any> = yield uploadCASAsset({
            file: action.payload.file,
            casUrl: response.uploadPutUrl,
        });

        console.log(
            "Got result for " +
                action.payload +
                " in " +
                `${Date.now() - start} ms`
        );

        if (uploadResponse.status === 202) {
            yield put(
                telemetryActions.appEvent({
                    name: `artistStudioAsyncUpload`,
                    dataPoints: new Map<string, string | undefined>([
                        [
                            METRIC_KEYS.artistAsin,
                            `${action.payload.artistAsin}`,
                        ],
                        [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    ]),
                })
            );
        }

        const newAsset: ExperienceAsset = {
            key: action.payload.assetKey,
            type: action.payload.assetType,
            sourceType: ExperienceAssetSource.ASSET,
            asset: response.assetId,
        };

        yield put(
            artistStudioActions.uploadToCASCompleted({
                asset: newAsset,
                id: action.payload.experienceId,
            })
        );
        yield put(
            artistStudioActions.uploadToCASSuccess({
                id: action.payload.experienceId,
                success: true,
            })
        );
        yield put(
            artistStudioActions.uploadToCASInProgress({
                inProgress: false,
                id: action.payload.experienceId,
            })
        );

        yield put(
            telemetryActions.appEvent({
                name: `artistStudioAssetUploadEnd`,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.artistAsin, `${action.payload.artistAsin}`],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.artistAsin, `${action.payload.artistAsin}`],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        yield put(
            telemetryActions.appEvent({
                name: functionName,
                dataPoints: dataPoints,
            })
        );
        yield put(
            artistStudioActions.uploadToCASSuccess({
                id: action.payload.experienceId,
                success: false,
            })
        );
        yield put(
            artistStudioActions.uploadToCASInProgress({
                inProgress: false,
                id: action.payload.experienceId,
            })
        );
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* watchCASUpload() {
    yield takeEvery(artistStudioActions.uploadAssetToCAS.type, CASAssetUpload);
}

function* watchListExperiences() {
    yield takeEvery(artistStudioActions.listExperiences.type, listExperiences);
}

function* watchListExperiencesForEntities() {
    yield takeEvery(
        artistStudioActions.listExperiencesForEntities.type,
        listExperiencesForEntities
    );
}

function* watchCreateExperience() {
    yield takeEvery(
        artistStudioActions.createExperience.type,
        createExperience
    );
}

function* watchGetExperience() {
    yield takeEvery(artistStudioActions.getExperience.type, getExperience);
}

function* watchUpdateExperienceAssets() {
    yield takeEvery(
        artistStudioActions.updateExperienceAssets.type,
        updateExperienceAssets
    );
}

function* watchUpdateExperienceMetadata() {
    yield takeEvery(
        artistStudioActions.updateExperienceMetadata.type,
        updateExperienceMetadata
    );
}

function* watchUpdateExperienceState() {
    yield takeEvery(
        artistStudioActions.updateExperienceState.type,
        updateExperienceState
    );
}

function* watchQuickUpdateExperienceState() {
    yield takeEvery(
        artistStudioActions.quickUpdateExperienceState.type,
        quickUpdateExperienceState
    );
}

function* watchCompositeUpdateExperience() {
    yield takeEvery(
        artistStudioActions.compositeUpdateExperience.type,
        compositeUpdateExperience
    );
}
