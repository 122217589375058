import { Action } from "redux-ts";
import { takeEvery, put } from "redux-saga/effects";
import {
    errorActions,
    twitchActions,
    telemetryActions,
    opsMetricsActions,
} from "../actions";
import * as service from "../../service";
import {
    METRIC_KEYS,
    unlinkTwitchChannelPayload,
    linkTwitchChannelPayload,
    hydrateTwitchChannelPayload,
    getTwitchChannelPayload,
    ErrorType,
    LinkTwitchChannelResponse,
    HydrateTwitchResponse,
    GetTwitchChannelResponse,
} from "../../models";
import { createSuccessOpsMetricsPayload } from "../../utils";

export const twitchSagas = [
    watchUnlinkTwitchChannel(),
    watchLinkTwitchChannel(),
    watchHydrateTwitchChannel(),
    watchGetLinkedTwitchChannel(),
];

function* unlinkTwitchChannel(action: Action<unlinkTwitchChannelPayload>) {
    const functionName = "unlinkTwitchChannel";
    try {
        yield put(twitchActions.unlinkTwitchChannelInProgress(true));
        yield put(twitchActions.unlinkTwitchChannelCompleted(false));

        yield put(
            telemetryActions.appEvent({
                name: "unlinkTwitchChannel",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield service.unlinkTwitchChannel({
            artistAsin: action.payload.artistAsin,
            teamId: action.payload.teamId,
        });

        yield put(twitchActions.setLinkedChannel(undefined));
        yield put(twitchActions.unlinkTwitchChannelCompleted(true));
        yield put(twitchActions.unlinkTwitchChannelInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(twitchActions.unlinkTwitchChannelInProgress(false));

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
                requestPath: action.payload.requestPath,
            })
        );
    }
}

function* linkTwitchChannel(action: Action<linkTwitchChannelPayload>) {
    const functionName = "linkTwitchChannel";
    try {
        yield put(twitchActions.linkTwitchChannelCompleted(false));
        yield put(twitchActions.linkTwitchChannelInProgress(true));
        yield put(
            telemetryActions.appEvent({
                name: "linkTwitchChannel",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        const response: LinkTwitchChannelResponse =
            yield service.linkTwitchChannel({
                artistAsin: action.payload.artistAsin,
                teamId: action.payload.teamId,
                authId: action.payload.authId,
            });

        if (response.channelId) {
            const hydrationResponse: HydrateTwitchResponse =
                yield service.hydrateTwitchChannel({
                    channelId: response.channelId,
                    teamId: action.payload.teamId,
                });

            const channel = hydrationResponse.data[response.channelId];
            yield put(twitchActions.setLinkedChannel(channel));
        }

        yield put(twitchActions.linkTwitchChannelInProgress(false));
        yield put(twitchActions.linkTwitchChannelCompleted(true));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);
        yield put(twitchActions.linkTwitchChannelInProgress(false));

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                type: ErrorType.modal,
                dataPoints: dataPoints,
                requestPath: action.payload.requestPath,
            })
        );
    }
}

function* hydrateTwitchChannel(action: Action<hydrateTwitchChannelPayload>) {
    const functionName = "hydrateTwitchChannel";
    const start = Date.now();
    try {
        yield put(twitchActions.hydrateTwitchChannelInProgress(true));

        yield put(
            telemetryActions.appEvent({
                name: "hydrateTwitchChannelStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        const channel: HydrateTwitchResponse =
            yield service.hydrateTwitchChannel({
                channelId: action.payload.channelId,
                teamId: action.payload.teamId,
            });

        yield put(
            twitchActions.setLinkedChannel(
                channel.data[action.payload.channelId]
            )
        );
        yield put(twitchActions.hydrateTwitchChannelInProgress(false));
        yield put(twitchActions.getTwitchChannelInProgress(false));

        yield put(
            telemetryActions.appEvent({
                name: "hydrateTwitchChannelEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(twitchActions.hydrateTwitchChannelInProgress(false));
        yield put(twitchActions.getTwitchChannelInProgress(false));

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getTwitchChannel(action: Action<getTwitchChannelPayload>) {
    const start = Date.now();
    const functionName = "getTwitchChannel";
    try {
        yield put(twitchActions.getTwitchChannelInProgress(true));

        yield put(
            telemetryActions.appEvent({
                name: "getTwitchChannelStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        const channel: GetTwitchChannelResponse =
            yield service.getTwitchChannel({
                artistAsin: action.payload.artistAsin,
                teamId: action.payload.teamId,
            });

        if (channel.channelId) {
            yield put(
                twitchActions.hydrateTwitchChannel({
                    channelId: channel.channelId,
                    requestPath: action.payload.requestPath,
                    teamId: action.payload.teamId,
                })
            );
        } else {
            yield put(twitchActions.getTwitchChannelInProgress(false));
        }

        yield put(
            telemetryActions.appEvent({
                name: "getTwitchChannelEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);
        yield put(twitchActions.getTwitchChannelInProgress(false));

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* watchUnlinkTwitchChannel() {
    yield takeEvery(
        twitchActions.unlinkTwitchChannel.type,
        unlinkTwitchChannel
    );
}

function* watchLinkTwitchChannel() {
    yield takeEvery(twitchActions.linkTwitchChannel.type, linkTwitchChannel);
}

function* watchHydrateTwitchChannel() {
    yield takeEvery(
        twitchActions.hydrateTwitchChannel.type,
        hydrateTwitchChannel
    );
}

function* watchGetLinkedTwitchChannel() {
    yield takeEvery(twitchActions.getTwitchChannel.type, getTwitchChannel);
}
