import { createStore, applyMiddleware, Store } from "redux";
import throttle from "lodash/throttle";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from "redux-saga";
import reducers, { RootState } from "./reducers/rootReducer";
import rootSaga from "./sagas/rootSaga";
import {
    initialArtistSearchState,
    initialReportingState,
    initialTeamState,
    initialUserState,
    ArtistSearchState,
    ReportingState,
    TeamState,
    UserState,
    CatalogState,
    initialCatalogState,
    OutageStatusState,
    initialOutageStatusState,
    ArtistControlState,
    initialArtistControlState,
    ArtistIssueState,
    FeatureAccessState,
    initialFeatureAccessState,
    initialPitchFormReducerState,
    PitchFormReducerState,
    PitchState,
    initialPitchState,
    initialArtistStudioState,
    ArtistStudioState,
    initialPromoCardReducerState,
    PromoCardState,
} from "./reducers";
import {
    artist,
    outageStatusEntry,
    TimeRange,
    teamManagementMember,
    teamInfo,
    settings,
    baseMediaItem,
    FeaturePermission,
    itemProps,
    albumRelease,
    track,
    group,
    cookieModalText,
} from "../models";
import { storeActions } from "./actions";

type persistentData = {
    artistControlBlueprintsUrl?: string;
    artistSearchSelectedArtist?: artist;
    artistSearchClaimSubmitted: boolean;
    featurePermissions: FeaturePermission[];
    catalog: any[];
    cookieConsentSetting?: string;
    cookieModalText?: cookieModalText;
    reportingSelectedRange: TimeRange;
    reportingSelectedAlbumAsin?: string;
    reportingSelectedStationAsin?: string;
    reportingSelectedPlaylistAsin?: string;
    reportingSelectedSongAsin?: string;
    reportingstartDate?: Date;
    reportingEndDate?: Date;
    teamTeamMembers: teamManagementMember[];
    teamInvitedMembers: teamManagementMember[];
    teamLocale: string;
    teamInvitePaginationToken: string;
    userSignedIn: boolean;
    userSelectedArtistAsin?: string;
    userManagedArtists?: string[];
    userTeams?: teamInfo[];
    userSelectedTeamId?: string;
    userIsPrivileged?: boolean;
    userRecentlyViewedArtists: artist[];
    userSettings: settings;
    userLocale: string;
    outageStatuses: outageStatusEntry[];
    lastOutageStatusFetch: number;
    artistIssueReportIssueSelectedItem?: baseMediaItem;
    pitchFormSelectedAlbumRelease?: albumRelease;
    pitchFormSelectedTrack?: string;
    pitchFormSelectedStep?: number;
    pitchFormDescription?: string;
    pitchFormGenresProps: group[];
    pitchFormCompositionsProps: itemProps[];
    pitchFormSongHasVocals?: boolean;
    pitchFormLangugagesProps: group[];
    pitchFormSongMoodsProps: itemProps[];
    pitchFormSongStylesProps: itemProps[];
    pitchFormRelatedArtistsProps: group[];
    pitchFormLocationProps: group[];
    promoCardSelectedStep?: number;
};

const loadState = () => {
    try {
        const serializedState = localStorage.getItem("state");

        if (serializedState === null) {
            return undefined;
        }

        const persistentState = JSON.parse(serializedState) as persistentData;

        const artistControl: ArtistControlState = {
            ...initialArtistControlState,
            blueprintsUrl: persistentState.artistControlBlueprintsUrl,
        };

        const featureAccess: FeatureAccessState = {
            ...initialFeatureAccessState,
            featurePermissionsList: persistentState.featurePermissions,
        };

        const artistSearch: ArtistSearchState = {
            ...initialArtistSearchState,
            selectedArtist: persistentState.artistSearchSelectedArtist,
            claimSubmitted: persistentState.artistSearchClaimSubmitted,
        };

        const catalog: CatalogState = {
            ...initialCatalogState,
            catalog: new Map(persistentState.catalog),
        };

        const reporting: ReportingState = {
            ...initialReportingState,
            selectedRange: persistentState.reportingSelectedRange,
            selectedAlbumAsin: persistentState.reportingSelectedAlbumAsin,
            selectedStationAsin: persistentState.reportingSelectedStationAsin,
            selectedPlaylistAsin: persistentState.reportingSelectedPlaylistAsin,
            selectedSongAsin: persistentState.reportingSelectedSongAsin,
            startDate: persistentState.reportingstartDate,
            endDate: persistentState.reportingEndDate,
        };

        const team: TeamState = {
            ...initialTeamState,
            teamMembers: persistentState.teamTeamMembers,
            invitedMembers: persistentState.teamInvitedMembers,
            locale: persistentState.teamLocale,
            invitePaginationToken: persistentState.teamInvitePaginationToken,
        };

        const user: UserState = {
            ...initialUserState,
            signedIn: persistentState.userSignedIn,
            selectedArtistAsin: persistentState.userSelectedArtistAsin,
            managedArtists: persistentState.userManagedArtists,
            teams: persistentState.userTeams,
            selectedTeamId: persistentState.userSelectedTeamId,
            isPrivileged: persistentState.userIsPrivileged,
            recentlyViewedArtists: persistentState.userRecentlyViewedArtists,
            settings: persistentState.userSettings,
            locale: persistentState.userLocale,
            cookieConsentSetting: persistentState.cookieConsentSetting,
            cookieModalText: persistentState.cookieModalText,
        };

        const outageStatus: OutageStatusState = {
            ...initialOutageStatusState,
            outageStatuses: persistentState.outageStatuses,
            lastOutageStatusFetch: persistentState.lastOutageStatusFetch,
        };

        const artistIssue: ArtistIssueState = {
            ...initialArtistSearchState,
            reportIssueSelectedItem:
                persistentState.artistIssueReportIssueSelectedItem,
        };

        const pitchForm: PitchFormReducerState = {
            ...initialPitchFormReducerState,
            selectedAlbumRelease: persistentState.pitchFormSelectedAlbumRelease,
            selectedTrack: persistentState.pitchFormSelectedTrack,
            selectedStep: persistentState.pitchFormSelectedStep,
            description: persistentState.pitchFormDescription,
            genresProps: persistentState.pitchFormGenresProps,
            compositionsProps: persistentState.pitchFormCompositionsProps,
            songHasVocals: persistentState.pitchFormSongHasVocals,
            languagesProps: persistentState.pitchFormLangugagesProps,
            songMoodsProps: persistentState.pitchFormSongMoodsProps,
            songStylesProps: persistentState.pitchFormSongStylesProps,
            relatedAritstProps: persistentState.pitchFormRelatedArtistsProps,
            locationProps: persistentState.pitchFormLocationProps,
        };

        const promoCard: PromoCardState = {
            ...initialPromoCardReducerState,
            selectedStep: persistentState.promoCardSelectedStep,
        };

        const pitch: PitchState = initialPitchState;

        const artistStudio: ArtistStudioState = initialArtistStudioState;

        return {
            artistControl,
            featureAccess,
            artistSearch,
            catalog,
            reporting,
            team,
            user,
            outageStatus,
            artistIssue,
            pitchForm,
            promoCard,
            pitch,
            artistStudio,
        };
    } catch (e) {
        return undefined;
    }
};

const saveState = (state: Readonly<RootState>) => {
    try {
        const persistentState: persistentData = {
            artistControlBlueprintsUrl: state.artistControl.blueprintsUrl,
            featurePermissions: state.featureAccess.featurePermissionsList,
            artistSearchSelectedArtist: state.artistSearch.selectedArtist,
            artistSearchClaimSubmitted: state.artistSearch.claimSubmitted,
            catalog: Array.from(state.catalog.catalog),
            cookieConsentSetting: state.user.cookieConsentSetting,
            cookieModalText: state.user.cookieModalText,
            reportingSelectedRange: state.reporting.selectedRange,
            reportingSelectedAlbumAsin: state.reporting.selectedAlbumAsin,
            reportingSelectedStationAsin: state.reporting.selectedStationAsin,
            reportingSelectedPlaylistAsin: state.reporting.selectedPlaylistAsin,
            reportingSelectedSongAsin: state.reporting.selectedSongAsin,
            reportingstartDate: state.reporting.startDate,
            reportingEndDate: state.reporting.endDate,
            teamTeamMembers: state.team.teamMembers,
            teamInvitedMembers: state.team.invitedMembers,
            teamLocale: state.team.locale,
            teamInvitePaginationToken: state.team.invitePaginationToken,
            userSignedIn: state.user.signedIn,
            userSelectedArtistAsin: state.user.selectedArtistAsin,
            userManagedArtists: state.user.managedArtists,
            userTeams: state.user.teams,
            userSelectedTeamId: state.user.selectedTeamId,
            userIsPrivileged: state.user.isPrivileged,
            userRecentlyViewedArtists: state.user.recentlyViewedArtists,
            userSettings: state.user.settings,
            userLocale: state.user.locale,
            outageStatuses: state.outageStatus.outageStatuses,
            lastOutageStatusFetch: state.outageStatus.lastOutageStatusFetch,
            artistIssueReportIssueSelectedItem:
                state.artistIssue.reportIssueSelectedItem,
            pitchFormSelectedAlbumRelease: state.pitchForm.selectedAlbumRelease,
            pitchFormSelectedTrack: state.pitchForm.selectedTrack,
            pitchFormSelectedStep: state.pitchForm.selectedStep,
            pitchFormDescription: state.pitchForm.description,
            pitchFormGenresProps: state.pitchForm.genresProps,
            pitchFormCompositionsProps: state.pitchForm.compositionsProps,
            pitchFormSongHasVocals: state.pitchForm.songHasVocals,
            pitchFormLangugagesProps: state.pitchForm.languagesProps,
            pitchFormSongMoodsProps: state.pitchForm.songMoodsProps,
            pitchFormSongStylesProps: state.pitchForm.songStylesProps,
            pitchFormRelatedArtistsProps: state.pitchForm.relatedAritstProps,
            pitchFormLocationProps: state.pitchForm.locationProps,
            promoCardSelectedStep: state.promoCard.selectedStep,
        };
        const serializedState = JSON.stringify(persistentState);
        localStorage.setItem("state", serializedState);
    } catch (e) {
        // Ignore write errors;
    }
};

const persistedState = loadState();
// Create a saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create redux store and run the middleware. Will need to remove dev tools in prod
const store: Store = createStore(
    reducers,
    persistedState,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
);

export const clearStore = () => {
    store.dispatch(storeActions.clearStore());
};

export const unsubscribe = store.subscribe(
    throttle(() => {
        saveState(store.getState());
    }, 1000)
);

sagaMiddleware.run(rootSaga);

export default store;
