import _ from "lodash";
import { stringIds } from "../../assets";
import {
    CatalogItemType,
    Experience,
    ExperienceAsset,
    ExperienceAssetKey,
    ExperienceAssetSource,
    ExperienceEntityType,
    ExperienceState,
    ExperienceSupportedAudioExtensions,
    ExperienceSupportedImageExtensions,
    hydrateCatalogPayload,
} from "../../models";

// Required to get a comma delimited list of supported audio extensions
// as part of audio upload to only allow files with the correct extensions
// to be uploaded
const getSupportedAudioFileTypesCommaDelimited = (): string => {
    return joinWithCommas(Object.values(ExperienceSupportedAudioExtensions));
};

// Required to get a comma delimited list of supported Image extensions
// as part of image upload to only allow files with the correct extensions
// to be uploaded
const getSupportedImageFileTypesCommaDelimited = (): string => {
    return joinWithCommas(Object.values(ExperienceSupportedImageExtensions));
};

const joinWithCommas = (values: string[]): string => {
    return _.join(
        _.map(values, (value: string) => `.${value}`),
        ","
    );
};

const getExperienceAssetFromExperience = (
    experience: Experience,
    assetKey: ExperienceAssetKey
): ExperienceAsset | undefined => {
    const asset = _.filter(experience.assets, (asset) => {
        return asset.key === assetKey;
    });

    if (asset.length === 1) {
        return asset[0];
    }

    return undefined;
};

const putExperienceAssetIntoExperienceAssetList = (
    assets: ExperienceAsset[],
    asset: ExperienceAsset
): ExperienceAsset[] => {
    let currentAssets = _.cloneDeep(assets);

    const assetToUpdate = _.findIndex(currentAssets, (theAsset) => {
        return theAsset.key === asset.key;
    });

    if (assetToUpdate === -1) {
        currentAssets.push(asset);
    } else {
        currentAssets[assetToUpdate] = asset;
    }

    return currentAssets;
};

const putExistingExperienceAssetIfNewAssetDoesNotExist = (
    newAssets: ExperienceAsset[],
    existingAssets: ExperienceAsset[],
    experienceId: string
): ExperienceAsset[] => {
    let currentAssets = _.cloneDeep(newAssets);

    _.forEach(existingAssets, (assetToPut) => {
        const assetToUpdate = _.findIndex(currentAssets, (assetToKeep) => {
            return assetToKeep.key === assetToPut.key;
        });

        if (assetToUpdate === -1) {
            // To make create call with existing asset
            // Send: experienceId|assetKey
            currentAssets.push({
                ...assetToPut,
                asset: `${experienceId}|${assetToPut.key}`,
                sourceType: ExperienceAssetSource.EXISTING_EXPERIENCE,
            });
        }
    });

    return currentAssets;
};

const transformToAcceptedMimeType = (mimeType: string): string => {
    switch (mimeType) {
        case "audio/mp4":
        case "audio/m4a":
        case "audio/x-m4a":
            return "video/mp4";
        case "audio/x-wav":
            return "audio/wav";
        default:
            return mimeType;
    }
};

const getExtensionFromFileName = (filename: string): string => {
    const splitFilename = filename.split(".");

    if (splitFilename.length === 0) {
        return "";
    }

    return splitFilename[splitFilename.length - 1];
};

const getExtensionFromMimeType = (mimeType: string): string => {
    const splitMimetype = mimeType.split("/");

    if (splitMimetype.length === 0) {
        return "";
    }

    return splitMimetype[splitMimetype.length - 1];
};

const getExperienceStateStringId = (
    experienceState: ExperienceState
): string => {
    switch (experienceState) {
        case ExperienceState.APPROVED:
            return stringIds.ArtistStudio.experienceStateApproved;
        case ExperienceState.ARCHIVED:
            return stringIds.ArtistStudio.experienceStateArchived;
        case ExperienceState.DRAFT:
            return stringIds.ArtistStudio.experienceStateDraft;
        case ExperienceState.LIVE:
            return stringIds.ArtistStudio.experienceStateLive;
        case ExperienceState.REJECTED:
            return stringIds.ArtistStudio.experienceStateRejected;
        case ExperienceState.REVIEW:
            return stringIds.ArtistStudio.experienceStateReview;
        default:
            return "";
    }
};

const getAsinsToHydrateForEntityType = (
    experiences: Experience[],
    entityType: ExperienceEntityType
): string[] => {
    return _.map(
        _.filter(
            _.flatMap(
                experiences,
                (experience) => experience.associatedEntities
            ),
            (entity) => entity.entityType === entityType
        ),
        (entity) => entity.identifier
    );
};

const getHydrationPayloadsForAssociatedEntities = (
    experiences: Experience[]
): hydrateCatalogPayload[] => {
    const albumsToHydratePayload: hydrateCatalogPayload = {
        asins: getAsinsToHydrateForEntityType(
            experiences,
            ExperienceEntityType.ALBUM
        ),
        type: CatalogItemType.Albums,
    };

    const tracksToHydratePayload: hydrateCatalogPayload = {
        asins: getAsinsToHydrateForEntityType(
            experiences,
            ExperienceEntityType.TRACK
        ),
        type: CatalogItemType.Tracks,
    };

    return [albumsToHydratePayload, tracksToHydratePayload];
};

export const ExperienceUtil = {
    getExperienceAssetFromExperience,
    getSupportedAudioFileTypesCommaDelimited,
    getSupportedImageFileTypesCommaDelimited,
    transformToAcceptedMimeType,
    getExtensionFromFileName,
    putExperienceAssetIntoExperienceAssetList,
    getExtensionFromMimeType,
    putExistingExperienceAssetIfNewAssetDoesNotExist,
    getExperienceStateStringId,
    getHydrationPayloadsForAssociatedEntities,
};
