import * as React from "react";
import { Row, Modal, Image, Col } from "react-bootstrap";
import { IconsList } from "..";
import * as rootStyles from "../../styles";
import { MediumSolidButton, MediumOutlineButton } from "../";
import { getLocalizedString } from "../../../utils";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import { Icon, BlurredModal, styledTitle } from "../common";
import styled from "styled-components";
import { BundleMap } from "../../../models";

export const TwitchPostLaunchModalTestIdPrefix = "TwitchPostLaunchModal";

export type TwitchPostLaunchModalProps = {
    primaryButtonText: string;
    secondaryButtonText?: string;
    primaryButtonAction: () => void;
    secondaryButtonAction?: () => void;
    onDismiss?: () => void;
    isVisible: boolean;
    bundleMap: BundleMap;
};

export class TwitchPostLaunchModal extends React.Component<TwitchPostLaunchModalProps> {
    render() {
        const spacer = <div style={spacerStyle} />;

        return (
            <BlurredModal
                id="blurred-modal"
                show={this.props.isVisible}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                minwidth="256px"
                maxwidth="512px"
                top="30%"
            >
                <Modal.Body>
                    <Row
                        style={{
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}
                    >
                        <div
                            onClick={this.props.onDismiss}
                            style={dismissIconContainerStyle}
                        >
                            <Icon
                                icon={IconsList.action_cancel}
                                size={rootStyles.icons.medium}
                                id={`${TwitchPostLaunchModalTestIdPrefix}_DismissIcon`}
                            />
                        </div>
                    </Row>
                    {spacer}
                    <Row>
                        <Image
                            src={ImageList.ftue_twitch}
                            style={{ width: "100%" }}
                        />
                    </Row>
                    {spacer}
                    <Row>
                        <styledTitle.h4
                            style={{
                                textTransform: "none",
                            }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.TWITCH_STRINGS,
                                stringId: stringIds.Twitch.modalPostLaunchTitle,
                            })}
                        </styledTitle.h4>
                    </Row>
                    {spacer}
                    <Row>
                        <span style={rootStyles.textStyles.primary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.TWITCH_STRINGS,
                                stringId:
                                    stringIds.Twitch.modalPostLaunchSubTitle,
                            })}
                        </span>
                    </Row>
                    {spacer}
                    {modalItem(
                        getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.TWITCH_STRINGS,
                            stringId: stringIds.Twitch.modalPostLaunchLabelOne,
                        }),
                        `${TwitchPostLaunchModalTestIdPrefix}_Item1`
                    )}
                    {modalItem(
                        getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.TWITCH_STRINGS,
                            stringId: stringIds.Twitch.modalPostLaunchLabelTwo,
                        }),
                        `${TwitchPostLaunchModalTestIdPrefix}_Item2`
                    )}
                    <Row style={centeredRowStyle}>
                        <MediumSolidButton
                            id={
                                TwitchPostLaunchModalTestIdPrefix +
                                "_PrimaryButton"
                            }
                            title={this.props.primaryButtonText}
                            onClick={this.props.primaryButtonAction}
                            containerStyle={buttons}
                        />
                    </Row>
                    {this.props.secondaryButtonAction &&
                        this.props.secondaryButtonText && (
                            <Row style={centeredRowStyle}>
                                <MediumOutlineButton
                                    id={
                                        TwitchPostLaunchModalTestIdPrefix +
                                        "_SecondaryButton"
                                    }
                                    title={this.props.secondaryButtonText}
                                    onClick={this.props.secondaryButtonAction}
                                    containerStyle={buttons}
                                />
                            </Row>
                        )}
                </Modal.Body>
            </BlurredModal>
        );
    }
}
const modalItem = (text: string, id: string) => {
    return (
        <Row style={{ marginBottom: rootStyles.spacers.large }}>
            <Col xs={2} style={{ maxWidth: 32 }}>
                <Icon
                    size={24}
                    color={rootStyles.colors.accent}
                    icon={IconsList.action_done}
                    id={id}
                />
            </Col>
            <Col xs={10}>
                <span style={rootStyles.textStyles.secondary}>{text}</span>
            </Col>
        </Row>
    );
};
const spacerStyle: React.CSSProperties = {
    flex: 1,
    minHeight: rootStyles.spacers.large,
};
const dismissIconContainerStyle: React.CSSProperties = {
    height: 36,
    width: 36,
    paddingTop: 4,
    cursor: "pointer",
    textAlign: "center",
    borderRadius: 18,
    backgroundColor: rootStyles.glassColors.primary2,
};
const buttons: React.CSSProperties = {
    width: "100%",
    flex: 1,
};
const centeredRowStyle: React.CSSProperties = {
    textAlign: "center",
    alignItems: "stretch",
    width: "100%",
    marginBottom: rootStyles.spacers.medium,
};
