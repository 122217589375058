import _ from "lodash";
import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { AnyAction } from "redux";
import {
    CatalogProps,
    METRIC_KEYS,
    telemetryPayload,
    hydrateCatalogPayload,
    Experience,
    GetExperiencePayload,
    UpdateExperienceStatePayload,
    ListExperiencePayload,
    ExperienceType,
    InitiateExperiencePayload,
    BundleMap,
} from "../../../models";
import {
    userActions,
    telemetryActions,
    catalogActions,
    RootState,
    artistStudioActions,
    announcementSeeAllSelector,
} from "../../../store";
import { paths } from "../../../utils";
import { AnnouncementsTable } from "../../components";
import * as rootStyles from "../../styles";

type StateProps = CatalogProps & {
    selectedArtistAsin: string;
    listExperiencesInProgress: boolean;
    listExperiencesPaginationToken: string;
    experiences: Experience[];
    teamId: string;
    quickUpdateExperienceStateInProgress: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    userAction: (payload: telemetryPayload) => void;
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    initiateExperience: (payload: InitiateExperiencePayload) => void;
    getExperience: (payload: GetExperiencePayload) => void;
    quickUpdateExperienceState: (payload: UpdateExperienceStatePayload) => void;
    listExperiences: (payload: ListExperiencePayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps;

type State = {
    loadedCount: number;
    finishedInitialLoad: boolean;
};

const testIDPrefix = "AnnouncementsSeeAllScreen";
const metricPrefix = "announcementsSeeAllScreen";
const pagePath = paths.announcements;

class AnnouncementsSeeAllScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadedCount: 0,
            finishedInitialLoad: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If no artist has been selected go to select Artist screen
        if (!this.props.selectedArtistAsin) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        if (!this.props.experiences) {
            this.listInitialExperiences();
        } else {
            this.setState({ finishedInitialLoad: true });
        }

        this.props.userAction({
            name: `${metricPrefix}View`,
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtistAsin],
                [METRIC_KEYS.page, pagePath],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props) {
        // Hydration and request build complete
        if (
            this.state.finishedInitialLoad === false &&
            !this.props.listExperiencesInProgress &&
            !this.props.hydrationInProgress &&
            (prevProps.listExperiencesInProgress ||
                prevProps.hydrationInProgress)
        ) {
            this.setState({ finishedInitialLoad: true });
        }
        if (
            this.props.quickUpdateExperienceStateInProgress !==
                prevProps.quickUpdateExperienceStateInProgress &&
            !this.props.quickUpdateExperienceStateInProgress
        ) {
            this.listInitialExperiences();
        }
    }

    render() {
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    paddingTop: 0,
                }}
            >
                <AnnouncementsTable
                    userAction={this.props.userAction}
                    catalog={this.props.catalog}
                    hydratingAsins={this.props.hydratingAsins}
                    failedAsins={this.props.failedAsins}
                    catalogBuildCompleted={this.props.catalogBuildCompleted}
                    hydrationInProgress={this.props.hydrationInProgress}
                    selectedArtistAsin={this.props.selectedArtistAsin}
                    pagePath={window.location.pathname}
                    loadingInProgress={this.props.listExperiencesInProgress}
                    isRefreshing={
                        !this.state.finishedInitialLoad ||
                        !this.props.experiences ||
                        this.props.quickUpdateExperienceStateInProgress
                    }
                    announcements={this.props.experiences}
                    history={this.props.history}
                    initiateDraft={this.props.initiateExperience}
                    teamId={this.props.teamId}
                    getExperience={this.props.getExperience}
                    updateExperienceState={
                        this.props.quickUpdateExperienceState
                    }
                    paginationToken={this.props.listExperiencesPaginationToken}
                    listExperiences={this.props.listExperiences}
                    scrolling={true}
                    id={`${testIDPrefix}_AnnouncementsTable`}
                    bundleMap={this.props.bundleMap}
                />
            </Container>
        );
    }

    private listInitialExperiences = () => {
        const listExperiencesPayload: ListExperiencePayload = {
            artistAsin: this.props.selectedArtistAsin,
            experienceTypeFilter: [ExperienceType.ANNOUNCEMENT],
            teamId: this.props.teamId,
            requestPath: pagePath,
        };
        this.props.listExperiences(listExperiencesPayload);
    };
}

function mapStateToProps(state: RootState): StateProps {
    return announcementSeeAllSelector(state, paths.announcements);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        initiateExperience: (payload: InitiateExperiencePayload) =>
            dispatch(artistStudioActions.initiateExperience(payload)),
        getExperience: (payload: GetExperiencePayload) =>
            dispatch(artistStudioActions.getExperience(payload)),
        quickUpdateExperienceState: (payload: UpdateExperienceStatePayload) =>
            dispatch(artistStudioActions.quickUpdateExperienceState(payload)),
        listExperiences: (payload: ListExperiencePayload) =>
            dispatch(artistStudioActions.listExperiences(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AnnouncementsSeeAllScreen)
);
