import { Action } from "redux-ts";
import { artistControlActions } from "../actions";

export type ArtistControlState = Readonly<{
    blueprintsUrl?: string;
}>;
 
export const initialArtistControlState: ArtistControlState = {};
 
export const artistControlReducer = (state: ArtistControlState = initialArtistControlState, action: Action) => {
    switch (action.type) {
        case artistControlActions.manageSkillsCompleted.type:
            return {...state, blueprintsUrl: action.payload};
        case artistControlActions.clearState.type:
            return initialArtistControlState;
        default:
            return state;
    }
};