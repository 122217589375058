import React from "react";
import { METRIC_KEYS, telemetryPayload } from "../../../../../models";
import { Icon, IconsList } from "../../../../../view/components/common";
import * as rootStyles from "../../../../styles";

type PropTypes = {
    playerHasStarted: (playerStarted: boolean) => void;
    userAction: (payload: telemetryPayload) => void;
    artistAsin?: string;
    player?: any;
    actions?: any;
    hasEnded: boolean;
    id: string;
};

type StateTypes = {
    isPlaying: boolean;
};

const metricPrefix = "SpotlightExplainerVideo";

export default class BigPlayButton extends React.Component<
    PropTypes,
    StateTypes
> {
    constructor(props: PropTypes) {
        super(props);
        this.state = {
            isPlaying: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.trackOnClickPause = this.trackOnClickPause.bind(this);
        this.trackOnClickPlay = this.trackOnClickPlay.bind(this);
    }

    componentDidUpdate(prevProps: PropTypes) {
        if (!prevProps.hasEnded && this.props.hasEnded) {
            this.setState({ isPlaying: false });
        }
    }

    handleClick() {
        const { actions, player } = this.props;
        this.props.playerHasStarted(true);

        if (player.error) {
            this.trackPlayerError();
        }

        if (player.paused) {
            actions.play();
            this.trackOnClickPlay();
        } else {
            actions.pause();
            this.trackOnClickPause();
        }
        this.setState({ isPlaying: !this.state.isPlaying });
    }

    trackPlayerError() {
        this.props.userAction({
            name: `${metricPrefix}-WebVideoPlayerError`,
            dataPoints: new Map<string, string | undefined>([]),
        });
    }

    trackOnClickPlay() {
        this.props.userAction({
            name: `${metricPrefix}-PlayButtonClick`,
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.artistAsin],
            ]),
        });
    }

    trackOnClickPause() {
        const currentTime = this.props.player?.currentTime;
        this.props.userAction({
            name: `${metricPrefix}-PauseButtonClick`,
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.artistAsin],
                [METRIC_KEYS.durationPlayed, `${currentTime}s`],
            ]),
        });
    }

    render() {
        return (
            <button
                style={{
                    position: "absolute",
                    backgroundColor: rootStyles.glassColors.primary3,
                    borderRadius: 40,
                    height: "70px",
                    width: "70px",
                }}
                onClick={this.handleClick}
            >
                {!this.state.isPlaying || this.props.hasEnded ? (
                    <Icon
                        icon={IconsList.playback_play}
                        size={45}
                        id={`${this.props.id}_Play`}
                    ></Icon>
                ) : (
                    <Icon
                        icon={IconsList.playback_pause}
                        size={45}
                        id={`${this.props.id}_Pause`}
                    ></Icon>
                )}
            </button>
        );
    }
}
