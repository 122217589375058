import PropTypes from "prop-types";
import React from "react";
import { Player, ControlBar, Shortcut } from "video-react";
import "video-react/dist/video-react.css";
import "./video.css";
import BigPlayButton from "./controls/BigPlayButton";
import { telemetryPayload } from "../../../../models";

type PropTypes = {
    videoURL: string;
    videoThumbnail: any;
    userAction: (payload: telemetryPayload) => void;
    artistAsin?: string;
    id: string;
};

type StateTypes = {
    hasStarted: boolean;
    hasEnded: boolean;
};

export default class Video extends React.Component<PropTypes, StateTypes> {
    constructor(props: PropTypes) {
        super(props);
        this.state = {
            hasStarted: false,
            hasEnded: false,
        };
        this.playerHasStarted = this.playerHasStarted.bind(this);
        this.onVideoEnd = this.onVideoEnd.bind(this);
    }

    playerHasStarted(playerStarted: boolean) {
        this.setState({ hasEnded: false });
        this.setState({ hasStarted: playerStarted });
    }

    onVideoEnd() {
        this.setState({ hasEnded: true });
    }

    render() {
        return (
            // Target Div behind Video Player to expand into full screen when adding full screen
            <div id="FullScreenOverlay">
                <div
                    id="playerDiv"
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Player
                        className="video"
                        poster={this.props.videoThumbnail}
                        src={this.props.videoURL}
                        width={"100%"}
                        height={"100%"}
                        onEnded={this.onVideoEnd}
                        style={{
                            borderRadius: 8,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Shortcut
                            clickable={false}
                            shortcuts={[
                                { keyCode: 32, handle: () => {} }, // disable spacebar
                                {
                                    keyCode: 70, // override video-react F key to use custom toggle
                                    handle: () => {},
                                },
                            ]}
                        />
                        <ControlBar
                            className="video-react video-react-control-bar"
                            autoHide={true}
                            disableDefaultControls
                        >
                            <BigPlayButton
                                playerHasStarted={this.playerHasStarted}
                                hasEnded={this.state.hasEnded}
                                userAction={this.props.userAction}
                                artistAsin={this.props.artistAsin}
                                id={`${this.props.id}_Button`}
                            />
                        </ControlBar>
                    </Player>
                </div>
            </div>
        );
    }
}
