import axios from "axios";
import {
    hydrateTwitchRequest,
    channel,
    unlinkTwitchChannelRequest,
    linkTwitchChannelRequest,
    getTwitchChannelRequest,
    LinkTwitchChannelResponse,
    GetTwitchChannelResponse,
    HydrateTwitchResponse,
} from "../models";
import { getEndpoints, testHeaders } from "./common";

const baseArtistControlUrl = getEndpoints().artistControl;
const baseCatalogProxyUrl = getEndpoints().catalog;
const unlinkTwitchChannelUrl = `${baseArtistControlUrl}/artist/twitch/unlink`;
const linkTwitchChannelUrl = `${baseArtistControlUrl}/artist/twitch/link`;
const getTwitchChannelUrl = `${baseArtistControlUrl}/artist/twitch`;
const hydrateTwitchChannelUrl = `${baseCatalogProxyUrl}/hydrate/twitch`;

export async function hydrateTwitchChannel(
    request: hydrateTwitchRequest
): Promise<HydrateTwitchResponse> {
    const result = await sendRequest(
        hydrateTwitchChannelUrl,
        request,
        request.teamId
    );
    return result.data;
}
export async function unlinkTwitchChannel(
    query: unlinkTwitchChannelRequest
): Promise<void> {
    const request = {
        artistAsin: query.artistAsin,
    };

    const result = await sendRequest(
        unlinkTwitchChannelUrl,
        request,
        query.teamId
    );
    return result.data;
}

export async function linkTwitchChannel(
    query: linkTwitchChannelRequest
): Promise<LinkTwitchChannelResponse> {
    const request = {
        artistAsin: query.artistAsin,
        authId: query.authId,
    };

    const result = await sendRequest(
        linkTwitchChannelUrl,
        request,
        query.teamId
    );
    return result.data;
}

export async function getTwitchChannel(
    query: getTwitchChannelRequest
): Promise<GetTwitchChannelResponse> {
    const request = {
        artistAsin: query.artistAsin,
    };

    const result = await sendRequest(
        getTwitchChannelUrl,
        request,
        query.teamId
    );
    return result.data;
}

function sendRequest(url: string, payload: any, teamId: string) {
    return axios.post(url, payload, {
        headers: {
            "x-team-id": teamId,
            ...testHeaders(),
        },
        withCredentials: true,
    });
}
