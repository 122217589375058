import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { HelpModal, Icon, SearchSelect, Tooltip } from ".";
import { IconsList, ImageList, stringIds, bundleIds } from "../../../assets";
import { artist, BundleMap, PitchStatus } from "../../../models";
import { ContentListItem } from "../lists";
import * as rootStyles from "../../styles";
import {
    MediumOutlineButton,
    MediumSolidButton,
    SmallGlassButton,
} from "./input";
import { getLocalizedString } from "../../../utils";

export const getSearchAndSelectArtist = (
    selectedArtist: artist | undefined,
    id: string,
    onRemove: any,
    onSelect: any,
    inProgress: boolean,
    displayResults: boolean,
    artistResults: artist[],
    onTextChange: any
) => {
    return (
        <div style={{ marginTop: rootStyles.spacers.mini }}>
            {selectedArtist ? (
                <Col style={selectedArtistStyle}>
                    <Row>
                        <Col>
                            <ContentListItem
                                key={selectedArtist.asin}
                                primary={selectedArtist.title}
                                image={selectedArtist.images?.artMedium}
                                fallbackImage={ImageList.placeholder_artist}
                                onClick={onRemove}
                                imageDescription={selectedArtist.title}
                                id={id + "-ContentList"}
                                rightIcon={IconsList.action_removeInline}
                            />
                        </Col>
                    </Row>
                </Col>
            ) : (
                <Col>
                    <SearchSelect
                        onSelectElement={onSelect}
                        inProgress={inProgress}
                        displayResults={displayResults}
                        items={artistResults}
                        onTextChange={onTextChange}
                        id={id + "-SearchSelect"}
                    />
                </Col>
            )}
        </div>
    );
};

export const getHelpTooltip = (
    text: string,
    buttonText: string | undefined,
    buttonAction: (() => void) | undefined,
    id: string
) => {
    return (
        <Tooltip
            icon={IconsList.ic_help}
            message={text}
            buttonText={buttonText}
            buttonAction={buttonAction}
            id={id}
        />
    );
};

export const getRadioItems = (items: string[]) => {
    const radioItems: any = [];

    items.forEach((item) => {
        radioItems.push({
            primary: item,
        });
    });

    return radioItems;
};

export const getTitleWithHelp = (
    title: string,
    helpText: string,
    helpButtonText: string | undefined,
    helpButtonAction: (() => void) | undefined,
    id: string
) => {
    return (
        <Row
            style={{
                ...sidePaddingRowStyle,
                maxWidth: 480,
                alignItems: "center",
                flexWrap: "nowrap",
            }}
        >
            <div style={{ display: "flex" }}>
                <span style={fieldTitleStyle}>{title}</span>
            </div>
            <div
                style={{
                    ...colVerticalAlignStyle,
                    paddingLeft: rootStyles.spacers.mini,
                }}
            >
                {getHelpTooltip(
                    helpText,
                    helpButtonText,
                    helpButtonAction,
                    `${id}_HelpTooltip`
                )}
            </div>
        </Row>
    );
};

export const getWarningRow = (title: string, id: string) => {
    return (
        <Row style={warningRowStyle}>
            <Col
                xs={"auto"}
                style={{
                    ...colVerticalAlignStyle,
                    paddingRight: 0,
                }}
                id={id}
            >
                <Icon
                    icon={IconsList.ic_warning_yellow}
                    size={25}
                    id={`${id}_Icon`}
                />
            </Col>
            <Col>
                <span
                    style={{
                        ...rootStyles.textStyles.tertiary,
                        color: rootStyles.colors.warning,
                    }}
                >
                    {title}
                </span>
            </Col>
        </Row>
    );
};

export const rightArrow = (id: string) => {
    return (
        <Icon
            size={rootStyles.icons.small}
            icon={IconsList.chevron_caretright}
            id={id}
        />
    );
};

export const pitchAlbumRightElement = (
    status: string,
    pitchButtonCallback: () => void,
    anouncementButtonCallback: (() => void) | undefined,
    hasPitchPermissions: boolean,
    hasAnnouncementPermissions: boolean,
    bundleMap: BundleMap,
    pitchDate: string | undefined,
    id: string
) => {
    const pitchCallToAction = () => {
        if (!hasPitchPermissions) {
            return <> </>;
        }

        if (status === PitchStatus.Pitchable) {
            return (
                <SmallGlassButton
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.pitchButton,
                    })}
                    onClick={() => pitchButtonCallback()}
                    id={`${id}_PitchButton`}
                />
            );
        }

        if (status === PitchStatus.Pitched) {
            return pitchedOnMessage(bundleMap, pitchDate);
        }

        return (
            <span style={rootStyles.textStyles.tertiary}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.PITCH_STRINGS,
                    stringId: stringIds.Pitch.unpitchable,
                })}
            </span>
        );
    };

    const announcementCallToAction = (bundleMap: BundleMap) => {
        if (!hasAnnouncementPermissions || !anouncementButtonCallback) {
            return <> </>;
        }

        return (
            <SmallGlassButton
                title={getLocalizedString(bundleMap, {
                    bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                    stringId:
                        stringIds.ArtistStudio.Announcement
                            .announcementNewReleaseButtonText,
                })}
                onClick={() => anouncementButtonCallback()}
                id={`${id}_AnnounceButton`}
            />
        );
    };

    if (!hasPitchPermissions && !hasAnnouncementPermissions) {
        return (
            <span
                style={{
                    ...rootStyles.textStyles.tertiary,
                    maxWidth: 160,
                    width: 160,
                    marginBottom: "auto",
                    marginTop: "auto",
                }}
            >
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.ACCOUNT_STRINGS,
                    stringId: stringIds.Account.contactAdmin,
                })}
            </span>
        );
    }

    return (
        <Row
            style={{
                gap: rootStyles.spacers.small,
                justifyContent: "flex-end",
                alignItems: "center",
            }}
        >
            {announcementCallToAction(bundleMap)}
            {pitchCallToAction()}
        </Row>
    );
};

export const pitchTrackRightElement = (
    status: string,
    bundleMap: BundleMap,
    pitchDate: string | undefined,
    isPrivileged: boolean | undefined,
    id: string
) => {
    if (isPrivileged) return rightArrow(id);
    if (status === PitchStatus.Pitched)
        return pitchedOnMessage(bundleMap, pitchDate);
    if (status === PitchStatus.Pitchable) return rightArrow(id);
    return (
        <span style={rootStyles.textStyles.tertiary}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.PITCH_STRINGS,
                stringId: stringIds.Pitch.unpitchable,
            })}
        </span>
    );
};

const pitchedOnMessage = (bundleMap: BundleMap, pitchDate?: string) => {
    const pitchDateString = pitchDate || "";
    return (
        <span
            style={{
                ...rootStyles.textStyles.tertiary,
                whiteSpace: "pre",
            }}
        >
            {getLocalizedString(
                bundleMap,
                {
                    bundleId: bundleIds.PITCH_STRINGS,
                    stringId: stringIds.Pitch.pitchedOn,
                },
                { "0": pitchDateString }
            )}
        </span>
    );
};

export const pitchHelpModal = (
    isVisible: boolean = false,
    id: string,
    onDismiss: () => void,
    onDone: () => void,
    bundleMap: BundleMap,
    onLearnMore?: () => void
) => {
    const stepInstruction = (icon: string, message: string, index: number) => {
        return (
            <Row>
                <Icon
                    size={20}
                    icon={icon}
                    style={{ marginRight: rootStyles.spacers.mini, flex: 0 }}
                    id={`${id}_Step${index}`}
                />
                <span
                    style={{
                        ...rootStyles.textStyles.secondary,
                        color: rootStyles.colors.primary,
                        flex: 1,
                    }}
                >
                    {message}
                </span>
            </Row>
        );
    };
    const pitchHelpModalBody = () => {
        return (
            <Row style={{ width: "100%" }}>
                {stepInstruction(
                    IconsList.ic_step_1,
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.NEWRELEASES_STRINGS,
                        stringId: stringIds.NewReleases.helpModalInstruction1,
                    }),
                    1
                )}
                {stepInstruction(
                    IconsList.ic_step_2,
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.NEWRELEASES_STRINGS,
                        stringId: stringIds.NewReleases.helpModalInstruction2,
                    }),
                    2
                )}
                {stepInstruction(
                    IconsList.ic_step_3,
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.NEWRELEASES_STRINGS,
                        stringId: stringIds.NewReleases.helpModalInstruction3,
                    }),
                    3
                )}
            </Row>
        );
    };

    const helpFooter = () => {
        return (
            <>
                <Row style={{ width: "100%" }}>
                    <MediumSolidButton
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.NEWRELEASES_STRINGS,
                            stringId:
                                stringIds.NewReleases.helpModalDismissButton,
                        })}
                        containerStyle={{ flex: 1 }}
                        onClick={onDone}
                        id={`${id}_FooterDismiss`}
                    />
                </Row>
                {onLearnMore ? (
                    <Row
                        style={{
                            width: "100%",
                            marginTop: rootStyles.spacers.small,
                        }}
                    >
                        <MediumOutlineButton
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.NEWRELEASES_STRINGS,
                                stringId:
                                    stringIds.NewReleases
                                        .helpModalLearnMoreButton,
                            })}
                            containerStyle={{ flex: 1 }}
                            onClick={onLearnMore}
                            id={`${id}_FooterLearnMore`}
                        />
                    </Row>
                ) : null}
            </>
        );
    };

    return (
        <HelpModal
            isVisible={isVisible}
            onDismiss={onDismiss}
            title={getLocalizedString(bundleMap, {
                bundleId: bundleIds.NEWRELEASES_STRINGS,
                stringId: stringIds.NewReleases.helpModalTitle,
            })}
            titleStyle={{ textAlign: "center" }}
            body={pitchHelpModalBody}
            footer={helpFooter}
            id={"PitchHelpModal"}
        />
    );
};

const selectedArtistStyle: React.CSSProperties = {
    backgroundColor: rootStyles.glassColors.primary2,
    borderRadius: 9,
    padding: 0,
    marginLeft: 15,
    height: "100%",
    width: "calc(100% - 30px)",
};

const fieldTitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    textTransform: "uppercase",
};

const colVerticalAlignStyle: React.CSSProperties = {
    marginTop: "auto",
    marginBottom: "auto",
};

const sidePaddingRowStyle: React.CSSProperties = {
    paddingRight: 15,
    paddingLeft: 15,
};

const warningRowStyle: React.CSSProperties = {
    paddingTop: rootStyles.spacers.small,
    paddingBottom: rootStyles.spacers.small,
    background: rootStyles.glassColors.warning2,
    marginTop: rootStyles.spacers.small,
    borderRadius: 8,
    borderColor: rootStyles.colors.warning,
    borderStyle: "solid",
    borderWidth: 1,
};
