import _ from "lodash";
import { Action } from "redux-ts";
import { takeEvery, put, select } from "redux-saga/effects";
import { selectedTeamIdSelector } from "../../selectors";
import * as services from "../../../service";
import {
    selectArtistPayload,
    updateSettingsPayload,
    teamInfo,
    METRIC_KEYS,
    registerUserResponse,
    optOutMarketingEmailsPayload,
    Feature,
    CatalogItemType,
    GlobalFeature,
    cookieModalText,
} from "../../../models";
import {
    catalogActions,
    reportingActions,
    artistSearchActions,
    teamManagementActions,
    errorActions,
    telemetryActions,
    twitchActions,
    artistControlActions,
    featureAccessActions,
    localizationActions,
    userActions,
    opsMetricsActions,
} from "../../actions";
import { RootState } from "../../reducers";
import {
    createErrorOpsMetricsPayload,
    createSuccessOpsMetricsPayload,
    DEVICE_TYPE,
    getDeviceId,
    getWebLocale,
    HYDRATION_COUNT,
    paths,
} from "../../../utils";
import {
    startTimer,
    publishTimer,
    privilegedTeamId,
    getCookieConsentSetting,
} from "../../../service";
import { batchMetric } from "../../actions/opsMetricsActions";

export const authSagas = [
    watchRegister(),
    watchSelectArtist(),
    watchLogOut(),
    watchClearArtistRecentlyViewed(),
    watchUpdateSettings(),
    watchUpdateUserLocale(),
    watchOptOut(),
    watchUpdateCookieSetting(),
];

function* registerUser(action: Action) {
    const start = Date.now();
    const functionName = "registerUser";
    const timerMetric = startTimer("registerUserDuration");
    try {
        yield put(userActions.refreshInProgress(true));
        // always make register user call

        yield put(
            telemetryActions.appEvent({
                name: "userRegisterStart",
                dataPoints: new Map<string, string | undefined>([]),
            })
        );

        const result: registerUserResponse = yield services.registerUser();
        const cookieConsentSetting: { cookieConsentString: string } = result.eu
            ? yield services.getCookieConsentSetting(getDeviceId(), DEVICE_TYPE)
            : "";

        const cookieModalText: cookieModalText = result.eu
            ? yield services.getCookieModalText(getWebLocale())
            : undefined;
        const teamInfos: teamInfo[] =
            result && result.teamInfos ? result.teamInfos : [];

        yield put(
            telemetryActions.appEvent({
                name: "userRegisterEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        yield put(
            userActions.updateOAuthClientIdsCompleted(result.oAuthClientIds)
        );

        publishTimer(timerMetric);

        yield put(errorActions.clearError(paths.initialFlow));

        // finish login
        yield put(userActions.loginCompleted(teamInfos));
        yield put(userActions.loginInProgress(false));

        // Update the settings
        yield put(userActions.updateSettingsCompleted(result.userSettings));

        yield put(userActions.refreshInProgress(false));
        yield put(userActions.updateUserState(true));

        // Update username and email address
        yield put(userActions.updateUserData(result.userData));

        yield put(
            userActions.storeCookieConsentSetting(
                cookieConsentSetting.cookieConsentString
            )
        );

        yield put(userActions.storeCookieModalText(cookieModalText));

        // get global permissions
        const currentPath: string = yield select(
            (state: RootState) => state.user.currentPath
        );
        yield put(
            featureAccessActions.getGlobalPermissions({
                requestPath: currentPath,
                upToDateFeatures: Object.values(GlobalFeature),
            })
        );

        // hydarate managed artists
        const teamArtists: string[] = [];
        teamInfos.forEach(
            (team) =>
                team &&
                team.artistAsins &&
                team.artistAsins.forEach((asin) => teamArtists.push(asin))
        );

        while (teamArtists.length > 0) {
            const batch = teamArtists.splice(0, HYDRATION_COUNT);
            yield put(
                catalogActions.hydrateAsins({
                    asins: batch,
                    type: CatalogItemType.Artists,
                })
            );
        }

        // get twitch profile if team is selected
        const teamId: string | undefined = yield select(selectedTeamIdSelector);
        const asin: string | undefined = yield select(
            (state: RootState) => state.user.selectedArtistAsin
        );

        if (teamId && asin) {
            yield put(
                twitchActions.getTwitchChannel({
                    artistAsin: asin,
                    teamId: teamId,
                })
            );
        }
        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        // get current page path so that requestPath is accurately updated
        // in Error payload
        const currentPath: string = yield select(
            (state: RootState) => state.user.currentPath
        );

        yield put(userActions.loginInProgress(false));
        yield put(userActions.refreshInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: currentPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* selectArtist(action: Action<selectArtistPayload>) {
    const start = Date.now();
    const functionName = "selectArtist";
    try {
        const currentArtist: string | undefined = yield select(
            (state: RootState) => state.user.selectedArtistAsin
        );

        if (currentArtist && currentArtist === action.payload.artist.asin) {
            if (
                action.payload.reroutePath &&
                action.payload.historyProps?.history
            ) {
                action.payload.historyProps.history.push(
                    action.payload.reroutePath
                );
            } else {
                const dataPoints = new Map<string, string | undefined>([
                    [METRIC_KEYS.errorName, METRIC_KEYS.undefinedHistoryProps],
                ]);
                yield put(
                    opsMetricsActions.batchMetric(
                        createErrorOpsMetricsPayload(
                            "Reroute current artist",
                            undefined,
                            dataPoints
                        )
                    )
                );
            }
            return;
        }

        if (!action.payload.artist.asin) {
            return;
        }

        yield put(
            catalogActions.hydrateAsins({
                asins: [action.payload.artist.asin],
                type: CatalogItemType.Artists,
            })
        );

        yield put(userActions.selectArtistCompleted(action.payload));

        yield put(
            userActions.updateArtistRecentlyViewed(action.payload.artist)
        );

        const teamId: string | undefined = yield select(selectedTeamIdSelector);

        yield put(twitchActions.clearState());

        if (!!teamId) {
            yield put(
                featureAccessActions.getFeaturePermissions({
                    requestPath: action.payload.requestPath,
                    teamId: teamId,
                    artistAsin: action.payload.artist.asin,
                    upToDateFeatures: Object.values(Feature),
                })
            );
            yield put(
                twitchActions.getTwitchChannel({
                    artistAsin: action.payload.artist.asin,
                    teamId: teamId,
                })
            );
        }

        // Skip getTeam and listInvite service calls for super user
        if (!!teamId && teamId !== privilegedTeamId) {
            yield put(
                teamManagementActions.getTeamMembers({
                    requestPath: action.payload.requestPath,
                    teamId: teamId,
                })
            );

            yield put(
                teamManagementActions.getInvitedMembers({
                    requestPath: action.payload.requestPath,
                    teamId: teamId,
                    paginationToken: "",
                })
            );

            yield put(artistControlActions.clearState());
        }

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );

        if (
            action.payload.reroutePath &&
            action.payload.historyProps?.history
        ) {
            action.payload.historyProps.history.push(
                action.payload.reroutePath
            );
        } else {
            const dataPoints = new Map<string, string | undefined>([
                [METRIC_KEYS.errorName, METRIC_KEYS.undefinedHistoryProps],
            ]);
            yield put(
                opsMetricsActions.batchMetric(
                    createErrorOpsMetricsPayload(
                        "Reroute after artist select",
                        undefined,
                        dataPoints
                    )
                )
            );
        }
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(
            errorActions.handleError({
                requestPath: paths.initialFlow,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* updateSettings(action: Action<updateSettingsPayload>) {
    const start = Date.now();
    const functionName = "updateSettings";
    const timerMetric = startTimer("setPermissionsDuration");
    try {
        yield put(userActions.updateSettingsInProgress(true));

        yield put(
            telemetryActions.appEvent({
                name: "setPermissionsStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                ]),
            })
        );

        yield services.updateSetting(action.payload.settings);

        publishTimer(timerMetric);

        yield put(
            telemetryActions.appEvent({
                name: "setPermissionsEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        yield put(userActions.updateSettingsCompleted(action.payload.settings));
        yield put(userActions.updateSettingsInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(
            errorActions.handleError({
                requestPath: paths.settings,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* optOutMarketingEmails(action: Action<optOutMarketingEmailsPayload>) {
    const start = Date.now();
    const functionName = "optOutMarketingEmails";
    const timerMetric = startTimer("optOutDuration");
    try {
        yield put(userActions.optOutInProgress(true));
        yield put(userActions.optOutCompleted(false));

        yield put(
            telemetryActions.appEvent({
                name: "optOutStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                ]),
            })
        );
        yield services.optOutMarketingEmails(action.payload.optOut);

        publishTimer(timerMetric);

        yield put(
            telemetryActions.appEvent({
                name: "optOutEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        yield put(userActions.optOutCompleted(true));
        yield put(userActions.optOutInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(userActions.optOutInProgress(false));

        yield put(
            errorActions.handleError({
                requestPath: paths.optOut,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* clearArtistRecentlyViewed(action: Action) {
    const functionName = "clearArtistRecentlyViewed";
    try {
        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(
            errorActions.handleError({
                requestPath: paths.reports,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* logOut(action: Action) {
    const functionName = "logOut";
    try {
        yield put(
            telemetryActions.appEvent({
                name: "userLogout",
                dataPoints: new Map<string, string | undefined>([]),
            })
        );
        yield put(userActions.clearArtistRecentlyViewed());
        yield put(userActions.logOutCompleted());
        yield put(artistControlActions.clearState());
        yield put(artistSearchActions.clearArtistSearch());
        yield put(catalogActions.clearState());
        yield put(reportingActions.clearState());
        yield put(teamManagementActions.clearState());

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(
            errorActions.handleError({
                requestPath: paths.initialFlow,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* updateUserLocale(action: Action) {
    const functionName = "updateUserLocale";
    try {
        yield put(
            telemetryActions.appEvent({
                name: "userUpdateLocale",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.locale, action.payload],
                ]),
            })
        );
        yield put(userActions.updateUserLocaleCompleted(action.payload));
        // clear the hydrated items once the user language is changed.
        yield put(catalogActions.clearState());
        // clear the localization bundle map once the user language is changed.
        yield put(localizationActions.clearBundleMap());

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(
            errorActions.handleError({
                requestPath: paths.initialFlow,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* updateCookieSetting(action: Action) {
    const functionName = "updateCookieSetting";
    try {
        yield services.updateCookieConsentSetting(
            getDeviceId(),
            DEVICE_TYPE,
            action.payload
        );
        yield put(userActions.storeCookieConsentSetting(action.payload));
        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(
            errorActions.handleError({
                requestPath: "InitialFlow",
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* watchRegister() {
    yield takeEvery(userActions.registerUser.type, registerUser);
}

function* watchSelectArtist() {
    yield takeEvery(userActions.selectArtist.type, selectArtist);
}

function* watchLogOut() {
    yield takeEvery(userActions.logOut.type, logOut);
}

function* watchClearArtistRecentlyViewed() {
    yield takeEvery(
        userActions.clearArtistRecentlyViewed.type,
        clearArtistRecentlyViewed
    );
}

function* watchUpdateSettings() {
    yield takeEvery(userActions.updateSettings.type, updateSettings);
}

function* watchUpdateUserLocale() {
    yield takeEvery(userActions.updateUserLocale.type, updateUserLocale);
}

function* watchOptOut() {
    yield takeEvery(
        userActions.optOutMarketingEmails.type,
        optOutMarketingEmails
    );
}

function* watchUpdateCookieSetting() {
    yield takeEvery(
        userActions.updateCookieConsentSetting.type,
        updateCookieSetting
    );
}
