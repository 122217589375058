import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { AnyAction } from "redux";
import _ from "lodash";
import {
    artistStudioActions,
    catalogActions,
    newReleasesSelector,
    pitchActions,
    pitchFormActions,
    RootState,
    telemetryActions,
    userActions,
} from "../../../store";
import {
    AnnouncementUtil,
    generateAlbumReleaseRows,
    getLocalizedString,
    getLocalizedUrl,
    hydrationHelpers,
    paths,
} from "../../../utils";
import {
    AnnouncementsTable,
    CommonHeader,
    Icon,
    IncrementalLoading,
    InlineError,
    Loading,
    MediumSolidButton,
    pitchAlbumRightElement,
    pitchHelpModal,
    styledTitle,
    Table,
} from "../../components";
import * as rootStyles from "../../styles";
import { IconsList, stringIds, bundleIds } from "../../../assets";
import {
    albumRelease,
    AnnouncementDraft,
    CatalogProps,
    ErrorPayload,
    Experience,
    ExperienceAssociatedEntity,
    ExperienceDraft,
    ExperienceEntityType,
    ExperiencesPage,
    ExperienceType,
    GetExperiencePayload,
    hydrateCatalogPayload,
    InitiateExperiencePayload,
    listAlbumsToPitchPayload,
    ListExperiencePayload as ListExperiencesPayload,
    ListExperiencesForEntitiesPayload,
    METRIC_KEYS,
    TableRowProps,
    TeamPermissions,
    telemetryPayload,
    UpdateExperienceStatePayload,
    album,
    CatalogItemType,
    BundleMap,
} from "../../../models";
import { LocalizedUrls } from "@amzn/ziggy-asset";

const testIDPrefix = "NewReleasesScreen";
const metricPrefix = "newReleasesScreen";
const pagePath = paths.newReleases;
const NO_RELEASES_DIV_WIDTH = 350;

type ViewProps = {};

type StateProps = CatalogProps & {
    teamId: string;
    selectedArtistAsin: string;
    albumsToPitch?: albumRelease[];
    inProgress: boolean;
    mockedAlbumsToPitch: albumRelease[];
    userLocale: string;
    error?: ErrorPayload;
    userPermissions?: TeamPermissions[];
    isPrivileged?: boolean;
    experiences?: Experience[];
    experienceDrafts: Map<string, ExperienceDraft>;
    experiencesForEntities?: Map<string, ExperiencesPage>;
    listExperiencesInProgress: boolean;
    listExperiencesForEntitiesInProgress: boolean;
    quickUpdateExperienceStateInProgress: boolean;
    hasAnnouncementPermissions: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    userAction: (payload: telemetryPayload) => void;
    selectAlbumRelease: (payload: albumRelease) => void;
    getAlbumReleases: (payload: listAlbumsToPitchPayload) => void;
    createNewExperience: (payload: InitiateExperiencePayload) => void;
    updateExperienceDraftKey: (payload: string) => void;
    listExperiences: (payload: ListExperiencesPayload) => void;
    listExperiencesForEntities: (
        payload: ListExperiencesForEntitiesPayload
    ) => void;
    getExperience: (payload: GetExperiencePayload) => void;
    initiateExperienceDraft: (payload: InitiateExperiencePayload) => void;
    quickUpdateExperienceState: (payload: UpdateExperienceStatePayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    showError: boolean;
    showHelpModal: boolean;
    finishedInitialLoad: boolean;
    loadedCount: number;
};

class NewReleasesScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showError: false,
            showHelpModal: false,
            finishedInitialLoad: false,
            loadedCount: 0,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If no artist has been selected go to select Artist screen
        if (!this.props.selectedArtistAsin) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.getAlbumReleases();

        if (this.props.hasAnnouncementPermissions) {
            this.listExperiences();
        }

        this.props.userAction({
            name: `${metricPrefix}View`,
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtistAsin],
                [METRIC_KEYS.page, pagePath],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props) {
        // Hydration and request build complete
        if (
            this.state.finishedInitialLoad === false &&
            !this.props.inProgress &&
            !this.props.hydrationInProgress &&
            !this.props.listExperiencesInProgress &&
            !this.props.listExperiencesForEntitiesInProgress &&
            (prevProps.inProgress ||
                prevProps.hydrationInProgress ||
                prevProps.listExperiencesForEntitiesInProgress ||
                prevProps.listExperiencesInProgress)
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (
            this.props.hasAnnouncementPermissions !==
                prevProps.hasAnnouncementPermissions &&
            this.props.hasAnnouncementPermissions
        ) {
            this.listExperiences();
            if (this.props.albumsToPitch) {
                this.listExperiencesForEntities();
            }
        }

        if (
            this.props.hasAnnouncementPermissions &&
            this.props.albumsToPitch &&
            this.props.albumsToPitch !== prevProps.albumsToPitch
        ) {
            this.listExperiencesForEntities();
        }

        // Handling errors
        if (this.props.error !== prevProps.error) {
            this.setState({
                showError: this.props.error !== undefined,
            });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.loadMore();
        }
        if (
            this.props.quickUpdateExperienceStateInProgress !==
                prevProps.quickUpdateExperienceStateInProgress &&
            !this.props.quickUpdateExperienceStateInProgress
        ) {
            this.listExperiences();
            this.listExperiencesForEntities();
        }
    }

    render() {
        const showAnnouncementsTable =
            this.props.hasAnnouncementPermissions &&
            !!this.props.experiences &&
            this.props.experiences.length > 0 &&
            this.state.finishedInitialLoad &&
            this.props.experiencesForEntities &&
            !this.props.quickUpdateExperienceStateInProgress &&
            !this.props.listExperiencesForEntitiesInProgress;

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.genericError,
                            })}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {this.newReleasesHeader()}
                {this.newReleasesTableHeader()}
                {this.state.finishedInitialLoad &&
                (this.props.experiencesForEntities ||
                    !this.props.hasAnnouncementPermissions) &&
                !this.props.quickUpdateExperienceStateInProgress &&
                !this.props.listExperiencesForEntitiesInProgress &&
                !this.props.listExperiencesInProgress &&
                this.props.albumsToPitch ? (
                    this.newReleasesTable()
                ) : (
                    <Loading />
                )}
                {pitchHelpModal(
                    this.state.showHelpModal,
                    `${testIDPrefix}_PitchHelpModal`,
                    this.onHelpModalDismiss,
                    this.onHelpModalDismiss,
                    this.props.bundleMap,
                    () =>
                        window.open(
                            getLocalizedUrl(LocalizedUrls.PitchLearnMoreURL),
                            "_blank"
                        )
                )}
                <div style={{ paddingTop: rootStyles.spacers.large }} />

                {showAnnouncementsTable && (
                    <AnnouncementsTable
                        userAction={this.props.userAction}
                        catalog={this.props.catalog}
                        hydratingAsins={this.props.hydratingAsins}
                        failedAsins={this.props.failedAsins}
                        catalogBuildCompleted={this.props.catalogBuildCompleted}
                        hydrationInProgress={this.props.hydrationInProgress}
                        selectedArtistAsin={this.props.selectedArtistAsin}
                        pagePath={pagePath}
                        loadingInProgress={
                            !this.state.finishedInitialLoad ||
                            !this.props.experiences ||
                            this.props.quickUpdateExperienceStateInProgress ||
                            this.props.listExperiencesInProgress
                        }
                        isRefreshing={
                            !this.state.finishedInitialLoad ||
                            !this.props.experiences ||
                            this.props.quickUpdateExperienceStateInProgress ||
                            this.props.listExperiencesInProgress
                        }
                        announcements={this.props.experiences}
                        maximumNumberOfRows={5}
                        seeAllAnnouncements={this.seeAllAnnouncements}
                        history={this.props.history}
                        initiateDraft={this.props.initiateExperienceDraft}
                        teamId={this.props.teamId}
                        getExperience={this.props.getExperience}
                        updateExperienceState={
                            this.props.quickUpdateExperienceState
                        }
                        bundleMap={this.props.bundleMap}
                        id={`${testIDPrefix}_AnnouncementsTable`}
                    />
                )}
            </Container>
        );
    }

    private newReleasesHeader = () => {
        return (
            <CommonHeader
                title={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.NEWRELEASES_STRINGS,
                    stringId: stringIds.NewReleases.title,
                })}
                subtitle={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.NEWRELEASES_STRINGS,
                    stringId: stringIds.NewReleases.subtitle,
                })}
                hideDatePicker={true}
                id={`${testIDPrefix}_Header`}
            />
        );
    };

    private newReleasesTableHeader = () => {
        const helpElement = () => {
            return (
                <Icon
                    icon={IconsList.ic_help}
                    size={rootStyles.icons.tiny}
                    style={{
                        opacity: rootStyles.glass._4,
                        marginLeft: rootStyles.spacers.mini,
                        verticalAlign: "sub",
                    }}
                    onClick={this.onHelpModalShow}
                    id={`${testIDPrefix}_HelpIcon`}
                />
            );
        };

        return (
            <div style={{ paddingRight: 15, paddingLeft: 15 }}>
                <Row style={{ marginTop: rootStyles.spacers.base }}>
                    <styledTitle.h4>
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.NEWRELEASES_STRINGS,
                            stringId: stringIds.NewReleases.tableTitle,
                        })}
                    </styledTitle.h4>
                </Row>
                <Row>
                    <span
                        style={{
                            ...rootStyles.textStyles.secondary,
                        }}
                    >
                        {this.props.hasAnnouncementPermissions
                            ? getLocalizedString(this.props.bundleMap, {
                                  bundleId: bundleIds.NEWRELEASES_STRINGS,
                                  stringId:
                                      stringIds.NewReleases
                                          .tableSubtitleWithNewReleaseIntros,
                              })
                            : getLocalizedString(this.props.bundleMap, {
                                  bundleId: bundleIds.NEWRELEASES_STRINGS,
                                  stringId: stringIds.NewReleases.tableSubtitle,
                              })}
                        {helpElement()}
                    </span>
                </Row>
            </div>
        );
    };

    private newReleasesMissingRelease = (style?: React.CSSProperties) => {
        return (
            <Row
                style={{
                    marginTop: rootStyles.spacers.mini,
                    ...style,
                }}
            >
                <span style={rootStyles.textStyles.secondary}>
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.NEWRELEASES_STRINGS,
                        stringId:
                            stringIds.NewReleases.noReleasesMissingRelease,
                    })}{" "}
                    <span
                        style={{
                            color: rootStyles.colors.accent,
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            window.open(
                                getLocalizedUrl(
                                    LocalizedUrls.PitchReleaseIsMissingInstructionsURL
                                ),
                                "_blank"
                            )
                        }
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.NEWRELEASES_STRINGS,
                            stringId: stringIds.NewReleases.noReleasesLetUsKnow,
                        })}
                    </span>
                </span>
            </Row>
        );
    };

    private newReleasesTable = () => {
        if (
            !this.props.albumsToPitch ||
            (!this.props.experiencesForEntities &&
                this.props.hasAnnouncementPermissions)
        ) {
            return;
        }
        const isNewReleasesEmpty = this.props.albumsToPitch.length === 0;

        const hasPermissionsToPitch: boolean =
            this.props.userPermissions?.includes(TeamPermissions.PitchWrite) ===
                true ||
            this.props.isPrivileged ||
            false;

        const newReleases: TableRowProps[] = generateAlbumReleaseRows(
            this.props.albumsToPitch,
            this.props.experiencesForEntities,
            this.props.catalog,
            this.props.hydratingAsins,
            this.onAlbumReleaseSelected,
            this.onAnnouncementSelected,
            pitchAlbumRightElement,
            hasPermissionsToPitch,
            this.props.hasAnnouncementPermissions,
            this.props.bundleMap,
            testIDPrefix + "_newReleases"
        );

        const emptyNewReleasesTable = () => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    <div
                        style={{
                            width: NO_RELEASES_DIV_WIDTH,
                            maxWidth: NO_RELEASES_DIV_WIDTH,
                            textAlign: "center",
                        }}
                    >
                        <Row
                            style={{
                                justifyContent: "center",
                                marginTop: rootStyles.spacers.epic,
                            }}
                        >
                            <styledTitle.h4>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.NEWRELEASES_STRINGS,
                                    stringId:
                                        stringIds.NewReleases.noReleasesTitle,
                                })}
                            </styledTitle.h4>
                        </Row>
                        <Row
                            style={{
                                justifyContent: "center",
                                marginTop: rootStyles.spacers.large,
                            }}
                        >
                            <MediumSolidButton
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.NEWRELEASES_STRINGS,
                                        stringId:
                                            stringIds.NewReleases
                                                .noReleasesLearnMoreButton,
                                    }
                                )}
                                containerStyle={{ flex: 1 }}
                                onClick={this.onHelpModalShow}
                                id={`${testIDPrefix}_NoReleasesLearnMore`}
                            />
                        </Row>
                    </div>
                </div>
            );
        };

        return (
            <Row>
                <Col
                    style={{
                        padding: 0,
                        paddingTop: rootStyles.spacers.small,
                    }}
                >
                    {isNewReleasesEmpty ? (
                        <>
                            {emptyNewReleasesTable()}
                            {this.newReleasesMissingRelease({
                                justifyContent: "center",
                            })}
                        </>
                    ) : (
                        <>
                            <Table
                                rows={newReleases}
                                scrolling={true}
                                loadMore={this.loadMore}
                                footer={
                                    this.props.albumsToPitch.length <=
                                    this.state.loadedCount ? (
                                        <div
                                            style={{
                                                height: rootStyles.spacers.huge,
                                            }}
                                        />
                                    ) : (
                                        <IncrementalLoading />
                                    )
                                }
                                id={`${testIDPrefix}_AlbumsToPitchTable`}
                                showIndex={false}
                            />

                            {/* divider */}
                            <Row
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: 1,
                                    borderTopColor:
                                        rootStyles.glassColors.primary3,
                                    marginLeft: rootStyles.spacers.base,
                                    maxWidth: 128,
                                }}
                            />

                            {this.newReleasesMissingRelease({
                                justifyContent: "start",
                                paddingLeft: rootStyles.spacers.base,
                            })}
                        </>
                    )}
                </Col>
            </Row>
        );
    };

    private onHelpModalShow = () => {
        this.setState({ showHelpModal: true });
    };

    private onHelpModalDismiss = () => {
        this.setState({ showHelpModal: false });
    };

    private onAlbumReleaseSelected = (albumRelease: albumRelease) => {
        this.props.selectAlbumRelease(albumRelease);
        this.props.history.push(paths.pitch);
    };

    private seeAllAnnouncements = () => {
        this.props.history?.push(paths.announcements);
    };

    private onAnnouncementSelected = (albumRelease: albumRelease) => {
        if (!albumRelease.globalAsin) {
            return;
        }
        this.props.selectAlbumRelease(albumRelease);

        this.startAnnouncementCreation(
            albumRelease.globalAsin,
            ExperienceEntityType.ALBUM
        );
    };

    private getAlbumReleases = () => {
        const getAlbumsToPitchPayload: listAlbumsToPitchPayload = {
            artistAsin: this.props.selectedArtistAsin,
            teamId: this.props.teamId,
            requestPath: pagePath,
            locale: this.props.userLocale,
        };
        this.props.getAlbumReleases(getAlbumsToPitchPayload);
    };

    private listExperiences = () => {
        const listExperiencesPayload: ListExperiencesPayload = {
            artistAsin: this.props.selectedArtistAsin,
            experienceTypeFilter: [ExperienceType.ANNOUNCEMENT],
            teamId: this.props.teamId,
            requestPath: pagePath,
        };
        this.props.listExperiences(listExperiencesPayload);
    };

    private listExperiencesForEntities = () => {
        const entities: ExperienceAssociatedEntity[] = _.map(
            this.props.albumsToPitch,
            (album) => {
                return {
                    entityType: ExperienceEntityType.ALBUM,
                    identifier: album.globalAsin || "",
                };
            }
        );

        const listExperiencesForEntitiesPayload: ListExperiencesForEntitiesPayload =
            {
                publishingArtistAsin: this.props.selectedArtistAsin,
                entities: entities,
                experienceTypeFilter: [ExperienceType.ANNOUNCEMENT],
                teamId: this.props.teamId,
                requestPath: pagePath,
            };
        this.props.listExperiencesForEntities(
            listExperiencesForEntitiesPayload
        );
    };

    private startAnnouncementCreation = (
        asin: string,
        entityType: ExperienceEntityType
    ): void => {
        // Using the entity ASIN as a temporary local ID for a new draft
        let experienceDraft = this.props.experienceDrafts.get(asin);

        if (!experienceDraft) {
            const experienceEntity: ExperienceAssociatedEntity = {
                entityType: entityType,
                identifier: asin,
            };

            const album = this.props.catalog.get(asin) as album;

            const newExperienceDraft: AnnouncementDraft =
                AnnouncementUtil.loadNewAnnouncementDraft(
                    album,
                    asin,
                    this.props.selectedArtistAsin,
                    [experienceEntity]
                );

            const initiateExperiencePayload: InitiateExperiencePayload = {
                experienceDraft: newExperienceDraft,
            };

            this.props.initiateExperienceDraft(initiateExperiencePayload);
        }

        this.props.history.push(`${paths.announcementDetails}/${asin}`);
    };

    private loadMore = () => {
        const nextCount: number = hydrationHelpers.hydrateAsinsBatch(
            this.state.loadedCount,
            _.map(this.props.albumsToPitch, (albumToPitch) => {
                return {
                    asin: albumToPitch.titlesetAsin,
                    globalAsin: albumToPitch.globalAsin,
                };
            }),
            this.props.hydrateAsins,
            CatalogItemType.Albums,
            this.props.userLocale
        );

        this.setState({ loadedCount: nextCount });
    };
}

function mapStateToProps(state: RootState): StateProps {
    return newReleasesSelector(state, pagePath);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        selectAlbumRelease: (payload: albumRelease) =>
            dispatch(pitchFormActions.selectAlbumRelease(payload)),
        getAlbumReleases: (payload: listAlbumsToPitchPayload) =>
            dispatch(pitchActions.listAlbumsToPitch(payload)),
        listExperiences: (payload: ListExperiencesPayload) =>
            dispatch(artistStudioActions.listExperiences(payload)),
        listExperiencesForEntities: (
            payload: ListExperiencesForEntitiesPayload
        ) => dispatch(artistStudioActions.listExperiencesForEntities(payload)),
        initiateExperienceDraft: (payload: InitiateExperiencePayload) =>
            dispatch(artistStudioActions.initiateExperience(payload)),
        getExperience: (payload: GetExperiencePayload) =>
            dispatch(artistStudioActions.getExperience(payload)),
        quickUpdateExperienceState: (payload: UpdateExperienceStatePayload) =>
            dispatch(artistStudioActions.quickUpdateExperienceState(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NewReleasesScreen)
);
