import { createAction } from "redux-ts";
import {
    hydrateTwitchChannelPayload,
    unlinkTwitchChannelPayload,
    linkTwitchChannelPayload,
    getTwitchChannelPayload,
} from "../../models";
import { channel } from "../../models";

export const setLinkedChannel = createAction<channel>(
    "TWITCH::SET_LINKED_CHANNEL"
);
export const clearState = createAction("TWITCH::TWITCH_CLEAR_STATE");

export const unlinkTwitchChannel = createAction<unlinkTwitchChannelPayload>(
    "TWITCH::UNLINK_TWITCH_CHANNEL"
);
export const unlinkTwitchChannelInProgress = createAction<boolean>(
    "TWITCH::UNLINK_TWITCH_CHANNEL_IN_PROGRESS"
);
export const unlinkTwitchChannelCompleted = createAction<boolean>(
    "TWITCH::UNLINK_TWITCH_CHANNEL_COMPLETED"
);

export const linkTwitchChannel = createAction<linkTwitchChannelPayload>(
    "TWITCH::LINK_TWITCH_CHANNEL"
);
export const linkTwitchChannelInProgress = createAction<boolean>(
    "TWITCH::LINK_TWITCH_CHANNEL_IN_PROGRESS"
);
export const linkTwitchChannelCompleted = createAction<boolean>(
    "TWITCH::LINK_TWITCH_CHANNEL_COMPLETED"
);

export const hydrateTwitchChannel = createAction<hydrateTwitchChannelPayload>(
    "TWITCH::HYDRATE_TWITCH_CHANNEL"
);
export const hydrateTwitchChannelInProgress = createAction<boolean>(
    "TWITCH::HYDRATE_TWITCH_CHANNEL_IN_PROGRESS"
);

export const getTwitchChannel = createAction<getTwitchChannelPayload>(
    "TWITCH::GET_TWITCH_CHANNEL"
);
export const getTwitchChannelInProgress = createAction<boolean>(
    "TWITCH::GET_TWITCH_CHANNEL_IN_PROGRESS"
);
