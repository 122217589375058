import { artistStudioActions } from "../actions";
import {
    baseCatalogItem,
    Experience,
    ExperienceDraft,
    ExperiencesPage,
    ExperienceType,
} from "../../models";
import { Action } from "redux-ts";
import _ from "lodash";
import { AnnouncementUtil, ExperienceUtil } from "../../utils";

export type ArtistStudioState = Readonly<{
    listExperiencesInProgress: boolean; // controls loading spinner
    listExperiencePaginationToken?: string;
    listExperiencesForEntitiesInProgress: boolean; // controls loading spinner
    quickUpdateExperienceStateInProgress: boolean; // controls loading after state update from actions menu
    experiences?: Experience[]; // populated from the ListExperiences call
    experiencesToEntities?: Map<string, ExperiencesPage>; // holds entity ASIN to Experiences
    // Holds a map of ids to drafts, this allows multiple drafts to be in the global state simultaniously
    experienceDrafts: Map<string, ExperienceDraft>;
}>;

export const initialArtistStudioState: ArtistStudioState = {
    listExperiencesInProgress: false,
    listExperiencesForEntitiesInProgress: false,
    quickUpdateExperienceStateInProgress: false,
    experienceDrafts: new Map<string, ExperienceDraft>(),
};

export const artistStudioReducer = (
    state: ArtistStudioState = initialArtistStudioState,
    action: Action
) => {
    let newExperienceDrafts: Map<string, ExperienceDraft>;
    let newExperienceDraft: ExperienceDraft | undefined;
    let currentExperienceDraft: ExperienceDraft | undefined;

    switch (action.type) {
        case artistStudioActions.listExperiencesInProgress.type:
            return {
                ...state,
                listExperiencesInProgress: action.payload,
            };
        case artistStudioActions.listExperiencesCompleted.type:
            let newExperiences: Experience[] | undefined;
            if (state.experiences && action.payload.experiences) {
                newExperiences = state.experiences.concat(action.payload.experiences);
            } else {
                newExperiences = action.payload.experiences;
            }
            return {
                ...state,
                experiences: newExperiences,
                listExperiencePaginationToken: action.payload.paginationToken
            };
        case artistStudioActions.listExperiencesForEntitiesInProgress.type:
            return {
                ...state,
                listExperiencesForEntitiesInProgress: action.payload,
            };
        case artistStudioActions.listExperiencesForEntitiesCompleted.type:
            return {
                ...state,
                experiencesToEntities: action.payload,
            };
        case artistStudioActions.initiateExperience.type:
            if (state.experienceDrafts.has(action.payload.experienceDraft.experience.experienceId)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);

            newExperienceDrafts.set(action.payload.experienceDraft.experience.experienceId, action.payload.experienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.getExperienceInProgress.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                getInProgress: action.payload.inProgress,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.getExperienceCompleted.type:
            if (!state.experienceDrafts.has(action.payload.experience.experienceId)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);

            newExperienceDrafts.set(action.payload.experience.experienceId, action.payload);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.uploadToCASInProgress.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }
            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                uploadToCASInProgress: action.payload.inProgress,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.uploadToCASCompleted.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            const newAssets =
                ExperienceUtil.putExperienceAssetIntoExperienceAssetList(
                    currentExperienceDraft.assets,
                    action.payload.asset
                );

            newExperienceDraft = {
                ...currentExperienceDraft,
                assets: newAssets,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.uploadToCASSuccess.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                uploadToCASSuccess: action.payload.success,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);
            
            return {
                ...state,
                experienceDrafts: newExperienceDrafts
            }
        case artistStudioActions.updateExperienceAssetsInProgress.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                updateAssetsInProgress: action.payload.inProgress,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.updateExperienceMetadataInProgress.type:
                if (!state.experienceDrafts.has(action.payload.id)) {
                    return state;
                }
    
                newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
                currentExperienceDraft = newExperienceDrafts.get(action.payload.id);
    
                if (!currentExperienceDraft) {
                    return state;
                }
    
                newExperienceDraft = {
                    ...currentExperienceDraft,
                    updateMetadataInProgress: action.payload.inProgress,
                };
    
                newExperienceDrafts.set(action.payload.id, newExperienceDraft);
    
                return {
                    ...state,
                    experienceDrafts: newExperienceDrafts,
                };
        case artistStudioActions.updateExperienceStateInProgress.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                updateStateInProgress: action.payload.inProgress,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.updateExperienceAssetsCompleted.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                updateAssetsSuccess: action.payload.success,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.updateExperienceMetadataCompleted.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                updateMetadataSuccess: action.payload.success,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.updateExperienceStateCompleted.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                updateStateSuccess: action.payload.success,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.compositeUpdateExperienceInProgress.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                compositeUpdateInProgress: action.payload.inProgress,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);
            
            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.compositeUpdateExperienceCompleted.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                compositeUpdateSuccess: action.payload.success,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);
            
            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.quickUpdateExperienceStateInProgress.type:
            console.log(action.payload);
            return {
                ...state,
                quickUpdateExperienceStateInProgress: action.payload
            }
        case artistStudioActions.createExperienceInProgress.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                createInProgress: action.payload.inProgress,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.createExperienceCompleted.type:
            if (!state.experienceDrafts.has(action.payload.id)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);
            currentExperienceDraft = newExperienceDrafts.get(action.payload.id);

            if (!currentExperienceDraft) {
                return state;
            }

            newExperienceDraft = {
                ...currentExperienceDraft,
                createSuccess: action.payload.success,
            };

            newExperienceDrafts.set(action.payload.id, newExperienceDraft);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.updateLocalDraft.type:
            if (
                !state.experienceDrafts.has(
                    action.payload.experience.experienceId
                )
            ) {
                return state;
            }
            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);

            newExperienceDrafts.set(
                action.payload.experience.experienceId,
                action.payload
            );

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.clearDraftFromDraftsMap.type:
            if (!state.experienceDrafts.has(action.payload)) {
                return state;
            }

            newExperienceDrafts = _.cloneDeep(state.experienceDrafts);

            newExperienceDrafts.delete(action.payload);

            return {
                ...state,
                experienceDrafts: newExperienceDrafts,
            };
        case artistStudioActions.clearArtistStudioState.type:
            return initialArtistStudioState;
        default:
            return state;
    }
};
