import { createSelector } from "reselect";
import { RootState } from "../reducers";
import {
    getBundleMap,
    getCatalog,
    getSelectedArtistAsin,
    getUserInfo,
} from "./commonSelectors";
import { getSelectedTeamSelector } from "./teamManagementSelectors";

export const blueprintUrlSelector = (state: RootState) =>
    state.artistControl.blueprintsUrl;
const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);

// Gets the currently selected team id or privileged id if applicable
export const blueprintScreenSelector = createSelector(
    [
        blueprintUrlSelector,
        getUserInfo,
        getErrorFromStore,
        getSelectedTeamSelector,
        getSelectedArtistAsin,
        getCatalog,
        getBundleMap,
    ],
    (
        blueprintsUrl,
        isSignedIn,
        error,
        selectedTeam,
        selectedArtistAsin,
        catalog,
        bundleMap
    ) => {
        return {
            blueprintsUrl,
            isSignedIn,
            error,
            teamId: selectedTeam.teamId,
            artist: selectedArtistAsin
                ? catalog.get(selectedArtistAsin)
                : undefined,
            bundleMap: bundleMap,
        };
    }
);
