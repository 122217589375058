import _ from "lodash";
import * as React from "react";
import {
    BrowserRouter as Router,
    NavLink,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import { webFontSizes } from "../styles/styles";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as rootStyles from "../styles";
import { colors, containerStyles, glassColors, textStyles } from "../styles";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { push as Menu } from "react-burger-menu";
import styled from "styled-components";

import {
    artistSearchActions,
    clientMetricsActions,
    cookieConsentSelector,
    globalNotificationsActions,
    oAuthActions,
    opsMetricsActions,
    showHypeDeckSelector,
    outageStatusActions,
    RootState,
    telemetryActions,
    userActions,
} from "../../store";
import * as Reporting from "./reports";
import SettingsScreen from "./profile/SettingsScreen";
import * as Claims from "./claim";
import {
    buildUIPageViewPayload,
    claimPathsPrefix,
    FeatureName,
    getAsinFromPath,
    getLocalizedString,
    getPageIdFromPath,
    initializeCountryCodeTranslater,
    isHideNavBarPath,
    LARGE_SCREEN_WIDTH_THRESHOLD,
    markFeatureAsSeen,
    paths,
    retrieveSeenFeatures,
    TOKEN_REFRESH_INTERVAL,
    translateOutageStatusForMessage,
} from "../../utils";
import SelectArtistScreen from "./manage/SelectArtistScreen";
import CurrentMembersScreen from "./team/CurrentMembersScreen";
import InviteTeamMemberScreen from "./team/InviteTeamMemberScreen";
import ModifyTeamMemberScreen from "./team/ModifyTeamMemberScreen";
import { signOut } from "../../service/common";
import {
    Header,
    Icon,
    IconsList,
    Loading,
    MediumGlassButton,
    OutageBanner,
} from "../components";

import { navBarStyles } from "../styles/navBarStyles";
import { bundleIds, ImageList, stringIds } from "../../assets";
import AcceptInviteScreen from "./team/AcceptInviteScreen";
import {
    BundleMap,
    clientMetricsPayload,
    CookieConsentString,
    cookieModalText,
    localStorageStrings,
    ModalRenderFunction,
    outageStatusEntry,
    recentlyAddedToPlaylistTrack,
    settings,
} from "../../models";
import TermsAndConditionsScreen from "./profile/TermsAndConditionsScreen";
import FirstLoginScreen from "./auth/FirstLoginScreen";
import OpenSourceAttributionScreen from "./profile/OpenSourceAttributionScreen";
import ProfileScreen from "./profile/ProfileScreen";
import { CustomScrollbar } from "../styles/CustomScrollbar";
import ArtistImgDropdown from "../components/common/ArtistImgDropdown";
import { rootNavigationSelector } from "../../store/selectors/rootNavigationSelectors";
import BlueprintsScreen from "./profile/BlueprintsScreen";
import { ReportArtistIssueScreen } from "./artistdisambiguation";
import NewReleasesScreen from "./newreleases/NewReleasesScreen";
import PitchScreen from "./pitch/PitchScreen";
import AnnouncementDetailPage from "./artistStudio/AnnouncementDetailPage";
import AnnouncementsSeeAllScreen from "./artistStudio/AnnouncementsSeeAllScreen";
import AccountDetailsScreen from "./profile/AccountDetailsScreen";
import MerchOverviewScreen from "./merch/MerchOverviewScreen";
import MoDLandingScreen from "./merch/MoDLandingScreen";
import ManualSearchScreen from "./merch/ManualSearchScreen";
import GlobalToastController from "../components/globalNotifications/GlobalToastController";
import GlobalModalController from "../components/globalNotifications/GlobalModalController";
import SeeAllMerchScreen from "./merch/SeeAllMerchScreen";
import { bundleMapLoadInProgress } from "../../store/selectors/commonSelectors";
import ClaimRequesterTypeScreen from "./claim/ClaimRequesterTypeScreen";
import ClaimRequestInviteScreen from "./claim/ClaimRequestInviteScreen";
import ClaimVendorLabelLearnMoreScreen from "./claim/ClaimVendorLabelLearnMore";
import PromoCardScreen from "./promoCard/PromoCardScreen";
import { CookieConsentModal } from "../components/common/modals/CookieConsentModal";
import { getLocalizedUrl } from "../../utils/localization/LocalizedUrls";
import { LocalizedUrls } from "@amzn/ziggy-asset";
import DeeplinkResolverScreen from "./DeeplinkResolverScreen";

type State = {
    navigationOpen: boolean;
    width: number;
    seenFeatures: FeatureName[];
};

type Props = {
    isPrivileged?: boolean;
    selectedArtist?: string;
    currentPath?: string;
    doRemoteSearch?: boolean;
    settings: settings;
    refreshInProgress?: boolean;
    userLocale: string;
    cookieConsentSetting?: string;
    cookieModalText?: cookieModalText;
    recentlyAddedToPlaylistData: recentlyAddedToPlaylistTrack[];
    outageStatus?: outageStatusEntry;
    showMerchTab: boolean;
    showHypeDeck: boolean;
    bundleMap: BundleMap;
    bundleMapLoadInProgress: boolean;
    hasCookieConsentFeaturePermission: boolean;
};

type DispatchProps = {
    registerUser: () => void;
    getCustomerSupportData: () => void;
    refreshOutageStatus: () => void;
    updatePrevPath: (path: string) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    stopTimedEmitterAndEmit: () => void;
    requestModal: (payload: ModalRenderFunction) => void;
    updateCookieSetting: (payload: CookieConsentString) => void;
};

const RootNavigationPrefix = "RootNavigation";
const minSideBarWidth = 240;

export class RootNavigationController extends React.Component<
    Props & DispatchProps,
    State
> {
    constructor(props: any) {
        super(props);
        this.state = {
            navigationOpen:
                window.innerWidth >= LARGE_SCREEN_WIDTH_THRESHOLD
                    ? true
                    : false,
            width: window.innerWidth,
            seenFeatures: retrieveSeenFeatures(),
        };

        // If we are redirecting from web.artists to artists.amazon.com,
        // just replace the url, and dont do anything else
        if (this.redirect()) {
            return;
        }

        this.props.refreshOutageStatus();
        setInterval(this.props.refreshOutageStatus, TOKEN_REFRESH_INTERVAL);
    }

    componentDidMount() {
        document.title = "Amazon Music for Artists";
        initializeCountryCodeTranslater();
        this.props.registerUser();
        this.props.getCustomerSupportData();
        window.addEventListener("resize", this.updateWidth);
        window.addEventListener("beforeunload", this.emitOpsMetricsBeforeClose);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            prevProps.currentPath &&
            prevProps.currentPath !== this.props.currentPath
        ) {
            if (this.props.currentPath) {
                const pageId = getPageIdFromPath(this.props.currentPath);
                if (pageId) {
                    this.props.sendClientMetrics(
                        buildUIPageViewPayload(
                            getPageIdFromPath(this.props.currentPath),
                            getAsinFromPath(this.props.currentPath) ??
                                this.props.selectedArtist
                        )
                    );
                }
            }
            this.props.updatePrevPath(prevProps.currentPath);
        }
    }

    redirect() {
        if (window.location.hostname === "web.artists.amazon.com") {
            window.location.replace(window.location.href.replace("web.", ""));
            return true;
        }
        return false;
    }

    public render() {
        let showNav = false;

        let mustAcceptTerms: boolean =
            this.props.isPrivileged === false &&
            this.props.settings.acceptedTermsSetting !== true;
        let inOobePath = _.includes(this.props.currentPath, paths.oobe);

        let showCookieConsentModal: boolean =
            !this.props.refreshInProgress &&
            this.props.settings.acceptedTermsSetting === true &&
            this.props.hasCookieConsentFeaturePermission &&
            !this.state.seenFeatures.includes(FeatureName.CookieConsent) &&
            this.props.cookieConsentSetting === CookieConsentString.UNKNOWN &&
            this.props.cookieModalText !== undefined;

        const outageBannerMessage =
            this.props.outageStatus?.message &&
            translateOutageStatusForMessage(this.props.outageStatus.message);
        const outageBannerHeight = outageBannerMessage
            ? this.calculateOutageBannerHeight(
                  outageBannerMessage,
                  this.state.width
              )
            : 0;

        /* Hiding Navigation bar when user is in some paths due to design spec or if the terms haven't been
           been updated yet so it hides when user opens terms and conditions and hasn't accepted terms */
        if (!isHideNavBarPath(this.props.currentPath) && !mustAcceptTerms) {
            showNav = true;
        }

        // doRemoteSearch is true if user is privileged or is member of a vendor/label team
        // if user goes directly to user a claim path, it gets redirected to home
        if (
            this.props.doRemoteSearch &&
            _.includes(this.props.currentPath, claimPathsPrefix)
        ) {
            window.location.replace(paths.home);
        }

        if (
            this.props.bundleMapLoadInProgress ||
            this.props.refreshInProgress
        ) {
            return (
                <div id={RootNavigationPrefix + "-FullPageLoading"}>
                    <Loading />
                </div>
            );
        }
        // User hasn't accepted terms and conditions
        let redirectToOOBE = false;

        /* User should be redirected to OOBE page if user is not privileged (super user)
            and haven't accepted terms but user shouldn't be redirected if it's already
            in oobe page or in terms and conditions page
        */
        if (
            !inOobePath &&
            !_.includes(window.location.pathname, paths.termsAndConditions) &&
            mustAcceptTerms
        ) {
            redirectToOOBE = true;
        }

        const storedDeeplinkURL = localStorage.getItem(
            localStorageStrings.deeplinkUrl
        );
        let goToDeeplink = false;
        let path = window.location.pathname;
        if (storedDeeplinkURL) {
            localStorage.removeItem(localStorageStrings.deeplinkUrl);
            goToDeeplink = true;
            const urlPath = new URL(storedDeeplinkURL);
            path = urlPath.pathname + urlPath.search;
        }

        return (
            <Container
                id={RootNavigationPrefix + "Container.Menu"}
                fluid={true}
                style={{ padding: 0 }}
            >
                <GlobalToastController />
                <CustomScrollbar />
                <Router>
                    <GlobalModalController />
                    {!this.state.navigationOpen && showNav && (
                        <NavHeaderBackground top={outageBannerHeight}>
                            <NavHeaderOverlay />
                            <ArtistImgDropdown />
                        </NavHeaderBackground>
                    )}
                    <CookieConsentModal
                        isVisible={showCookieConsentModal}
                        cookieModalText={this.props.cookieModalText}
                        onAcceptCookie={this.onAcceptCookie}
                        onRejectCookie={this.onRejectCookie}
                        onCustomizeCookie={this.onCustomizeCookie}
                    ></CookieConsentModal>
                    {outageBannerMessage && (
                        <Row style={outageBannerRowStyle(outageBannerHeight)}>
                            <OutageBannerContainer>
                                <OutageBanner
                                    text={outageBannerMessage}
                                    height={outageBannerHeight}
                                />
                            </OutageBannerContainer>
                        </Row>
                    )}
                    <Row
                        className={
                            this.state.width < LARGE_SCREEN_WIDTH_THRESHOLD
                                ? "topOverlayPresent"
                                : ""
                        }
                    >
                        {showNav ? (
                            <Col
                                lg={showNav ? 3 : 0}
                                style={containerStyles.navigationContainerStyle}
                            >
                                <Menu
                                    width={minSideBarWidth}
                                    onStateChange={(state) =>
                                        this.onNavigationChange(state.isOpen)
                                    }
                                    isOpen={this.state.navigationOpen}
                                    noOverlay={
                                        this.state.width >=
                                        LARGE_SCREEN_WIDTH_THRESHOLD
                                    }
                                    customBurgerIcon={
                                        !this.state.navigationOpen &&
                                        this.state.width <
                                            LARGE_SCREEN_WIDTH_THRESHOLD &&
                                        this.getCustomBurgerIcon()
                                    }
                                    styles={navBarStyles(outageBannerHeight)}
                                    customCrossIcon={
                                        this.state.navigationOpen &&
                                        this.state.width <
                                            LARGE_SCREEN_WIDTH_THRESHOLD &&
                                        this.getCustomCrossIcon()
                                    }
                                    disableCloseOnEsc
                                >
                                    <Nav
                                        style={{
                                            height: "100% ",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        onClick={() =>
                                            this.onNavigationChange(
                                                false ||
                                                    window.innerWidth >=
                                                        LARGE_SCREEN_WIDTH_THRESHOLD
                                            )
                                        }
                                    >
                                        <div>
                                            <Nav.Item>
                                                <NavLink
                                                    activeStyle={
                                                        navLinkSelectedState
                                                    }
                                                    to={paths.artistSelect}
                                                    style={
                                                        textStyles.navigationLinkTextStyle
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            ImageList.logo_sidebar_svg
                                                        }
                                                        height={30}
                                                        width={188}
                                                    />
                                                </NavLink>
                                            </Nav.Item>
                                            {this.props.selectedArtist ? (
                                                <div>
                                                    <Nav.Item
                                                        style={
                                                            textStyles.navigationLabelStyle
                                                        }
                                                    >
                                                        <span>
                                                            {getLocalizedString(
                                                                this.props
                                                                    .bundleMap,
                                                                {
                                                                    bundleId:
                                                                        bundleIds.NAVIGATION_STRINGS,
                                                                    stringId:
                                                                        stringIds
                                                                            .Navigation
                                                                            .dashboardTitle,
                                                                }
                                                            )}
                                                        </span>
                                                    </Nav.Item>
                                                    <Nav.Item
                                                        style={
                                                            textStyles.navigationLinkStyle
                                                        }
                                                    >
                                                        <NavLink
                                                            activeStyle={
                                                                navLinkSelectedState
                                                            }
                                                            to={paths.reports}
                                                            style={
                                                                textStyles.navigationLinkTextStyle
                                                            }
                                                        >
                                                            {getLocalizedString(
                                                                this.props
                                                                    .bundleMap,
                                                                {
                                                                    bundleId:
                                                                        bundleIds.REPORTS_STRINGS,
                                                                    stringId:
                                                                        stringIds
                                                                            .Reports
                                                                            .reportsOverviewTitle,
                                                                }
                                                            )}
                                                        </NavLink>
                                                    </Nav.Item>
                                                    <Nav.Item
                                                        style={
                                                            textStyles.navigationLinkStyle
                                                        }
                                                    >
                                                        <NavLink
                                                            activeStyle={
                                                                navLinkSelectedState
                                                            }
                                                            to={paths.songs}
                                                            style={
                                                                textStyles.navigationLinkTextStyle
                                                            }
                                                        >
                                                            {getLocalizedString(
                                                                this.props
                                                                    .bundleMap,
                                                                {
                                                                    bundleId:
                                                                        bundleIds.REPORTS_STRINGS,
                                                                    stringId:
                                                                        stringIds
                                                                            .Reports
                                                                            .songsTitle,
                                                                }
                                                            )}
                                                        </NavLink>
                                                    </Nav.Item>
                                                    <Nav.Item
                                                        style={
                                                            textStyles.navigationLinkStyle
                                                        }
                                                    >
                                                        <NavLink
                                                            activeStyle={
                                                                navLinkSelectedState
                                                            }
                                                            to={paths.fans}
                                                            style={
                                                                textStyles.navigationLinkTextStyle
                                                            }
                                                        >
                                                            {getLocalizedString(
                                                                this.props
                                                                    .bundleMap,
                                                                {
                                                                    bundleId:
                                                                        bundleIds.REPORTS_STRINGS,
                                                                    stringId:
                                                                        stringIds
                                                                            .Reports
                                                                            .fansTitle,
                                                                }
                                                            )}
                                                        </NavLink>
                                                    </Nav.Item>
                                                    <Nav.Item
                                                        style={
                                                            textStyles.navigationLinkStyle
                                                        }
                                                    >
                                                        <NavLink
                                                            activeStyle={
                                                                navLinkSelectedState
                                                            }
                                                            to={paths.voice}
                                                            style={
                                                                textStyles.navigationLinkTextStyle
                                                            }
                                                        >
                                                            {getLocalizedString(
                                                                this.props
                                                                    .bundleMap,
                                                                {
                                                                    bundleId:
                                                                        bundleIds.REPORTS_STRINGS,
                                                                    stringId:
                                                                        stringIds
                                                                            .Reports
                                                                            .voiceTitle,
                                                                }
                                                            )}
                                                        </NavLink>
                                                    </Nav.Item>
                                                    <Nav.Item
                                                        style={
                                                            textStyles.navigationLinkStyle
                                                        }
                                                    >
                                                        <NavLink
                                                            activeStyle={
                                                                navLinkSelectedState
                                                            }
                                                            to={
                                                                paths.programming
                                                            }
                                                            style={
                                                                textStyles.navigationLinkTextStyle
                                                            }
                                                        >
                                                            {getLocalizedString(
                                                                this.props
                                                                    .bundleMap,
                                                                {
                                                                    bundleId:
                                                                        bundleIds.REPORTS_STRINGS,
                                                                    stringId:
                                                                        stringIds
                                                                            .Reports
                                                                            .programmingTitle,
                                                                }
                                                            )}
                                                        </NavLink>
                                                    </Nav.Item>
                                                </div>
                                            ) : null}
                                            {(this.props.selectedArtist ||
                                                !this.props.doRemoteSearch) && (
                                                <Nav.Item
                                                    style={
                                                        textStyles.navigationLabelStyle
                                                    }
                                                >
                                                    <span>
                                                        {getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.NAVIGATION_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .Navigation
                                                                        .artistToolsTitle,
                                                            }
                                                        )}
                                                    </span>
                                                </Nav.Item>
                                            )}
                                            {/* {
                                                (!this.props.isPrivileged && this.props.selectedArtist) ?
                                                    <Nav.Item style={textStyles.navigationLinkStyle}>
                                                        <NavLink activeStyle={{ color: colors.accent }} to={paths.currentTeamMembers} style={textStyles.navigationLinkTextStyle}>
                                                            {getLocalizedString(stringIds.ManageCurrentTeam.manageTeamHeader)}
                                                        </NavLink>
                                                    </Nav.Item> : null
                                            } */}
                                            {/* <Nav.Item style={textStyles.navigationLinkStyle}>
                                                <NavLink activeStyle={{ color: colors.accent }} to={paths.artistSelect} style={textStyles.navigationLinkTextStyle}>
                                                    {getLocalizedString(stringIds.ManageCurrentTeam.selectArtistTitle)}
                                                </NavLink>
                                            </Nav.Item> */}

                                            {this.props.selectedArtist ? (
                                                <Nav.Item
                                                    style={
                                                        textStyles.navigationLinkStyle
                                                    }
                                                >
                                                    <NavLink
                                                        activeStyle={{
                                                            color: colors.accent,
                                                        }}
                                                        to={paths.profile}
                                                        style={
                                                            textStyles.navigationLinkTextStyle
                                                        }
                                                    >
                                                        {getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.ACCOUNT_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .Account
                                                                        .overview,
                                                            }
                                                        )}
                                                    </NavLink>
                                                </Nav.Item>
                                            ) : null}
                                            {!this.props.doRemoteSearch && (
                                                <Nav.Item
                                                    style={
                                                        textStyles.navigationLinkStyle
                                                    }
                                                >
                                                    <NavLink
                                                        activeStyle={
                                                            navLinkSelectedState
                                                        }
                                                        to={paths.requesterType}
                                                        style={
                                                            textStyles.navigationLinkTextStyle
                                                        }
                                                    >
                                                        {getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.CLAIMANARTIST_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .ClaimAnArtist
                                                                        .title,
                                                            }
                                                        )}
                                                    </NavLink>
                                                </Nav.Item>
                                            )}
                                            {this.props.selectedArtist ? (
                                                <Nav.Item
                                                    style={
                                                        textStyles.navigationLinkStyle
                                                    }
                                                >
                                                    <NavLink
                                                        activeStyle={{
                                                            color: colors.accent,
                                                        }}
                                                        to={paths.newReleases}
                                                        style={
                                                            textStyles.navigationLinkTextStyle
                                                        }
                                                    >
                                                        {getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.NEWRELEASES_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .NewReleases
                                                                        .title,
                                                            }
                                                        )}
                                                    </NavLink>
                                                </Nav.Item>
                                            ) : null}
                                            {this.props.selectedArtist &&
                                            this.props.showMerchTab ? (
                                                <Nav.Item
                                                    style={
                                                        textStyles.navigationLinkStyle
                                                    }
                                                >
                                                    <NavLink
                                                        activeStyle={{
                                                            color: colors.accent,
                                                        }}
                                                        to={paths.merch}
                                                        style={
                                                            textStyles.navigationLinkTextStyle
                                                        }
                                                    >
                                                        {getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.MERCHCURATION_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .Merch
                                                                        .Curation
                                                                        .Merch,
                                                            }
                                                        )}
                                                    </NavLink>
                                                </Nav.Item>
                                            ) : null}
                                            {this.props.selectedArtist &&
                                            this.props.showHypeDeck ? (
                                                <Nav.Item
                                                    style={
                                                        textStyles.navigationLinkStyle
                                                    }
                                                >
                                                    <NavLink
                                                        activeStyle={{
                                                            color: colors.accent,
                                                        }}
                                                        to={paths.promoCard}
                                                        style={
                                                            textStyles.navigationLinkTextStyle
                                                        }
                                                    >
                                                        {getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.PROMO_CARD_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .PromoCard
                                                                        .pageTitle,
                                                            }
                                                        )}
                                                    </NavLink>
                                                </Nav.Item>
                                            ) : null}
                                        </div>
                                        {/* {
                                            (!this.props.isPrivileged && this.props.selectedArtist) ?
                                                <Nav.Item style={textStyles.navigationLinkStyle}>
                                                    <NavLink activeStyle={{ color: colors.accent }} to={paths.currentTeamMembers} style={textStyles.navigationLinkTextStyle}>
                                                        {getLocalizedString(stringIds.ManageCurrentTeam.manageTeamHeader)}
                                                    </NavLink>
                                                </Nav.Item> : null
                                        } */}
                                        {/* <Nav.Item style={textStyles.navigationLinkStyle}>
                                            <NavLink activeStyle={{ color: colors.accent }} to={paths.artistSelect} style={textStyles.navigationLinkTextStyle}>
                                                {getLocalizedString(stringIds.ManageCurrentTeam.selectArtistTitle)}
                                            </NavLink>
                                        </Nav.Item> */}
                                        <div
                                            style={{
                                                alignSelf: "flex-end",
                                                textAlign: "center",
                                                bottom: "3vh",
                                                marginBottom:
                                                    24 + outageBannerHeight,
                                            }}
                                        >
                                            <Nav.Item
                                                style={{ marginBottom: 16 }}
                                            >
                                                <NavLink
                                                    activeStyle={
                                                        navLinkSelectedState
                                                    }
                                                    to={paths.settings}
                                                    style={navFooter}
                                                >
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.SETTINGS_STRINGS,
                                                            stringId:
                                                                stringIds
                                                                    .Settings
                                                                    .settings,
                                                        }
                                                    )}
                                                    <Icon
                                                        icon={
                                                            IconsList.navigation_settings
                                                        }
                                                        size={
                                                            rootStyles.icons
                                                                .small
                                                        }
                                                        color={
                                                            rootStyles.colors
                                                                .primary
                                                        }
                                                        id={`${RootNavigationPrefix}_SettingsIcon`}
                                                    />
                                                </NavLink>
                                            </Nav.Item>
                                            <Nav.Item
                                                style={{ marginBottom: 16 }}
                                            >
                                                <NavLink
                                                    activeStyle={
                                                        navLinkSelectedState
                                                    }
                                                    to={
                                                        paths.reportArtistProfile
                                                    }
                                                    style={navFooter}
                                                >
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                            stringId:
                                                                stringIds
                                                                    .ArtistDisambiguation
                                                                    .reportArtistProfileIssueTitle,
                                                        }
                                                    )}
                                                    <Icon
                                                        icon={
                                                            IconsList.ic_important
                                                        }
                                                        size={
                                                            rootStyles.icons
                                                                .small
                                                        }
                                                        color={
                                                            rootStyles.colors
                                                                .primary
                                                        }
                                                        id={`${RootNavigationPrefix}_ReportIssueIcon`}
                                                    />
                                                </NavLink>
                                            </Nav.Item>
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <MediumGlassButton
                                                    title={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.SETTINGS_STRINGS,
                                                            stringId:
                                                                stringIds
                                                                    .Settings
                                                                    .signOut,
                                                        }
                                                    )}
                                                    containerStyle={{
                                                        width: 200,
                                                    }}
                                                    buttonStyle={{
                                                        width: "100%",
                                                    }}
                                                    onClick={() => signOut()}
                                                    id={`${RootNavigationPrefix}_SignOut`}
                                                />
                                            </div>
                                        </div>
                                    </Nav>
                                </Menu>
                            </Col>
                        ) : null}
                        <Col xl={0} lg={"auto"} md={0} />
                        <Col
                            lg={showNav ? 8 : 12}
                            style={{
                                overflow: "visible",
                                paddingTop: rootStyles.spacers.base,
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}
                        >
                            <Header />
                            <TransitionGroup>
                                <CSSTransition
                                    key={"group"}
                                    classNames="fade"
                                    timeout={300}
                                >
                                    <Switch>
                                        <Route
                                            path={paths.oobe}
                                            component={FirstLoginScreen}
                                        />
                                        {redirectToOOBE && (
                                            <Redirect to={paths.oobe} />
                                        )}
                                        <Route
                                            path={`${paths.deeplinkResolver}/:version/:asin?`}
                                            component={DeeplinkResolverScreen}
                                        />
                                        {goToDeeplink && <Redirect to={path} />}
                                        <Route
                                            path={paths.reports}
                                            exact={true}
                                            component={
                                                Reporting.ReportsOverviewScreen
                                            }
                                        />
                                        <Route
                                            path={paths.artistSelect}
                                            component={SelectArtistScreen}
                                        />
                                        <Route
                                            path={paths.requesterType}
                                            component={ClaimRequesterTypeScreen}
                                        />
                                        <Route
                                            path={paths.vendorFlow}
                                            exact={true}
                                            component={ClaimRequestInviteScreen}
                                        />
                                        <Route
                                            path={paths.vendorFlowLearnMore}
                                            component={
                                                ClaimVendorLabelLearnMoreScreen
                                            }
                                        />

                                        <Route
                                            path={paths.songs}
                                            component={Reporting.SongsScreen}
                                        />
                                        <Route
                                            path={`${paths.songPerformance}/:asin`}
                                            component={
                                                Reporting.SongPerformanceScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.albumTracks}/:asin`}
                                            component={
                                                Reporting.AlbumTracksScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.albumPerformance}/:asin`}
                                            component={
                                                Reporting.AlbumPerformanceScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.stationPerformance}/:asin`}
                                            component={
                                                Reporting.StationPerformanceScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.playlistPerformance}/:asin`}
                                            component={
                                                Reporting.PlaylistPerformanceScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.songCountries}/:asin`}
                                            component={
                                                Reporting.SongCountriesScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.albumCountries}/:asin`}
                                            component={
                                                Reporting.AlbumCountriesScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.stationCountries}/:asin`}
                                            component={
                                                Reporting.StationCountriesScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.playlistCountries}/:asin`}
                                            component={
                                                Reporting.PlaylistCountriesScreen
                                            }
                                        />

                                        <Route path={`/reports`} exact={true}>
                                            <Redirect to={paths.reports} />
                                        </Route>
                                        <Route
                                            path={`${paths.songPerformance}`}
                                        >
                                            <Redirect to={paths.songs} />
                                        </Route>
                                        <Route path={`${paths.albumTracks}`}>
                                            <Redirect to={paths.albums} />
                                        </Route>
                                        <Route
                                            path={`${paths.albumPerformance}`}
                                        >
                                            <Redirect to={paths.albums} />
                                        </Route>
                                        <Route
                                            path={`${paths.stationPerformance}`}
                                        >
                                            <Redirect to={paths.stations} />
                                        </Route>
                                        <Route
                                            path={`${paths.playlistPerformance}`}
                                        >
                                            <Redirect to={paths.playlists} />
                                        </Route>
                                        <Route path={"/"} exact={true}>
                                            <Redirect to={paths.artistSelect} />
                                        </Route>
                                        <Route path={"/ap/return"}>
                                            <Redirect to={paths.reports} />
                                        </Route>

                                        <Route
                                            path={paths.albums}
                                            component={Reporting.AlbumsScreen}
                                        />
                                        <Route
                                            path={paths.voice}
                                            component={Reporting.VoiceScreen}
                                        />
                                        <Route
                                            path={paths.fans}
                                            component={Reporting.FansScreen}
                                        />
                                        <Route
                                            path={paths.countries}
                                            component={
                                                Reporting.CountriesScreen
                                            }
                                        />
                                        <Route
                                            path={paths.programming}
                                            component={
                                                Reporting.ProgrammingScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.stations}/:trackAsin?`}
                                            component={Reporting.StationsScreen}
                                        />
                                        <Route
                                            path={`${paths.playlists}/:trackAsin?`}
                                            component={
                                                Reporting.PlaylistsScreen
                                            }
                                        />
                                        <Route
                                            path={paths.newAddsToPlaylists}
                                            component={
                                                Reporting.NewAddsToPlaylistsScreen
                                            }
                                        />
                                        <Route
                                            path={paths.artistSearch}
                                            component={
                                                Claims.SearchArtistScreen
                                            }
                                        />
                                        <Route
                                            path={paths.claim}
                                            component={
                                                Claims.ClaimThisArtistScreen
                                            }
                                        />

                                        <Route path={`/claim`} exact={true}>
                                            <Redirect to={paths.claim} />
                                        </Route>

                                        <Route
                                            path={`${paths.provideInformation}/twitch/:stateId/:token`}
                                            render={({ match }) => (
                                                <Redirect
                                                    to={`${paths.profile}/link/${match.params.token}`}
                                                />
                                            )}
                                        />
                                        {/* Required if a user cancels auth. */}
                                        <Route
                                            path={`${paths.provideInformation}/twitch/:stateId`}
                                            render={({ match }) => (
                                                <Redirect
                                                    to={`${paths.profile}/link/`}
                                                />
                                            )}
                                        />
                                        <Route
                                            path={`${paths.provideInformation}/:partner/:stateId/:token/:nounce`}
                                            component={
                                                Claims.ProvideYourInformationScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.provideInformation}/:partner/:stateId/:token`}
                                            component={
                                                Claims.ProvideYourInformationScreen
                                            }
                                        />
                                        <Route
                                            path={`${paths.provideInformation}/:partner/:token`}
                                            component={
                                                Claims.ProvideYourInformationScreen
                                            }
                                        />
                                        <Route
                                            path={paths.provideInformation}
                                            component={
                                                Claims.ProvideYourInformationScreen
                                            }
                                        />

                                        <Route
                                            path={paths.claimDone}
                                            component={
                                                Claims.ClaimSubmissionOutScreen
                                            }
                                        />

                                        <Route
                                            path={paths.currentTeamMembers}
                                            component={CurrentMembersScreen}
                                        />
                                        <Route
                                            path={paths.inviteTeamMember}
                                            component={InviteTeamMemberScreen}
                                        />
                                        <Route
                                            path={paths.invitedTeamMembers}
                                            component={InviteTeamMemberScreen}
                                        />
                                        <Route
                                            path={`${paths.manageTeamMember}/:memberId`}
                                            component={ModifyTeamMemberScreen}
                                        />
                                        <Route path={paths.manageTeamMember}>
                                            <Redirect
                                                to={paths.currentTeamMembers}
                                            />
                                        </Route>
                                        <Route
                                            path={paths.termsAndConditions}
                                            component={TermsAndConditionsScreen}
                                        />
                                        <Route
                                            path={paths.settings}
                                            component={SettingsScreen}
                                        />
                                        <Route
                                            path={`${paths.acceptInvite}/:id`}
                                            component={AcceptInviteScreen}
                                        />
                                        <Route
                                            path={paths.acceptInvite}
                                            component={AcceptInviteScreen}
                                        />
                                        <Route
                                            path={paths.openSourceAttribution}
                                            component={
                                                OpenSourceAttributionScreen
                                            }
                                        />
                                        <Route
                                            path={paths.profile}
                                            component={ProfileScreen}
                                        />
                                        <Route
                                            path={`${paths.profile}/link/:token`}
                                            component={ProfileScreen}
                                        />
                                        <Route
                                            path={paths.blueprints}
                                            component={BlueprintsScreen}
                                        />
                                        <Route
                                            path={paths.reportArtistProfile}
                                            component={ReportArtistIssueScreen}
                                        />
                                        <Route
                                            path={paths.newReleases}
                                            component={NewReleasesScreen}
                                        />
                                        <Route
                                            path={paths.pitch}
                                            component={PitchScreen}
                                        />
                                        <Route
                                            path={paths.announcements}
                                            component={
                                                AnnouncementsSeeAllScreen
                                            }
                                        />
                                        <Route
                                            path={paths.announcementDetails}
                                            component={AnnouncementDetailPage}
                                        />
                                        <Route
                                            path={`${paths.announcementDetails}/:id`}
                                            component={AnnouncementDetailPage}
                                        />
                                        <Route
                                            path={paths.accountDetails}
                                            component={AccountDetailsScreen}
                                        />
                                        <Route
                                            path={paths.merch}
                                            component={MerchOverviewScreen}
                                        />
                                        <Route
                                            path={paths.modLanding}
                                            component={MoDLandingScreen}
                                        />
                                        {/* disabling auto search screen until further notice: https://issues.amazon.com/issues/ALPS-25853 */}
                                        {/* <Route
                                            path={paths.autoSearchMerch}
                                            component={AutoSearchScreen}
                                        /> */}
                                        <Route
                                            path={paths.manualSearchMerch}
                                            component={ManualSearchScreen}
                                        />
                                        <Route
                                            path={paths.seeAllMerch}
                                            component={SeeAllMerchScreen}
                                        />
                                        <Route
                                            path={paths.promoCard}
                                            component={PromoCardScreen}
                                        />
                                    </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        </Col>
                        {/* This column is only to make graphs stay in the middle */}
                        <Col lg={3}></Col>
                        {/* contiainer for portal elements created from ReactDOM.createPortal */}
                        <div id="portal-root"></div>
                    </Row>
                </Router>
            </Container>
        );
    }

    private onNavigationChange = (isOpen: boolean) => {
        this.setState({ navigationOpen: isOpen });
    };

    private emitOpsMetricsBeforeClose = () => {
        this.props.stopTimedEmitterAndEmit();
        return;
    };

    private onAcceptCookie = () => {
        this.props.updateCookieSetting(CookieConsentString.ACCEPT);
        markFeatureAsSeen(FeatureName.CookieConsent);
        this.setState({
            seenFeatures: retrieveSeenFeatures(),
        });
    };

    private onRejectCookie = () => {
        this.props.updateCookieSetting(CookieConsentString.REJECT);
        markFeatureAsSeen(FeatureName.CookieConsent);
        this.setState({
            seenFeatures: retrieveSeenFeatures(),
        });
    };

    private onCustomizeCookie = () => {
        this.props.updateCookieSetting(CookieConsentString.ACCEPT_PARTIAL);
        markFeatureAsSeen(FeatureName.CookieConsent);
        this.setState({
            seenFeatures: retrieveSeenFeatures(),
        });
        window.open(
            getLocalizedUrl(LocalizedUrls.CookieConsentCustomizeCookieUrl),
            "_blank"
        );
    };

    private updateWidth = () => {
        if (
            this.state.width >= LARGE_SCREEN_WIDTH_THRESHOLD &&
            window.innerWidth < LARGE_SCREEN_WIDTH_THRESHOLD
        ) {
            this.setState({ navigationOpen: false });
        } else if (
            this.state.width < LARGE_SCREEN_WIDTH_THRESHOLD &&
            window.innerWidth >= LARGE_SCREEN_WIDTH_THRESHOLD
        ) {
            this.setState({ navigationOpen: true });
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({ width: window.innerWidth });
        }
    };

    private getCustomBurgerIcon = (): any => {
        return (
            <Icon
                icon={IconsList.navigation_menu}
                size={rootStyles.icons.medium}
                color={rootStyles.colors.primary}
                id={`${RootNavigationPrefix}_OpenMenu`}
            />
        );
    };

    private getCustomCrossIcon = (): any => {
        return (
            <Icon
                icon={IconsList.action_cancel}
                size={rootStyles.icons.medium}
                color={rootStyles.colors.primary}
                id={`${RootNavigationPrefix}_Close`}
            />
        );
    };

    private calculateOutageBannerHeight = (
        outageBannerMessage: string,
        width: number
    ) => {
        if (width < LARGE_SCREEN_WIDTH_THRESHOLD) {
            return outageBannerMessage.length > 88 ? 64 : 48;
        } else {
            return 32;
        }
    };
}

const NavHeaderBackground = styled.div<{ top: number }>`
    background-color: ${colors.aux6};
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 1000;
    filter: drop-shadow(0 0 8px #000);
    top: ${(props) => `${props.top}px`};
`;

const NavHeaderOverlay = styled.div`
    background-color: ${glassColors.secondary2};
    width: 100%;
    height: 100%;
`;

const navFooter: React.CSSProperties = {
    ...textStyles.navigationLinkTextStyle,
    fontSize: webFontSizes.t2,
    paddingLeft: 4,
    paddingRight: 4,
    display: "flex",
    justifyContent: "space-between",
    width: 200,
};

const navLinkSelectedState: React.CSSProperties = {
    color: colors.accent,
};

const outageBannerRowStyle = (height: number): React.CSSProperties => {
    return {
        position: "relative",
        top: 0,
        zIndex: 1105, // the nav bar has a z-index of 1100 by default, so this value is above that in order to keep the banner over it
        width: "100%",
        height: height,
    };
};

const OutageBannerContainer = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    margin-bottom: 0px;
`;

function mapStateToProps(state: RootState) {
    return {
        ...rootNavigationSelector(state),
        ...cookieConsentSelector(state),
        ...showHypeDeckSelector(state),
        bundleMapLoadInProgress: bundleMapLoadInProgress(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        registerUser: () => dispatch(userActions.registerUser()),
        getCustomerSupportData: () =>
            dispatch(oAuthActions.getCustomerSupportData()),
        refreshOutageStatus: () =>
            dispatch(outageStatusActions.getOutageStatus()),
        updatePrevPath: (path: string) =>
            dispatch(userActions.updatePrevPath(path)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        stopTimedEmitterAndEmit: () =>
            dispatch(opsMetricsActions.stopTimedEmitter()),
        requestModal: (payload: ModalRenderFunction) =>
            dispatch(globalNotificationsActions.requestModalDisplay(payload)),
        updateCookieSetting: (payload: CookieConsentString) =>
            dispatch(userActions.updateCookieConsentSetting(payload)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RootNavigationController);
