import axios from "axios";
import _ from "lodash";
import {
    ArtistStudioGetCASURLRequest,
    ArtistStudioGetExperienceRequest,
    ArtistStudioListExperiencesRequest,
    ArtistStudioUploadCASAssetRequest,
    ArtistStudioCreateExperienceRequest,
    ArtistStudioUpdateExperienceAssetsRequest,
    ArtistStudioUpdateExperienceMetadataRequest,
    ArtistStudioUpdateExperienceStateRequest,
    ArtistStudioListExperiencesForEntitiesRequest,
    ArtistStudioGetAssetStatusesRequest,
    ExperienceAssetStatus,
} from "../models";
import { getEndpoints, testHeaders } from "./common";

const artistControlUrl = `${getEndpoints().artistControl}/artist`;
const baseExperiencePath = `/experience`;
const assetUploadEndpoint = `${artistControlUrl}/assets/create`;
const assetStatusEndpoint = `${artistControlUrl}/assets/statuses`;
const createExperienceEndpoint = `${artistControlUrl}${baseExperiencePath}/create`;
const getExperienceEndpoint = `${artistControlUrl}${baseExperiencePath}`;
const listExperiencesEndpoint = `${artistControlUrl}${baseExperiencePath}s/list`;
const listExperiencesForEntitiesEndpoint = `${listExperiencesEndpoint}/entities`;
const updateExperienceAssetsEndpoint = `${artistControlUrl}${baseExperiencePath}/update/assets`;
const updateExperienceMetadataEndpoint = `${artistControlUrl}${baseExperiencePath}/update/metadata`;
const updateExperienceStateEndpoint = `${artistControlUrl}${baseExperiencePath}/update/state`;

const ASSET_STATUS_POLLING_RETRY = 500 // in ms
const ASSET_POLLING_MAX_NUMBER_OF_TRIES = 60 // will give around 30 seconds before the polling fails
export async function listExperiences(
    payload: ArtistStudioListExperiencesRequest
) {
    const result = await axios.post(
        listExperiencesEndpoint,
        {
            publishingArtistAsin: payload.publishingArtistAsin,
            experienceTypeFilter: payload.experienceTypeFilter,
            paginationToken: payload.paginationToken,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function listExperiencesForEntities(
    payload: ArtistStudioListExperiencesForEntitiesRequest
) {
    const result = await axios.post(
        listExperiencesForEntitiesEndpoint,
        {
            publishingArtistAsin: payload.publishingArtistAsin,
            experienceTypeFilter: payload.experienceTypeFilter,
            entities: payload.entities,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function getExperience(payload: ArtistStudioGetExperienceRequest) {
    const result = await axios.post(
        getExperienceEndpoint,
        {
            experienceId: payload.experienceId,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function createExperience(
    payload: ArtistStudioCreateExperienceRequest
) {
    const result = await axios.post(
        createExperienceEndpoint,
        {
            publishingArtistAsin: payload.publishingArtistAsin,
            experienceType: payload.experienceType,
            experienceTitle: payload.experienceTitle,
            creationState: payload.creationState,
            eligibility: payload.eligibility,
            associatedEntities: payload.associatedEntities,
            assets: payload.assets,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function updateExperienceAssets(
    payload: ArtistStudioUpdateExperienceAssetsRequest
) {
    const result = await axios.post(
        updateExperienceAssetsEndpoint,
        {
            experienceId: payload.experienceId,
            set: payload.set,
            remove: payload.remove,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result;
}

export async function updateExperienceMetadata(
    payload: ArtistStudioUpdateExperienceMetadataRequest
) {
    const result = await axios.post(
        updateExperienceMetadataEndpoint,
        {
            experienceId: payload.experienceId,
            title: payload.title,
            eligibility: payload.eligibility,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result;
}

export async function updateExperienceState(
    payload: ArtistStudioUpdateExperienceStateRequest
) {
    const result = await axios.post(
        updateExperienceStateEndpoint,
        {
            experienceId: payload.experienceId,
            state: payload.state,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result;
}

export async function createAsset(payload: ArtistStudioGetCASURLRequest) {
    const result = await axios.post(
        assetUploadEndpoint,
        {
            assetDefinition: {
                assetContext: payload.assetContext,
                mimeType: payload.mimeType,
                extension: payload.extension,
            },
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function uploadCASAsset(
    payload: ArtistStudioUploadCASAssetRequest
) {
    const data = new FormData();
    data.append("file", payload.file);

    const result = await axios.post(payload.casUrl, data, {
        withCredentials: false,
        headers: null,
    });

    return result;
}

export async function getAssetStatuses(
    payload: ArtistStudioGetAssetStatusesRequest
) {
    const fetchStatuses = () => {
        return axios.post(
            assetStatusEndpoint,
            {
                assetIds: payload.assetIds,
            },
            {
                headers: {
                    "x-team-id": payload.teamId,
                    ...testHeaders(),
                },
            }
        );
    }
    const validateResponse = (statuses: { [assetId: string]: ExperienceAssetStatus }) => {
        let isValid = true;
        _.forEach(payload.assetIds, (assetId) => {
            if (statuses[assetId] === ExperienceAssetStatus.UNAVAILABLE) {
                isValid = false;
                return;
            }
        });
        return isValid;
    };

    const wait = () => {
        return new Promise(resolve => {
            setTimeout(resolve, ASSET_STATUS_POLLING_RETRY);
        });
    }

    const pollStatuses = async function () {
        let currentNumTries = 1;
        let result = await fetchStatuses();
        while (!validateResponse(result.data.statuses) && currentNumTries < ASSET_POLLING_MAX_NUMBER_OF_TRIES) {
            await wait();
            result = await fetchStatuses();
            currentNumTries++;
        }
        return result
    };

    const result = await pollStatuses();

    return result.data;
}
