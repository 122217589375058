export type userInfo = {
    timeZone: string;
};

export type userData = {
    userName: string;
    emailAddress: string;
};

export type teamInfo = {
    teamId: string;
    teamName: string;
    artistAsins: string[];
    members: teamMember[];
    teamType: TeamType;
};

export type teamMember = {
    memberId: string;
    role: TeamRole;
    permissions: TeamPermissions[];
};

export enum TeamType {
    ARTIST = "ARTIST",
    VENDOR = "VENDOR",
    LABEL = "LABEL",
}

export enum TeamRole {
    Owner = "Owner",
    Admin = "Admin",
    Viewer = "ReadOnly",
    Custom = "Custom",
}

export enum TeamPermissions {
    UploadImage = "UploadImage",
    ReadReports = "ReadReports",
    AddMember = "AddMember",
    RemoveMember = "RemoveMember",
    SetPermissions = "SetPermissions",
    SetMemberInfo = "SetMemberInfo",
    PromoteToOwner = "PromoteToOwner",
    SendInvite = "SendInvite",
    CancelInvite = "CancelInvite",
    ManageTwitch = "ManageTwitch",
    ManageBluePrints = "ManageBluePrints",
    PitchWrite = "PitchWrite",
    PitchRead = "PitchRead",
    ManageExperiences = "ManageExperiences",
    MerchOnDemandIngress = "MerchOnDemandIngress",
    CurateMerch = "CurateMerch",
}
