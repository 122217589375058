import { DeviceUtil } from "."
import { METRIC_KEYS } from "../models"

const getDeviceInfo = (): Map<string, string | undefined> => {
    return new Map<string, string | undefined>([
        [METRIC_KEYS.userAgent, DeviceUtil.getUserAgent()],
        [METRIC_KEYS.browserName, DeviceUtil.getBrowserName()],
        [METRIC_KEYS.browserVersion, DeviceUtil.getBrowserFullVersion()],
        [METRIC_KEYS.deviceType, DeviceUtil.getDeviceType()],
        [METRIC_KEYS.osName, DeviceUtil.getOSName()],
        [METRIC_KEYS.osVersion, DeviceUtil.getOSVersion()],
        [METRIC_KEYS.mobileVendor, DeviceUtil.getMobileVendor()],
        [METRIC_KEYS.mobileModel, DeviceUtil.getMobileModel()],
        [METRIC_KEYS.engineName, DeviceUtil.getEngineName()],
        [METRIC_KEYS.engineVersion, DeviceUtil.getEngineVersion()],
    ])
}

export const TelemetryUtil = {
    getDeviceInfo,
}