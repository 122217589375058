import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AnyAction } from "redux";
import {} from "@amzn/arb-tools";
import {
    artist,
    BundleMap,
    ErrorPayload,
    manageBluePrintsPayload,
} from "../../../models";
import { paths } from "../../../utils/constant";
import {
    artistControlActions,
    blueprintScreenSelector,
    RootState,
    userActions,
} from "../../../store";
import { Container } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { getLocalizedString } from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import { CommonHeader } from "../../components";

const testIDPrefix = "BlueprintsScreen";

type StateProps = {
    isSignedIn: boolean;
    blueprintsUrl?: string;
    error?: ErrorPayload;
    teamId: string;
    artist?: artist;
    bundleMap: BundleMap;
};

type DispatchProps = {
    manageSkills: (payload: manageBluePrintsPayload) => void;
    updateCurrentPath: (payload: string) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<{}>;

type State = {
    iframeLoaded: boolean;
};

export class BlueprintsScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { iframeLoaded: false };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        if (this.props?.artist?.asin) {
            this.props.manageSkills({
                teamId: this.props.teamId,
                artistAsin: this.props.artist?.asin,
                requestPath: paths.blueprints,
            });
        }

        window.focus();
        window.addEventListener("blur", this.detectIFrameBlur);
    }

    componentWillUnmount() {
        window.removeEventListener("blur", this.detectIFrameBlur);
    }

    detectIFrameBlur() {
        window.setTimeout(function () {
            if (document.activeElement === document.getElementById("iframe")) {
                document.getElementById("iframe")?.click();
            }
        }, 0);
    }

    render() {
        const DISPLAY_LANGUAGE_SUFFIX = getLocalizedString(
            this.props.bundleMap,
            {
                bundleId: bundleIds.SPOTLIGHT_STRINGS,
                stringId: stringIds.Spotlight.blueprintsURLDisplayLanguage,
            }
        );
        const LOCALIZED_BLUEPRINTS_URL = `${this.props.blueprintsUrl}${DISPLAY_LANGUAGE_SUFFIX}`;

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {this.props.blueprintsUrl && (
                    <>
                        <CommonHeader
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SPOTLIGHT_STRINGS,
                                stringId:
                                    stringIds.Spotlight.artistSpotlightLabel,
                            })}
                            subtitle={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.NAVIGATION_STRINGS,
                                stringId: stringIds.Navigation.artistToolsTitle,
                            })}
                            id={`${testIDPrefix}_Header`}
                            hideDatePicker={true}
                        />
                        <iframe
                            className="blueprint-iframe"
                            frameBorder={0}
                            allow="microphone;clipboard-write"
                            height={window.screen.availHeight}
                            width={"100%"}
                            src={LOCALIZED_BLUEPRINTS_URL}
                            id="iframe"
                            loading={"eager"}
                        />
                    </>
                )}
            </Container>
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return blueprintScreenSelector(state, paths.blueprints);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        manageSkills: (payload: manageBluePrintsPayload) =>
            dispatch(artistControlActions.manageSkills(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BlueprintsScreen)
);
