import * as React from "react";
import Cropper from "react-easy-crop";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { Icon, IconsList, styledTitle } from "..";
import * as rootStyles from "../../../styles";
import { LargeSolidButton, LargeOutlineButton } from "../input/Buttons";
import { testIDSuffixes } from "../../../../utils";
import { Slider, ThemeProvider, createMuiTheme } from "@material-ui/core";
import { Point, Area } from "react-easy-crop/types";
import * as ReactBootstrap from "react-bootstrap";

export type CropImageModalProps = {
    cropShape: "rect" | "round";
    image: string;
    crop: Point;
    zoom: number;
    aspect: number;
    onCropChange: (crop: Point) => void;
    onCropComplete?: (_croppedArea: Area, croppedAreaPixels: Area) => void;
    onZoomChange: (zoom: number) => void;
    onZoomIn?: () => void;
    onZoomOut?: () => void;
    id: string;
    labelText?: string;
    alertText?: string;
    showAlert?: boolean;
    text: string;
    saveButtonText?: string;
    saveButtonAction?: () => void;
    uploadButtonText?: string;
    uploadButtonAction?: () => void;
    description?: string;
    terms?: string;
    guidelines?: string;
    artistHandbookDescritpion?: string;
    artistHandbookUrl?: string;
    onDismiss?: () => void;
    isVisible: boolean;
    sticky?: boolean;
};

export class CropImageModal extends React.Component<CropImageModalProps> {
    render() {
        const artistStudioStylingChanges = flexStyle;

        return (
            <Modal
                show={this.props.isVisible}
                style={rootStyles.containerStyles.modal}
                size="lg"
            >
                <Modal.Body style={modalContainer}>
                    <Row
                        style={cancelButtonStyle}
                        onClick={this.props.onDismiss}
                    >
                        <Icon
                            icon={IconsList.action_cancel}
                            size={rootStyles.spacers.huge}
                            color={rootStyles.colors.primary}
                            id={this.props.id + "_DismissIcon"}
                        />
                    </Row>
                    <Row
                        style={{
                            alignSelf: "center",
                        }}
                    >
                        <Col>
                            <styledTitle.h2 style={{ margin: 0 }}>
                                {this.props.text}
                            </styledTitle.h2>
                        </Col>
                    </Row>
                    {this.props.labelText ? (
                        <Row
                            style={{
                                alignSelf: "center",
                            }}
                        >
                            <Col id={this.props.id + "-Label"}>
                                <span
                                    style={{
                                        ...rootStyles.textStyles.primary,
                                    }}
                                >
                                    {this.props.labelText}
                                </span>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <div style={cropperStyle}>
                            <Cropper
                                cropShape={this.props.cropShape}
                                cropSize={{
                                    width: 300 * this.props.aspect,
                                    height: 300,
                                }}
                                showGrid={false}
                                image={this.props.image}
                                crop={this.props.crop}
                                zoom={this.props.zoom}
                                aspect={this.props.aspect}
                                onCropChange={this.props.onCropChange}
                                onCropComplete={this.props.onCropComplete}
                                onZoomChange={this.props.onZoomChange}
                            />
                        </div>
                    </Row>
                    <Row style={sliderStyle}>
                        <Col>
                            <ThemeProvider theme={muiTheme}>
                                <Slider
                                    value={this.props.zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) =>
                                        this.props.onZoomChange(zoom as number)
                                    }
                                />
                            </ThemeProvider>
                        </Col>
                    </Row>
                    {this.props.showAlert && (
                        <Row
                            style={{
                                ...artistStudioStylingChanges,
                            }}
                        >
                            <ReactBootstrap.Toast style={toastContainerStyle}>
                                <ReactBootstrap.Toast.Body
                                    style={toastBodyStyle}
                                >
                                    <Row style={iconContainerStyle}>
                                        <Col
                                            xs={1}
                                            style={{
                                                ...artistStudioStylingChanges,
                                            }}
                                        >
                                            <Icon
                                                icon={IconsList.alerts_warning}
                                                size={rootStyles.icons.medium}
                                                color={
                                                    rootStyles.colors.primary
                                                }
                                                id={
                                                    this.props.id +
                                                    testIDSuffixes.icon
                                                }
                                            />
                                        </Col>
                                        <Col xs={11}>
                                            <p style={textStyle}>
                                                {this.props.alertText}
                                            </p>
                                        </Col>
                                    </Row>
                                </ReactBootstrap.Toast.Body>
                            </ReactBootstrap.Toast>
                        </Row>
                    )}
                    <Row style={buttonSectionStyle}>
                        {this.props.uploadButtonText &&
                        this.props.uploadButtonAction ? (
                            <Col id={this.props.id + "-UploadButton"}>
                                <LargeOutlineButton
                                    containerStyle={buttonStyle}
                                    title={this.props.uploadButtonText}
                                    onClick={this.props.uploadButtonAction}
                                    id={`${this.props.id}_UploadImageButton`}
                                />
                            </Col>
                        ) : null}
                        {this.props.saveButtonText &&
                        this.props.saveButtonAction ? (
                            <Col id={this.props.id + "-SaveButton"}>
                                <LargeSolidButton
                                    containerStyle={buttonStyle}
                                    title={this.props.saveButtonText}
                                    onClick={this.props.saveButtonAction}
                                    disabled={this.props.showAlert}
                                    id={`${this.props.id}_SaveImageButton`}
                                />
                            </Col>
                        ) : null}
                    </Row>
                    {this.props.description ? (
                        <Row
                            id={this.props.id + "-Description"}
                            style={descriptionStyle}
                        >
                            <Col sm={8} lg={5}>
                                {this.props.terms ? (
                                    <p
                                        style={rootStyles.textStyles.secondary}
                                        id={`${this.props.id}_Description`}
                                    >
                                        {this.props.description +
                                            " " +
                                            this.props.terms +
                                            ". " +
                                            this.props.guidelines}
                                        <a
                                            style={clickableTextStyle}
                                            href={this.props.artistHandbookUrl}
                                            target="_blank"
                                        >
                                            {" " +
                                                this.props
                                                    .artistHandbookDescritpion +
                                                ". "}
                                        </a>
                                    </p>
                                ) : (
                                    <p
                                        style={rootStyles.textStyles.secondary}
                                        id={`${this.props.id}_Description`}
                                    >
                                        {this.props.description}
                                    </p>
                                )}
                            </Col>
                        </Row>
                    ) : null}
                </Modal.Body>
            </Modal>
        );
    }
}

const toastContainerStyle: React.CSSProperties = {
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
    marginTop: rootStyles.spacers.epic,
    width: 450,
    maxWidth: 450,
    minHeight: 50,
    borderRadius: 8,
    backgroundColor: "rgba(105, 25, 25, 0.75)",
};

const toastBodyStyle: React.CSSProperties = {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: 2,
};

const iconContainerStyle: React.CSSProperties = {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
};

const muiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            colorPrimary: {
                color: rootStyles.colors.accent,
            },
        },
    },
});
const modalContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: rootStyles.colors.background,
    display: "flex",
};

const cropperStyle: React.CSSProperties = {
    flex: 1,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    width: 600,
    height: 300,
    marginTop: rootStyles.spacers.large,
};

const sliderStyle: React.CSSProperties = {
    marginTop: rootStyles.spacers.medium,
    width: 300,
};

const buttonSectionStyle: React.CSSProperties = {
    marginTop: rootStyles.spacers.base,
};

const descriptionStyle: React.CSSProperties = {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginTop: rootStyles.spacers.large,
};

const cancelButtonStyle: React.CSSProperties = {
    flex: 0,
    padding: 4,
    borderRadius: rootStyles.spacers.medium,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
};

const zoomButtonStyle: React.CSSProperties = {
    flex: 0,
    padding: 4,
    width: 16,
    height: 16,
    borderRadius: rootStyles.spacers.base,
    alignSelf: "center",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
};

const buttonStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "stretch",
    width: "100%",
    minWidth: 150,
    marginTop: rootStyles.spacers.base,
};

const textStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    textTransform: "none",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 0,
};

const clickableTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    color: rootStyles.colors.accent,
    marginTop: 2,
};

const flexStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
};
