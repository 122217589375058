import { AnyAction } from "redux";
import { twitchActions } from "../actions";
import { channel } from "../../models";

export type TwitchState = Readonly<{
    linkedChannel?: channel;
    unlinkTwitchChannelInProgress: boolean;
    unlinkTwitchChannelCompleted: boolean;
    hydrateTwitchChannelInProgress: boolean;
    linkTwitchChannelCompleted: boolean;
    linkTwitchChannelInProgress: boolean;
    getTwitchChannelInProgress: boolean;
}>;

const initialState = {
    unlinkTwitchChannelInProgress: false,
    unlinkTwitchChannelCompleted: false,
    hydrateTwitchChannelInProgress: false,
    linkTwitchChannelCompleted: false,
    linkTwitchChannelInProgress: false,
    getTwitchChannelInProgress: false,
};

export const twitchReducer = (
    state: TwitchState = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case twitchActions.setLinkedChannel.type:
            return { ...state, linkedChannel: action.payload };
        case twitchActions.unlinkTwitchChannelInProgress.type:
            return { ...state, unlinkTwitchChannelInProgress: action.payload };
        case twitchActions.unlinkTwitchChannelCompleted.type:
            return { ...state, unlinkTwitchChannelCompleted: action.payload };
        case twitchActions.hydrateTwitchChannelInProgress.type:
            return { ...state, hydrateTwitchChannelInProgress: action.payload };
        case twitchActions.linkTwitchChannelCompleted.type:
            return { ...state, linkTwitchChannelCompleted: action.payload };
        case twitchActions.linkTwitchChannelInProgress.type:
            return { ...state, linkTwitchChannelInProgress: action.payload };
        case twitchActions.getTwitchChannelInProgress.type:
            return { ...state, getTwitchChannelInProgress: action.payload };
        case twitchActions.clearState.type:
            return initialState;
        default:
            return state;
    }
};
