import { RootState } from "..";
import { createSelector } from "reselect";
import {
    albumRelease,
    BundleMap,
    ErrorPayload,
    Experience,
    ExperienceDraft,
    track,
} from "../../models";
import {
    catalogSelector,
    getBundleMap,
    getErrorFromStore,
    getHydratingAsins,
    getIsPrivileged,
    getSelectedArtistAsin,
} from "./commonSelectors";
import { selectedTeamIdSelector } from "./userSelectors";

const getListExperiencesInProgress = (state: RootState) =>
    state.artistStudio.listExperiencesInProgress;
const getExperiences = (state: RootState) => state.artistStudio.experiences;
const getExperienceDrafts = (state: RootState) =>
    state.artistStudio.experienceDrafts;

const getSelectedAlbumRelease = (state: RootState) =>
    state.pitchForm.selectedAlbumRelease;

const getSelectedStep = (state: RootState) => state.pitchForm.selectedStep;

const quickUpdateExperienceStateInProgress = (state: RootState) =>
    state.artistStudio.quickUpdateExperienceStateInProgress;

const getListExperiencesPaginationToken = (state: RootState) =>
    state.artistStudio.listExperiencePaginationToken;

export const artistStudioSelector = createSelector(
    [
        getListExperiencesInProgress,
        getExperiences,
        getExperienceDrafts,
        getIsPrivileged,
        getSelectedArtistAsin,
        selectedTeamIdSelector,
    ],
    (
        listExperiencesInProgress: boolean,
        experiences: Experience[] | undefined,
        experienceDrafts: Map<string, ExperienceDraft> | undefined,
        isPrivileged: boolean | undefined,
        selectedArtistAsin: string | undefined,
        teamId: string | undefined
    ) => {
        return {
            listExperiencesInProgress,
            experiences,
            experienceDrafts,
            isPrivileged,
            selectedArtistAsin,
            teamId,
        };
    }
);

export const experienceAnnouncementSelector = createSelector(
    [
        artistStudioSelector,
        getSelectedAlbumRelease,
        getSelectedStep,
        catalogSelector,
        getErrorFromStore,
        getBundleMap,
    ],
    (
        artistStudioSelector: any,
        selectedAlbumRelease: albumRelease | undefined,
        selectedStep: number | undefined,
        catalogSelector: any,
        error: ErrorPayload | undefined,
        bundleMap: BundleMap
    ) => {
        return {
            ...artistStudioSelector,
            selectedAlbumRelease,
            selectedStep,
            ...catalogSelector,
            error,
            bundleMap: bundleMap,
        };
    }
);

export const announcementSeeAllSelector = createSelector(
    [
        selectedTeamIdSelector,
        getSelectedArtistAsin,
        getErrorFromStore,
        catalogSelector,
        getHydratingAsins,
        getListExperiencesInProgress,
        getExperiences,
        quickUpdateExperienceStateInProgress,
        getListExperiencesPaginationToken,
        getBundleMap,
    ],
    (
        teamId: string | undefined,
        selectedArtistAsin: string | undefined,
        error: ErrorPayload | undefined,
        catalogSelector: any,
        hydratingAsins: any,
        listExperiencesInProgress: boolean,
        experiences: Experience[] | undefined,
        quickUpdateExperienceStateInProgress: boolean,
        listExperiencesPaginationToken: string | undefined,
        bundleMap: BundleMap
    ) => {
        return {
            teamId,
            selectedArtistAsin,
            error,
            ...catalogSelector,
            hydratingAsins,
            listExperiencesInProgress,
            experiences,
            quickUpdateExperienceStateInProgress,
            listExperiencesPaginationToken,
            bundleMap: bundleMap,
        };
    }
);
