// ExperienceDraft designed to easily support future Draft Types
export type ExperienceDraft = AnnouncementDraft;

// Base info that all Experience Types will need
export type BaseExperienceDraft = {
    // controls loading spinner
    getInProgress: boolean;

    // controls button disable, nav, etc
    compositeUpdateInProgress: boolean; // This is used during a composite call ONLY
    compositeUpdateSuccess?: boolean; // This is used during a composite call ONLY
    updateAssetsInProgress: boolean; // NOT used during composite call
    updateMetadataInProgress: boolean; // NOT used during composite call
    updateStateInProgress: boolean; // NOT used during composite call
    updateAssetsSuccess?: boolean; // NOT used during composite call
    updateMetadataSuccess?: boolean; // NOT used during composite call
    updateStateSuccess?: boolean; // NOT used during composite call

    // controls button disable, nav, success/failure modal
    createInProgress: boolean;
    createSuccess?: boolean;

    // controls upload to CAS, button, nav
    uploadToCASInProgress: boolean;
    uploadToCASSuccess?: boolean;
    // This is the original model that the flow was initialized with
    // for a new creation, it will be mostly empty.  For editing an
    // existing experience it will hold all the info from that original experience
    // This WILL NOT BE MODIFIED during the creation flow
    // this will be used to set all the default values for the creation flow
    experience: Experience;
    // Used to hold all assets that were uploaded to CAS
    // during the creation flow
    assets: ExperienceAsset[];
    // Name for Experience that will show in AM4A
    title: string;
    // Eligibility data for the experience
    eligibility: ExperienceEligibility;
    // holds info on if meta data was edited
    isMetadataEdited: boolean;
    // holds if editing an existing experience
    // This will control if we update an existing experience
    // or if we create a new experience
    isEdit: boolean;
};

// a more specific ExperienceDraft for Announcements
export type AnnouncementDraft = BaseExperienceDraft & {
    // Background image for announcement
    backgroundImageURL: string;
    // boolean to check if background needs to be uploaded to CAS
    backgroundImageNeedsUpload: boolean;
    // boolean to check if background was edited
    backgroundImageWasEdited: boolean;
    // Blob for newley uploaded image file
    backgroundImageBlob?: Blob;
    // background image filename
    backgroundImageName: string;
    // AMU Customer facing name of the announcement
    headline: string;
    // Holds if the headline was edited during the announcement flow
    headlineWasEdited: boolean;
    // Filename of the audio source
    audioName: string;
    // Announcement audio message
    audioURL: string;
    // Blob for newley uploaded audio file
    audioBlob?: Blob;
    // boolean to check if audio message was edited,
    // if was edited, needs to be re-uploaded to CAS
    audioWasEdited: boolean;
};

export type Experience = {
    // the experience id from the experience table
    experienceId: string;
    // the experience type, currently supports ANNOUNCEMENT, INTRODUCTION
    experienceType: ExperienceType;
    // the experience title
    experienceTitle: string;
    // the state of the experience in the experience lifecycle
    experienceState: ExperienceState;
    // the artist asin for the artist the experience is associated with
    publishingArtistAsin: string;
    // eligibility information for the experience
    eligibility: ExperienceEligibility;
    // entities that the experience is for, currently supports TRACK, ALBUM
    associatedEntities: ExperienceAssociatedEntity[];
    // assets for the experience, currently supports IMAGE, AUDIO, TEXT, VIDEO
    assets: ExperienceAsset[];
    // used in rendering experiences from list call
    // will only be available in experiences returned from list call
    createdTime?: string;
    updatedTime?: string;
};

export type ExperienceEligibility = {
    // flag for determining explicit experience
    explicit: boolean;
    // OPTIONAL: if present may include:
    // Schedule is optional and all fields within schedule are optional
    schedule?: ExperienceSchedule;
};

// Start/End dates for an experience to be discoverable by customers on AMU
// startDate: when the experience will go live for AMU customers to discover
// expirationDate: When the experience will end and no longer be discoverable
export type ExperienceSchedule = {
    startDate?: Date;
    expirationDate?: Date;
};

// Entity associated with an experience
export type ExperienceAssociatedEntity = {
    // Entity type, currently supports ALBUM, TRACK
    entityType: ExperienceEntityType;
    // Global ASIN for supported entity types
    identifier: string;
    // only in responses, returns true if the entity is a new release
    newRelease?: boolean;
};

// Asset that is part of an experience
export type ExperienceAsset = {
    // key for determining which asset this is for a given experience
    key: string;
    // Asset type, can be restricted further from the full list based on which asset this is
    type: ExperienceAssetType;
    // Source for the asset, will be restriced based on which asset this is
    sourceType: ExperienceAssetSource;
    // can be an assetID, renderable string, asin; depending on the asset
    asset: string;
};

export type ExperiencesPage = {
    experiences: Experience[];
    paginationToken?: string;
};

export enum ExperienceAssetKey {
    BACKGROUND = "BACKGROUND",
    MESSAGE = "MESSAGE",
    TITLE = "TITLE",
}

export enum ExperienceAssetSource {
    ASSET = "ASSET",
    RAW = "RAW",
    ALBUM = "ALBUM",
    TRACK = "TRACK",
    HTTP = "HTTP",
    EXISTING_EXPERIENCE = "EXISTING_EXPERIENCE",
}

export enum ExperienceAssetType {
    IMAGE = "IMAGE",
    AUDIO = "AUDIO",
    TEXT = "TEXT",
    VIDEO = "VIDEO",
}

// Experience supported entity types
export enum ExperienceEntityType {
    ALBUM = "ALBUM",
    TRACK = "TRACK",
}

export enum ExperienceState {
    DRAFT = "DRAFT",
    REVIEW = "REVIEW",
    REJECTED = "REJECTED",
    APPROVED = "APPROVED",
    LIVE = "LIVE",
    ARCHIVED = "ARCHIVED",
}

export enum ExperienceStateChange {
    REVIEW = "REVIEW",
    ARCHIVED = "ARCHIVED",
    LIVE = "LIVE",
    DELETED = "DELETED",
}

export enum ExperienceCreationMode {
    DRAFT = "DRAFT",
    REVIEW = "REVIEW",
}

export enum ExperienceType {
    ANNOUNCEMENT = "ANNOUNCEMENT",
    INTRODUCTION = "INTRODUCTION",
}

export enum ExperienceAssetStatus {
    AVAILABLE = "AVAILABLE",
    UNAVAILABLE = "UNAVAILABLE",
    VALIDATION_FAILED = "VALIDATION_FAILED", // "400" response for asset
    PROCESSING_FAILED = "PROCESSING_FAILED", // "500" response for asset
    NOT_FOUND = "NOT_FOUND",
}

export enum ExperienceSupportedAudioExtensions {
    FLAC = "flac",
    M4A = "m4a", // Can be mimeType `audio/x-m4a` or `audio/m4a`. upload only accepts `audio/m4a`.
    MPEG = "mp3",
    WAV = "wav",
    OGG = "ogg",
    MP4 = "mp4", // IMPORTANT: mp4 is a VIDEO file (and needs to be marked as such using ExperienceAssetType)
}

export enum ExperienceSupportedImageExtensions {
    PNG = "png",
    JPEG = "jpeg",
    JPG = "jpg",
}

// Asset Context Def in Artist Control
// https://tiny.amazon.com/v6gy92u1
export enum ExperienceAssetContext {
    AM4A_EXPERIENCES_IMAGE = "AM4A_EXPERIENCES_IMAGE",
    AM4A_EXPERIENCES_ANNOUNCEMENTS_AUDIO = "AM4A_EXPERIENCES_ANNOUNCEMENTS_AUDIO",
}

export enum ArtistStudioUpdateExperienceType {
    Assets,
    Metadata,
    State,
}
