import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { IconsList, ImageList, stringIds, bundleIds } from "../../../../assets";
import {
    CatalogProps,
    METRIC_KEYS,
    TableRowProps,
    telemetryPayload,
    catalog,
    album,
    track,
    baseCatalogItem,
    Experience,
    ExperienceEntityType,
    GetExperiencePayload,
    UpdateExperienceStatePayload,
    ExperienceStateChange,
    ExperienceType,
    ListExperiencePayload,
    InitiateExperiencePayload,
    BundleMap,
} from "../../../../models";
import {
    AnnouncementUtil,
    catalogHelper,
    checkIfNotEmpty,
    ExperienceUtil,
    formatDate,
    generateLoadingDataRow,
    generateUnavailableDataRow,
    getCatalogItem,
    getLocalizedString,
    getLocalizedUrl,
    paths,
} from "../../../../utils";
import * as rootStyles from "../../../styles";
import {
    FullScreenConfirmationModal,
    Icon,
    IncrementalLoading,
    Loading,
    SmallGlassButton,
    styledTitle,
    Table,
    testIDPrefix,
} from "../../common";
import { History } from "history";
import { AnnouncementLearnMoreModal } from "./AnnouncementLearnMoreModal";
import AnnouncementMoreOptions from "./AnnouncementsTableMoreOptionsDropdown";
import { LocalizedUrls } from "@amzn/ziggy-asset";

type ViewProps = {
    id: string;
};

type StateProps = CatalogProps & {
    selectedArtistAsin: string;
    loadingInProgress: boolean;
    pagePath: string;
    history: History;
    announcements?: Experience[];
    maximumNumberOfRows?: number; // Controls the maximum number of rows to render
    teamId: string;
    isRefreshing: boolean;
    seeAllAnnouncements?: () => void;
    initiateDraft: (payload: InitiateExperiencePayload) => void;
    getExperience: (payload: GetExperiencePayload) => void;
    updateExperienceState: (payload: UpdateExperienceStatePayload) => void;
    paginationToken?: string;
    listExperiences?: (payload: ListExperiencePayload) => void;
    scrolling?: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    userAction: (payload: telemetryPayload) => void;
};

type Props = ViewProps & DispatchProps & StateProps;

type State = {
    showHelpModal: boolean;
    isPaginating: boolean;
    showArchiveModal: boolean;
    showDeleteModal: boolean;
    selectedExperience?: Experience;
};

const metricPrefix = "announcementsTable";
const NO_ANNOUNCEMENTS_DIV_WIDTH = 350;

export class AnnouncementsTable extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showHelpModal: false,
            isPaginating: false,
            showArchiveModal: false,
            showDeleteModal: false,
        };
    }
    componentDidMount() {
        this.props.userAction({
            name: `${metricPrefix}View`,
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtistAsin],
                [METRIC_KEYS.page, this.props.pagePath],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props) {
        // Hydration and request build complete
        if (
            this.state.isPaginating &&
            !this.props.loadingInProgress &&
            prevProps.loadingInProgress
        ) {
            this.setState({ isPaginating: false });
        }
    }

    render() {
        return (
            <>
                {this.announcementsTableHeader()}
                {!this.props.isRefreshing ? (
                    this.announcementsTable()
                ) : (
                    <Loading />
                )}
                <AnnouncementLearnMoreModal
                    isVisible={this.state.showHelpModal}
                    onDone={this.onHelpModalDismiss}
                    bundleMap={this.props.bundleMap}
                    onLearnMore={() =>
                        window.open(
                            getLocalizedUrl(
                                LocalizedUrls.AnnouncementLearnMoreUrl
                            ),
                            "_blank"
                        )
                    }
                />
            </>
        );
    }

    private announcementsTableHeader = () => {
        return (
            <div style={{ paddingRight: 15, paddingLeft: 15 }}>
                <Row
                    style={{
                        marginTop: rootStyles.spacers.base,
                        justifyContent: "space-between",
                    }}
                >
                    <styledTitle.h4>
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementsTableHeader,
                        })}
                    </styledTitle.h4>
                    {!!this.props.seeAllAnnouncements &&
                        !!this.props.maximumNumberOfRows &&
                        !!this.props.announcements &&
                        this.props.announcements.length >
                            this.props.maximumNumberOfRows && (
                            <SmallGlassButton
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.GENERIC_STRINGS,
                                        stringId: stringIds.Generic.seeAll,
                                    }
                                )}
                                onClick={this.props.seeAllAnnouncements}
                                id={this.props.id + "_SeeAllAnnouncements"}
                            />
                        )}
                </Row>
            </div>
        );
    };

    private announcementsTable = () => {
        if (!this.props.announcements) {
            return;
        }

        const isAnnouncementsEmpty = this.props.announcements.length === 0;

        const numRowsToLoad =
            this.props.maximumNumberOfRows &&
            this.props.announcements.length > this.props.maximumNumberOfRows
                ? this.props.maximumNumberOfRows
                : this.props.announcements.length;

        const announcements: TableRowProps[] =
            this.generateAnnouncementTableRows(
                _.slice(this.props.announcements, 0, numRowsToLoad),
                this.props.catalog,
                this.props.hydratingAsins
            );

        const announcementsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                stringId:
                    stringIds.ArtistStudio.Announcement
                        .announcementsTableLeftHeading,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                stringId:
                    stringIds.ArtistStudio.Announcement
                        .announcementsTableRightHeading,
            }),
        ];

        const rightElement = () => {
            return (
                <div
                    style={{
                        display: "flex",
                        marginLeft: rootStyles.spacers.epic,
                    }}
                >
                    <span style={rootStyles.textStyles.index}>
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementsTableActionHeading,
                        })}
                    </span>
                    <Icon
                        icon={IconsList.ic_help}
                        size={16}
                        style={{
                            opacity: rootStyles.glass._4,
                            marginLeft: rootStyles.spacers.mini,
                        }}
                        onClick={this.onHelpModalShow}
                        id={`${testIDPrefix}_HelpIcon`}
                    />
                </div>
            );
        };

        return (
            <Row>
                <Col>
                    <Table
                        rows={announcements}
                        labels={announcementsHeader}
                        stat2labelRightElement={rightElement()}
                        scrolling={!!this.props.scrolling}
                        loadMore={this.loadMore}
                        footer={
                            !this.state.isPaginating ? (
                                <div
                                    style={{
                                        height: rootStyles.spacers.huge,
                                    }}
                                />
                            ) : (
                                <IncrementalLoading />
                            )
                        }
                        id={`${this.props.id}_AnnouncementsTable`}
                        showIndex={false}
                    />
                    <FullScreenConfirmationModal
                        id={`${this.props.id}-ArchiveModal`}
                        icon={IconsList.archive}
                        text={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.GENERIC_STRINGS,
                            stringId: stringIds.Generic.areYouSure,
                        })}
                        description={getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementArchiveModalDescription,
                        })}
                        confirmButtonText={getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.confirm,
                            }
                        )}
                        confirmButtonAction={this.onModalConfirmArchive}
                        cancelButtonText={getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.cancel,
                            }
                        )}
                        cancelButtonAction={this.onModalCancel}
                        onDismiss={this.onModalCancel}
                        isVisible={this.state.showArchiveModal}
                        buttonOrientation={"inline"}
                    />
                    <FullScreenConfirmationModal
                        id={`${this.props.id}-DeleteModal`}
                        icon={IconsList.action_delete}
                        text={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.GENERIC_STRINGS,
                            stringId: stringIds.Generic.areYouSure,
                        })}
                        description={getLocalizedString(this.props.bundleMap, {
                            bundleId:
                                bundleIds.ARTISTSTUDIOANNOUNCEMENT_STRINGS,
                            stringId:
                                stringIds.ArtistStudio.Announcement
                                    .announcementDeleteModalDescription,
                        })}
                        confirmButtonText={getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.confirm,
                            }
                        )}
                        confirmButtonAction={this.onModalConfirmDelete}
                        cancelButtonText={getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.cancel,
                            }
                        )}
                        cancelButtonAction={this.onModalCancel}
                        onDismiss={this.onModalCancel}
                        isVisible={this.state.showDeleteModal}
                        buttonOrientation={"inline"}
                    />
                </Col>
            </Row>
        );
    };

    private generateAnnouncementTableRows = (
        announcements: Experience[],
        catalog: catalog,
        hydrationAsins: string[]
    ) => {
        const stat2Builder = (announcement: Experience, id: string) => {
            // All announcements returned in list call will have expiration date
            // unless there is an error
            if (!announcement.createdTime) {
                return;
            }

            const formattedAnnouncementCreationDate = formatDate(
                new Date(announcement.createdTime)
            );

            const announcementActions =
                AnnouncementUtil.getAnnouncementActions(announcement);

            return (
                <div
                    style={{
                        display: "flex",
                        gap: rootStyles.spacers.epic + rootStyles.spacers.large,
                        paddingRight: rootStyles.spacers.micro,
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            ...rootStyles.textStyles.tertiary,
                            whiteSpace: "pre",
                            marginRight: rootStyles.spacers.micro,
                        }}
                    >
                        {formattedAnnouncementCreationDate}
                    </span>
                    <div onClick={(e) => e.stopPropagation()}>
                        <AnnouncementMoreOptions
                            onSelectUpdate={
                                announcementActions.update
                                    ? () => this.onUpdate(announcement, 0)
                                    : undefined
                            }
                            onSelectArchive={
                                announcementActions.archive
                                    ? () => this.onArchive(announcement)
                                    : undefined
                            }
                            onSelectDelete={
                                announcementActions.delete
                                    ? () => this.onDelete(announcement)
                                    : undefined
                            }
                            id={id}
                        />
                    </div>
                </div>
            );
        };

        const returnList = _.map(
            announcements,
            (announcement: Experience, index: number) => {
                const rowID = `${this.props.id}_${announcement.experienceId}`;
                if (
                    announcement.associatedEntities.length !== 0 &&
                    announcement.eligibility.schedule?.expirationDate
                ) {
                    let albumAsin: string = "";
                    if (
                        announcement.associatedEntities[0].entityType ===
                        ExperienceEntityType.ALBUM
                    ) {
                        albumAsin =
                            announcement.associatedEntities[0].identifier;
                    } else if (
                        announcement.associatedEntities[0].entityType ===
                        ExperienceEntityType.TRACK
                    ) {
                        const trackAsin: string =
                            announcement.associatedEntities[0].identifier;
                        const track = getCatalogItem(
                            trackAsin,
                            catalog
                        ) as track;
                        albumAsin = track.albumAsin;
                    } else {
                        return generateUnavailableDataRow(
                            rowID,
                            "",
                            this.props.bundleMap,
                            ImageList.placeholder_album
                        );
                    }

                    const album = getCatalogItem(albumAsin, catalog) as album;

                    if (catalogHelper.isValidAlbum(album)) {
                        return this.generateAnnouncementRow(
                            album,
                            announcement,
                            () => this.onUpdate(announcement, 2),
                            stat2Builder(
                                announcement,
                                `${this.props.id}_Item${index}`
                            ),
                            rowID,
                            _.includes(hydrationAsins, album.asin)
                        );
                    }
                    if (_.includes(hydrationAsins, albumAsin)) {
                        return generateLoadingDataRow(
                            rowID,
                            0,
                            "",
                            ImageList.placeholder_album
                        );
                    }
                }

                return generateUnavailableDataRow(
                    rowID,
                    "",
                    this.props.bundleMap,
                    ImageList.placeholder_album
                );
            }
        );
        return _.filter(returnList, checkIfNotEmpty);
    };

    private generateAnnouncementRow = (
        catalogItem: baseCatalogItem,
        announcement: Experience,
        onSelect: any,
        stat2: any,
        id: string,
        isLoading?: boolean,
        index?: string,
        showIndex?: boolean,
        hideImage?: boolean,
        showBottomBorder?: boolean
    ): TableRowProps => {
        return {
            primary: announcement.experienceTitle,
            secondary: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTSTUDIO_STRINGS,
                stringId: ExperienceUtil.getExperienceStateStringId(
                    announcement.experienceState
                ),
            }),
            onSelect: onSelect,
            image: hideImage
                ? undefined
                : catalogItem && catalogItem.images?.artSmall,
            fallbackImage: hideImage ? undefined : ImageList.placeholder_album,
            stat2: stat2,
            isLoading: isLoading,
            id,
            imageDescription: catalogItem && catalogItem.title,
            index: showIndex ? index : undefined,
            showBottomBorder: showBottomBorder,
        };
    };

    private loadMore = () => {
        if (!this.props.listExperiences || this.state.isPaginating) {
            return;
        }

        if (!!this.props.paginationToken) {
            this.setState({ isPaginating: true });
            this.getAnnouncements(this.props.paginationToken);
        }
    };

    private getAnnouncements = (paginationToken: string) => {
        if (!this.props.teamId || !this.props.listExperiences) {
            return;
        }
        if (!this.props.selectedArtistAsin) {
            console.log(
                "Artist ASIN was undefined, unable to fetch announcements"
            );
            return;
        }

        const payload: ListExperiencePayload = {
            requestPath: paths.announcements,
            teamId: this.props.teamId,
            artistAsin: this.props.selectedArtistAsin,
            experienceTypeFilter: [ExperienceType.ANNOUNCEMENT],
            paginatonToken: paginationToken,
        };

        this.props.listExperiences(payload);
    };

    private onHelpModalShow = () => {
        this.setState({ showHelpModal: true });
    };

    private onHelpModalDismiss = () => {
        this.setState({ showHelpModal: false });
    };

    private onUpdate = (experience: Experience, stepIndex = 0) => {
        if (!this.props.teamId) {
            this.props.history.push(paths.newReleases);
            return;
        }

        // First initiate draft
        const initiateDraftPayload: InitiateExperiencePayload = {
            experienceDraft:
                AnnouncementUtil.loadAnnouncementDraftFromExperience(
                    experience
                ),
        };

        this.props.initiateDraft(initiateDraftPayload);

        // Make update call to get assests for draft
        const getExperiencePayload: GetExperiencePayload = {
            experienceId: experience.experienceId,
            teamId: this.props.teamId,
            requestPath: this.props.pagePath,
        };
        this.props.getExperience(getExperiencePayload);

        // Navigate to announcement details page
        this.props.history.push({
            pathname: `${paths.announcementDetails}/${experience.experienceId}`,
            state: { stepIndex },
        });
    };

    private onArchive = (experience: Experience) => {
        this.setState({
            showArchiveModal: true,
            showHelpModal: false,
            showDeleteModal: false,
            selectedExperience: experience,
        });
    };

    private onDelete = (experience: Experience) => {
        this.setState({
            showArchiveModal: false,
            showHelpModal: false,
            showDeleteModal: true,
            selectedExperience: experience,
        });
    };

    private onModalConfirmArchive = () => {
        if (!this.state.selectedExperience) {
            return;
        }

        const updateStatePayload: UpdateExperienceStatePayload = {
            teamId: this.props.teamId,
            experienceId: this.state.selectedExperience.experienceId,
            state: ExperienceStateChange.ARCHIVED,
            requestPath: this.props.pagePath,
        };

        this.props.updateExperienceState(updateStatePayload);

        this.onModalCancel();
    };

    private onModalConfirmDelete = () => {
        if (!this.state.selectedExperience) {
            return;
        }

        const updateStatePayload: UpdateExperienceStatePayload = {
            teamId: this.props.teamId,
            experienceId: this.state.selectedExperience.experienceId,
            state: ExperienceStateChange.DELETED,
            requestPath: this.props.pagePath,
        };

        this.props.updateExperienceState(updateStatePayload);

        this.onModalCancel();
    };

    private onModalCancel = () => {
        this.setState({
            showArchiveModal: false,
            showHelpModal: false,
            showDeleteModal: false,
            selectedExperience: undefined,
        });
    };
}
