import action_add from "./ic_action_add.png";
import action_add_black from "./ic_action_add_black.svg";
import action_addInline from "./ic_action_addInline.png";
import action_cancel from "./ic_action_cancel.png";
import action_cancel_black from "./ic_action_cancel_black.svg";
import action_cancelInline from "./ic_action_cancelInline.png";
import action_delete from "./ic_action_delete.png";
import action_dislike from "./ic_action_dislike.png";
import action_done from "./ic_action_done.png";
import action_done_svg from "./ic_action_done.svg";
import action_done_black from "./ic_action_done_black.png";
import action_doneInline from "./ic_action_doneInline.png";
import action_doneInline_blue from "./ic_action_doneInline_blue.svg";
import action_download from "./ic_action_download.png";
import action_download_black from "./ic_action_download_black.png";
import action_downloadcomplete from "./ic_action_downloadcomplete.png";
import action_downloadfailed from "./ic_action_downloadfailed.png";
import action_edit from "./ic_action_edit.png";
import action_edit_black from "./ic_action_edit_black.png";
import action_view from "./ic_action_view.png";
import action_favorite from "./ic_action_favorite.png";
import action_favoriteon from "./ic_action_favoriteon.png";
import action_flag from "./ic_action_flag.png";
import action_follow from "./ic_action_follow.png";
import action_following_active from "./ic_action_following_active.png";
import action_like from "./ic_action_like.png";
import action_makeprivate from "./ic_action_makeprivate.png";
import action_makepublic from "./ic_action_makepublic.png";
import action_maximize from "./ic_action_maximize.png";
import action_minimum from "./ic_action_minimum.png";
import action_more from "./ic_action_more.png";
import action_overflow from "./ic_action_overflow.png";
import list_check_accent from "./ic_list_check_accent.svg";
import action_remove from "./ic_action_remove.png";
import action_removeInline from "./ic_action_removeInline.png";
import action_shareandroid from "./ic_action_shareandroid.png";
import action_share_web from "./ic_action_share_web.svg";
import action_shareemail from "./ic_action_shareemail.svg";
import action_shareembed from "./ic_action_shareembed.svg";
import action_sharefacebook from "./ic_action_sharefacebook.svg";
import action_shareinstagram from "./ic_action_shareinstagram.svg";
import action_sharelink from "./ic_action_sharelink.svg";
import action_share_x from "./ic_action_share_X.svg";
import action_upload from "./ic_action_upload.png";
import alerts_help from "./ic_alerts_help.png";
import alerts_Information from "./ic_alerts_Information.png";
import alerts_warning from "./ic_alerts_warning.png";
import chevron_caretdown from "./ic_chevron_caretdown.png";
import chevron_caretleft from "./ic_chevron_caretleft.png";
import chevron_caretright from "./ic_chevron_caretright.png";
import chevron_caretUp from "./ic_chevron_caretUp.png";
import error_inline from "./ic_error_inline.svg";
import navigation_alexa from "./ic_navigation_alexa.png";
import navigation_goback from "./ic_navigation_goback.png";
import navigation_goforward from "./ic_navigation_goforward.png";
import navigation_home_active from "./ic_navigation_home_active.png";
import navigation_home from "./ic_navigation_home.png";
import navigation_menu from "./ic_navigation_menu.png";
import navigation_mymusic from "./ic_navigation_mymusic.png";
import navigation_profile_active from "./ic_navigation_profile_active.png";
import navigation_profile from "./ic_navigation_profile.png";
import navigation_refresh from "./ic_navigation_refresh.png";
import navigation_search from "./ic_navigation_search.png";
import navigation_search_dark from "./ic_navigation_search_dark.png";
import navigation_settings_active from "./ic_navigation_settings_active.png";
import navigation_settings from "./ic_navigation_settings.png";
import other_artist from "./ic_other_artist.png";
import other_camera from "./ic_other_camera.png";
import other_chart1_active from "./ic_other_chart1_active.png";
import other_chart1 from "./ic_other_chart1.png";
import other_closedcaptionsinternational_active from "./ic_other_closedcaptionsinternational_active.png";
import other_closedcaptionsinternational_inactive from "./ic_other_closedcaptionsinternational_inactive.png";
import other_closedcaptionsus_active from "./ic_other_closedcaptionsus_active.png";
import other_closedcaptionsus_inactive from "./ic_other_closedcaptionsus_inactive.png";
import other_contract from "./ic_other_contract.png";
import other_expand from "./ic_other_expand.png";
import other_filter1 from "./ic_other_filter1.png";
import other_filter2 from "./ic_other_filter2.png";
import other_grid from "./ic_other_grid.png";
import other_list from "./ic_other_list.png";
import other_notifications_active from "./ic_other_notifications_active.png";
import other_notifications from "./ic_other_notifications.png";
import other_notificationsdot_active from "./ic_other_notificationsdot_active.png";
import other_notificationsdot from "./ic_other_notificationsdot.png";
import other_sent from "./ic_other_sent.png";
import other_song from "./ic_other_song.png";
import other_sort1 from "./ic_other_sort1.png";
import other_sort2 from "./ic_other_sort2.png";
import other_video from "./ic_other_video.png";
import playback_equalizeroff from "./ic_playback_equalizeroff.png";
import playback_equalizeron from "./ic_playback_equalizeron.png";
import playback_fastforward from "./ic_playback_fastforward.png";
import playback_jumpback10 from "./ic_playback_jumpback10.png";
import playback_jumpforward10 from "./ic_playback_jumpforward10.png";
import playback_next from "./ic_playback_next.png";
import playback_pause from "./ic_playback_pause.png";
import playback_play from "./ic_playback_play.png";
import playback_playqueue from "./ic_playback_playqueue.png";
import playback_previous from "./ic_playback_previous.png";
import playback_repeat from "./ic_playback_repeat.png";
import playback_repeatone from "./ic_playback_repeatone.png";
import playback_rewind from "./ic_playback_rewind.png";
import playback_shuffle from "./ic_playback_shuffle.png";
import playback_volumemute from "./ic_playback_volumemute.png";
import playback_volumeoff from "./ic_playback_volumeoff.png";
import playback_volumeon from "./ic_playback_volumeon.png";
import ic_other_calendar from "./ic_other_calendar.png";
import ic_other_calendar1 from "./ic_other_calendar.png";
import ic_other_calendar7 from "./ic_other_calendar7.png";
import ic_other_calendar28 from "./ic_other_calendar28.png";
import ic_other_calendar30 from "./ic_other_calendar30.png";
import ic_other_calendaradd from "./ic_other_calendaradd.png";
import ic_playback_castoff from "./ic_playback_castoff.png";
import ic_playback_caston from "./ic_playback_caston.png";
import ic_other_spreadsheet from "./ic_other_spreadsheet.png";
import ic_action_signout from "./ic_action_signout.png";
import ic_hyperlink from "./ic_hyperlink.png";
import ic_other_profiles from "./ic_other_profiles.png";
import ic_other_document from "./ic_other_document.png";
import ic_action_checkbox from "./box_check_accent.svg";
import ic_action_uncheckbox from "./ic_action_uncheckbox.png";
import ic_action_checkcircle from "./ic_action_checkcircle.svg";
import ic_privacy from "./ic_privacy.png";
import ic_graphbars from "./ic_graphbars.svg";
import ic_smileyface from "./ic_smileyface.svg";
import ic_thermometer from "./ic_thermometer.svg";
import ic_help from "./ic_help.svg";
import ic_time_played from "./ic_time_played.svg";
import ic_music from "./ic_music.png";
import ic_spotlight from "./ic_spotlight.png";
import ic_step_1 from "./ic_step_1.svg";
import ic_step_2 from "./ic_step_2.svg";
import ic_step_3 from "./ic_step_3.svg";
import ic_step_4 from "./ic_step_4.svg";
import ic_step_5 from "./ic_step_5.svg";
import ic_step_6 from "./ic_step_6.svg";
import ic_important from "./ic_important.svg";
import ic_instagram from "./ic_instagram.svg";
import ic_twitter from "./ic_twitter.svg";
import ic_warning_yellow from "./ic_warning_yellow.svg";
import ic_image from "./ic_image.svg";
import ic_microphone from "./ic_microphone.svg";
import ic_audio_input from "./ic_audio_input.svg";
import archive from "./archive.svg";
import shield from "./shield.svg";
import partialCheckbox from "./partialCheckbox.svg";
import disabledCheckbox from "./disabledCheckbox.svg";
import merch_search from "./merch_search.png";
import share_arrow from "./share_arrow.svg";
import { ReactComponent as ic_disk } from "./ic_disk.svg";
import { ReactComponent as ic_microphone_karaoke } from "./ic_microphone_karaoke.svg";
import { ReactComponent as ic_ellipse } from "./ic_ellipse.svg";

export const IconsList = {
    action_add,
    action_add_black,
    action_addInline,
    action_cancel,
    action_cancel_black,
    action_cancelInline,
    action_delete,
    action_dislike,
    action_done,
    action_done_svg,
    action_done_black,
    action_doneInline,
    action_doneInline_blue,
    action_download,
    action_download_black,
    action_downloadcomplete,
    action_downloadfailed,
    action_edit,
    action_edit_black,
    action_view,
    action_favorite,
    action_favoriteon,
    action_flag,
    action_follow,
    action_following_active,
    action_like,
    action_makeprivate,
    action_makepublic,
    action_maximize,
    action_minimum,
    action_more,
    action_overflow,
    list_check_accent,
    action_remove,
    action_removeInline,
    action_shareandroid,
    action_share_web,
    action_shareemail,
    action_shareembed,
    action_sharefacebook,
    action_shareinstagram,
    action_sharelink,
    action_share_x,
    action_upload,
    alerts_help,
    alerts_Information,
    alerts_warning,
    chevron_caretdown,
    chevron_caretleft,
    chevron_caretright,
    chevron_caretUp,
    error_inline,
    navigation_alexa,
    navigation_goback,
    navigation_goforward,
    navigation_home_active,
    navigation_home,
    navigation_menu,
    navigation_mymusic,
    navigation_profile_active,
    navigation_profile,
    navigation_refresh,
    navigation_search,
    navigation_search_dark,
    navigation_settings_active,
    navigation_settings,
    other_artist,
    other_camera,
    other_chart1_active,
    other_chart1,
    other_closedcaptionsinternational_active,
    other_closedcaptionsinternational_inactive,
    other_closedcaptionsus_active,
    other_closedcaptionsus_inactive,
    other_contract,
    other_expand,
    other_filter1,
    other_filter2,
    other_grid,
    other_list,
    other_notifications_active,
    other_notifications,
    other_notificationsdot_active,
    other_notificationsdot,
    other_sent,
    other_song,
    other_sort1,
    other_sort2,
    other_video,
    playback_equalizeroff,
    playback_equalizeron,
    playback_fastforward,
    playback_jumpback10,
    playback_jumpforward10,
    playback_next,
    playback_pause,
    playback_play,
    playback_playqueue,
    playback_previous,
    playback_repeat,
    playback_repeatone,
    playback_rewind,
    playback_shuffle,
    playback_volumemute,
    playback_volumeoff,
    playback_volumeon,
    ic_other_calendar,
    ic_other_calendar1,
    ic_other_calendar7,
    ic_other_calendar28,
    ic_other_calendar30,
    ic_other_calendaradd,
    ic_playback_castoff,
    ic_playback_caston,
    ic_other_spreadsheet,
    ic_action_signout,
    ic_hyperlink,
    ic_other_profiles,
    ic_other_document,
    ic_action_checkbox,
    ic_action_uncheckbox,
    ic_action_checkcircle,
    ic_privacy,
    ic_graphbars,
    ic_smileyface,
    ic_thermometer,
    ic_help,
    ic_time_played,
    ic_music,
    ic_spotlight,
    ic_step_1,
    ic_step_2,
    ic_step_3,
    ic_step_4,
    ic_step_5,
    ic_step_6,
    ic_important,
    ic_instagram,
    ic_twitter,
    ic_warning_yellow,
    ic_image,
    ic_microphone,
    ic_audio_input,
    archive,
    shield,
    partialCheckbox,
    disabledCheckbox,
    merch_search,
    share_arrow,
    ic_microphone_karaoke,
    ic_disk,
    ic_ellipse,
};
